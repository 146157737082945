
import { Button, Form, Modal } from 'react-bootstrap';
import InfoDataUpdated from './InfoDataUpdated';
import { XLg } from 'react-bootstrap-icons';
import { useRecoilState } from 'recoil';
import { openedModalsState } from '../atoms';
import { useMemo } from 'react';
import { v4 } from 'uuid';

export default function InterfaceModal(props) {

    const { size, header, children, footer, show, onHide, isAlertOpen, isBlurred, overflowVisible } = props;

    const [ openedModals, setOpenedModals ] = useRecoilState(openedModalsState)

    const modalId = useMemo(() => v4() , []);

    const handleOnShow = () => {

        setOpenedModals([...openedModals, modalId]);        

    }

    const handleOnHide = () => {

        setOpenedModals(openedModals.filter(id => id !== modalId));        
        onHide();

    }

    return (

        <Modal
            className={isBlurred ? 'blurred' : ''}
            show={show}
            size={size ?? 'lg'}
            onShow={handleOnShow}
            onHide={handleOnHide}
            centered
            backdrop={openedModals[0] === modalId}
            keyboard={false}
        >
            { 
                header && 
                    <Modal.Header closeButton>
                        <Modal.Title
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                        { header }
                        </Modal.Title>
                    </Modal.Header>
            }
            <Modal.Body className={`pt-0 ${overflowVisible ? 'overflow-visible' : ''}`} style={{ minHeight: '6rem' }}>

                <InfoDataUpdated show={isAlertOpen} />

                <Form>
                    <Form.Group
                        className='form-group'
                    >

                        { children }

                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>

                { 
                    footer 
                }

                {
                    onHide &&
                        <Button
                            className='icon-button' 
                            onClick={handleOnHide} 
                            variant='light'
                            title='Chiudi modale'
                        >
                            <XLg />
                        </Button>

                }

            </Modal.Footer>
        </Modal>

    )

}