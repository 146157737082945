import { Alert } from 'react-bootstrap';

export default function FormInputError(props) {

    const {show, caption, className = '', style} = props;

    return (

        <Alert 
            style={style} 
            variant='danger' 
            className={`mt-1 p-1 ps-2 ${className}`}
            show={show} 
            transition={false}
        >
            { caption }
        </Alert>

    );

}
