
import { Button, ButtonGroup, Card, Dropdown } from "react-bootstrap";
import { List } from "react-bootstrap-icons";

import PathBreadcrumb from "./PathBreadcrumb";
import PageSpinner from "./PageSpinner";
import InfoClickForDetails from "./InfoClickForDetails";

export default function CardList(props) {

    const {header, onClick, setUpdateList, loading, children, detailModal, readOnly = false, showBreadcrumb = true, showInfoClick = true, showExport = false, handleExport } = props;

    return (
        <>

            {
                showBreadcrumb &&
                    <PathBreadcrumb />
            }

            {
                
                showInfoClick &&
                    <InfoClickForDetails />

            }

            <Card className='elenco mb-2'>
                <Card.Header>
                    { header }
                    <div style={{float : 'right'}}>
                        {
                            readOnly === false &&
                                <Button size='sm' className='me-1' onClick={onClick}>Aggiungi</Button>

                        }
                        <Dropdown
                            size='sm'
                            align='end'
                            as={ButtonGroup}
                        >
                            <Dropdown.Toggle variant="light">
                                <List size={22} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                { 
                                    showExport &&
                                        <Dropdown.Item onClick={handleExport}>Esporta</Dropdown.Item>
                                }
                                {
                                    showExport === false &&
                                        <Dropdown.Item disabled>Cerca</Dropdown.Item>
                                }
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={e => setUpdateList(new Date())}>Aggiorna</Dropdown.Item>
                            </Dropdown.Menu>                            
                        </Dropdown>
                    </div>
                </Card.Header>
                <Card.Body>

                {
                    loading ? 

                        (

                            <PageSpinner />

                        ) : (

                            <>
                                { children }
                            </>
                            
                        )

                }

                </Card.Body>
            </Card>

            { detailModal }

        </>
         
    )

}