import { useState } from 'react';
import { Button, Modal, Row } from 'react-bootstrap';
import { XLg } from 'react-bootstrap-icons';
import CircularLabel from './CircularLabel';
import RightModalFooterNav from './RightModalFooterNav';
import ButtonClearValue from './ButtonClearValue';

export default function ButtonColorPicker(props) {

    const { name, bg, onChange, isModalVisible } = props;

    const [colour, setColour] = useState(bg);

    const [isOpen, setIsOpen] = useState(false);

    const colours = [
        '#4d4d4d', '#999999', '#ffffff', '#f44e3b', '#fe9200', '#fcdc00', '#dbdf00', '#a4dd00', '#68ccca', '#73d8ff', '#aea1ff', '#fda1ff',
        '#333333', '#808080', '#cccccc', '#d33115', '#e27300', '#fcc400', '#b0bc00', '#68bc00', '#16a5a5', '#009ce0', '#7b64ff', '#fa28ff',
        '#000000', '#666666', '#b3b3b3', '#9f0500', '#c45100', '#fb9e00', '#808900', '#194d33', '#0c797d', '#0062b1', '#653294', '#ab149e',
    ];

    const handleClick = (e) => {

        isModalVisible(!isOpen);

        setIsOpen(!isOpen);

    };

    const handleColourSelection = (colour) => (e) => {

        setColour(colour)

    }
    const handleClearSelection = (e) => {

        setColour('');

    }

    const handleSubmit = (e) => {

        onChange({ target : { name, value : colour}});

        isModalVisible(!isOpen);

        setIsOpen(!isOpen);

    }

    const setDefaultColour = () => {

        return bg || '#d3d4d5';

    }

    return (

        <>

            <Button
                className='ms-2 sfondo-appuntamento-default'
                style={{

                    backgroundColor : setDefaultColour(),
                    borderColor : setDefaultColour(),
                    color : bg ? '' : '#212529'
                
                }}
                size='sm'
                onClick={handleClick}
            >
                Cambia
            </Button>                        
            {        
                isOpen &&
                    <Modal
                        show={isOpen}
                        size='sm'
                        centered
                        backdrop={false}
                        keyboard={false}
                        onHide={handleClick}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Imposta sfondo</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                            {
                                
                                colours.map(item => (

                                    <div 
                                        key={item}
                                        xs 
                                        lg='1'
                                        className='p-0 d-flex align-items-center justify-content-center'
                                        style={{ 
                                            height: '1.8rem',
                                            width : 'calc(100% / 12)',
                                        }}
                                    >

                                        <CircularLabel 
                                            bg={item} 
                                            animate 
                                            selected={ colour === item }
                                            onClick={handleColourSelection(item)} 
                                        />

                                    </div>

                                ))
                            }
                            </Row>                        
                        </Modal.Body>
                        <Modal.Footer>

                            <RightModalFooterNav>

                                <ButtonClearValue
                                    onClick={handleClearSelection}
                                    title='Elimina valore'
                                />

                            </RightModalFooterNav>

                            <Button 
                                onClick={handleSubmit}
                            >
                                Applica
                            </Button>

                            <Button variant='light' className='icon-button' onClick={handleClick} style={{margin: '0 calc(var(--bs-modal-footer-gap) * .5)'}}>
                                <XLg />
                            </Button>

                        </Modal.Footer>                
                    </Modal>

            }

        </>
    )

}