
import { DatabaseFill } from "react-bootstrap-icons";
import InterfaceModal from "./InterfaceModal";
import { Button, Form } from "react-bootstrap";
import RightModalFooterNav from "./RightModalFooterNav";
import FormPasswordInput from "./FormPasswordInput";
import FormFloatingInput from "./FormFloatingInput";
import ButtonPasswordPolicy from "./ButtonPasswordPolicy";
import usePassword from "../hooks/usePassword";
import FormSpinnerButton from "./FormSpinnerButton";

export default function ProfileModal(props) {

    const { isOpen, toggleModal } = props;

    const {

        data,

        valid,
        isAlertOpen,
        loading,

        handleOnChange,
        handleSubmit

    } = usePassword(isOpen);

    return (

        <InterfaceModal
            show={isOpen}
            onHide={toggleModal}
            isAlertOpen={isAlertOpen}
            header={

                <>
                    Profilo Utente
                </>

            }
            footer= {

                <>
                    <RightModalFooterNav>

                        <Button 
                            variant='secondary'
                            className='m-0 icon-button'
                            title='Visualizza log'
                        >
                            <DatabaseFill />
                        </Button>

                    </RightModalFooterNav>

                    <FormSpinnerButton 
                        caption='Salva' 
                        title='Salva dati' 
                        loading={loading} 
                        onClick={handleSubmit} 
                        error={Object.keys(valid).length > 0} 
                    />

                </>

            }
        >

            <FormFloatingInput
                label='Email'
                name='email'
                value={data}
                readOnly
            />

            <Form.Check
                className='mb-3'
                type='switch'
                id='disabilitaNotifiche'
                name='disabilitaNotifiche'
                onChange={handleOnChange}
                checked={data.disabilitaNotifiche}
                label='Disabilita notifiche di sistema'
            />

            <Form.Check
                className='mb-3'
                type='switch'
                id='visualizzaAvvisi'
                name='visualizzaAvvisi'
                onChange={handleOnChange}
                checked={data.visualizzaAvvisi}
                label='Visualizza avvisi informativi'
            />

            <FormPasswordInput
                label='Password'
                name='vecchiaPassword'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

            <FormFloatingInput
                label='Data scadenza password'
                name='dataScadenzaPassword'
                value={data}
                readOnly
            />

            <FormPasswordInput
                label='Nuova password'
                name='password'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />
            
            <ButtonPasswordPolicy />

            <FormPasswordInput
                label='Conferma nuova password'
                name='conferma'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

            <FormFloatingInput
                label='Ultimo accesso'
                name='ultimoAccesso'
                value={data}
                readOnly
            />

        </InterfaceModal>

    )

}