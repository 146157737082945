import { Button, Card, Form, Navbar } from "react-bootstrap";
import { useEffect, useState } from "react";
import moment from "moment";
import { XLg } from "react-bootstrap-icons";
import NoteTextarea from "./NoteTextarea";

export default function CalendarNoteSidebar(props) {

    const { context, date, filters, notes, doctors, onClose } = props;

    const [ data, setData ] = useState({});
    const [ filter, setFilter ] = useState('');

    const [ doctorList, setDoctorList ] = useState([]);
    const [ filteredDoctorList, setFilteredDoctorList ] = useState([]);

    useEffect(() => {

        setDoctorList(filters.length > 0 ? filters.reduce((acc, item) => { acc.push({ _id: item }); return acc} , []) : doctors);

    }, [doctors, filters])

    useEffect(() => {

        const formattedDate = moment(date).format('YYYY-MM-DD');

        setData(notes.filter(item => context === 'note' ? item.data === formattedDate : true).reduce((acc, item) => {

            const { medico, note } = item;

            acc[medico] = note;

            return acc;

        }, {}));

    }, [date, notes]);

    useEffect(() => {

        setFilteredDoctorList(
            doctorList
            .filter(item => filter ? data[item._id]?.toLowerCase().includes(filter.toLowerCase()) : true)
        )

    }, [doctorList, filter]);

    const handleOnFilterChange= (e) => {

        const { value } = e.target;

        setFilter(value);

    }

    const height = window.innerHeight - 170;

    return (

        <Card className='d-none d-md-block' style={{ minWidth : '25vw', maxWidth : '25vw', height : height }}>

            <Card.Body className='p-0'>

            <Navbar 
                variant='light'
                className='d-flex'
                style={{
                    justifyContent: 'space-between',
                    borderTopLeftRadius: '.375rem',
                    borderTopRightRadius: '.375rem',
                    borderBottom: '3px solid #DDDDDD',
                    backgroundColor: 'rgba(33,37,41,.03)',
                    padding: 0,
                    height: 51
                }}
            >
                <Navbar.Brand className='mx-2 d-flex align-items-center gap-1 w-100'>
                    {
                        context.toUpperCase()
                    }
                </Navbar.Brand>

                <div
                    className='d-flex'
                    style={{
                        minWidth : '50%'
                    }}
                >
                    <Form.Control
                        type='text' 
                        placeholder='Cerca...' 
                        size='sm'
                        value={filter}
                        onChange={handleOnFilterChange}
                    />

                    <Button 
                        variant='light'
                        size='sm'
                        className='mx-2'
                        title='Chiudi sidebar'
                        onClick={onClose}
                    >
                        <XLg size={19} />
                    </Button>
                </div>                
            </Navbar>

                <div className='py-3 px-2 pb-0 overflow-auto' style={{ height : height - 54 }}>

                {

                    filteredDoctorList.length > 0 ?

                    (

                        filteredDoctorList
                        .map(doctor => {

                            const item = doctors.find(item => item._id === doctor._id) ?? {};
                            const boxHeight = (((height - 54) - (16 * doctorList.length)) / doctorList.length) - (16 / doctorList.length);

                            return (

                                <NoteTextarea
                                    context={context}
                                    key={item._id}
                                    label={`${item.cognome}, ${item.nome}`}
                                    doctor={item._id}
                                    date={moment(date).format('YYYY-MM-DD')}
                                    name={item._id}
                                    initialValue={data[item._id] || ''}
                                    height={Math.max(boxHeight, 132)}
                                />
    
                            )

                        })

                    ) : (

                        <div 
                            className={filter.length === 0 && 'd-none'}
                            style={{ fontStyle: 'italic' }}
                        >
                            Nessuna corrispondenza trovata.
                        </div>

                    )

                }

                </div>

            </Card.Body>

        </Card>    

    )

}