
import FormFloatingInput from "./FormFloatingInput";
import InterfaceModal from "./InterfaceModal";
import FormFloatingTextarea from "./FormFloatingTextarea";

export default function LogModal(props) {

    const { data, isOpen, toggleModal } = props;

    return (

        <InterfaceModal
            show={isOpen}
            onHide={toggleModal}
            header='Dettaglio log'
            isAlertOpen={false}
        >

            <FormFloatingInput
                label='Data'
                name='data'
                value={data}
                readOnly
            />

            <FormFloatingInput
                label='Utente'
                name='email'
                value={data.utente}
                readOnly
            />

            <FormFloatingInput
                label='Indirizzo IP'
                name='ip'
                value={data}
                readOnly
            />

            <FormFloatingTextarea
                label='Evento'
                name='evento'
                value={data}
                readOnly
            />

        </InterfaceModal>

    )

}