import { useEffect, useState } from 'react';

import Ajax from './Ajax';

import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { DatabaseFill } from 'react-bootstrap-icons';
import FormFloatingInput from './FormFloatingInput';
import FormSelect from './FormSelect';
import FormPasswordInput from './FormPasswordInput';
import InterfaceModal from './InterfaceModal';
import RightModalFooterNav from './RightModalFooterNav';
import ButtonColorPicker from './ButtonColorPicker';
import ButtonOfficeHoursSetter from './ButtonOfficeHoursSetter';
import ActionInputWrapper from './ActionInputWrapper';
import ButtonPasswordPolicy from './ButtonPasswordPolicy';
import UserLogModal from './UserLogModal';
import { useRecoilValue } from 'recoil';
import { deleteItemState } from '../atoms';
import ButtonDeleteItem from './ButtonDeleteItem';
import FormSpinnerButton from './FormSpinnerButton';

export default function UserModal(props) {

    const { data, handleOnChange, isOpen, toggleModal, list, setUpdateList } = props;

    const [loading, isLoading] = useState(false);

    const [valid, setValidity] = useState({});

    const [isUpdated, setIsUpdated ] = useState(false);
    const [isDeleted, setIsDeleted ] = useState(false);

    const [isBlurred, setModalBlur] = useState(false);

    const [ officeHours, setOfficeHours ] = useState('');

    const [isLogOpen, toggleLogModal] = useState(false);

    const itemPendingDeletion = useRecoilValue(deleteItemState);

    useEffect(() => {

        setValidity({});

        isLoading(false);

        setIsUpdated(false);
        setIsDeleted(false);

    }, [isOpen]);

    useEffect(() => {

        const officeHours = formatOfficeHours(data)

        setOfficeHours(officeHours);

    }, [data])
        
    const isFormDataValid = () => {

        const errors = {};

        if (!data.email || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email)) {
            errors.email = 'Email non valida';
        }

        if (list.filter(item => item.email === data.email && item._id !== data._id).length > 0) {
            errors.email = 'Email già presente in archivio Utenti';
        }

        if (!data.cognome || /^[A-Z' àèìòù]{2,}$/i.test(data.cognome) === false) {
            errors.cognome = 'Cognome non valido';
        }

        if (!data.nome || /^[A-Z' àèìòù]{2,}$/i.test(data.nome) === false) {
            errors.nome = 'Nome non valido';
        }

        if (!data.gruppo) {
            errors.gruppo = 'Selezionare il gruppo di appartenenza'
        }

        if (data.password || data.conferma || !data._id) {

            if (/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/.test(data.password) === false) {
                errors.password ='Password non valida';
            }

            if (data.password !== data.conferma) {
                errors.conferma = 'Le password non corrispondono';
            }

        }

        setValidity(errors);

        return Object.keys(errors).length === 0;

    }

    const handleSubmit = e => {

        if (!isFormDataValid()) {

            return;

        }

        const updateUser = async() => {

            isLoading(true);

            const updateData = { ...data };

            delete updateData.conferma;

            return await Ajax({
                route : `utenti/${data._id ?? ''}`, 
                parametri : { data : updateData },
                metodo : data._id ? 'post' : 'put',
            })
            .catch(err => {

            });
    
        }

        updateUser()
        .then((res) => {

            isLoading(false);

            setIsUpdated(true);

            setUpdateList(new Date());

            if (!data._id) {

                handleOnChange({ target : { name :'_id', value : res.insertedId }});

            }

        })
        .catch(err => {

        });

    }

    const handleOnDelete = () => {

        const deleteUser = async () => {

            return await Ajax({
                route : `utenti/${data._id}`, 
                parametri : { data : { data } },
                metodo : 'delete',
            })
            .catch(err => {

            })    
        }

        deleteUser()
        .then((res) => {

            setIsDeleted(true);
            setUpdateList(new Date());

            toggleModal(false);

        });

    }

    const handleChangeBlur = (isOpen) => {

        setModalBlur(isOpen);

    }

    const formatOfficeHours = (data) => {

        const hours = data.orariRicevimento;

        if (!hours || !Object.keys(hours)) return '';

        const result = Object.entries(hours).map(([key, item]) => {

            return key + ': ' + item.map(item => 

                `${item.inizio}-${item.fine}`

            )

        })

        return result.join('; ');

    }

    return (

        <InterfaceModal
            isBlurred={isBlurred || isLogOpen || itemPendingDeletion}
            show={isOpen}
            onHide={toggleModal}
            isAlertOpen={isUpdated}
            header={

                <>
                    {data._id ? 'Dettaglio' : 'Aggiungi'} Utente
                </>

            }
            footer= {

                <>
                    <RightModalFooterNav>

                        <ButtonDeleteItem
                            disabled={!data._id}
                            className='me-2'
                            body={`Eliminare l'Utente e tutti i dati associati?\nL'eliminazione è irreversibile e comporta una perdita di dati.`}
                            onClick={handleOnDelete}
                        />

                        <Button 
                            variant='secondary'
                            disabled={!data._id}
                            className='m-0 icon-button'
                            title='Visualizza log'
                            onClick={() => toggleLogModal(true)}
                        >
                            <DatabaseFill />
                        </Button>

                    </RightModalFooterNav>

                    <FormSpinnerButton 
                        caption='Salva'
                        title='Salva dati'
                        loading={loading}
                        onClick={handleSubmit} 
                        error={Object.keys(valid).length > 0} 
                    />

                </>

            }
        >

            <FormFloatingInput
                label='Email'
                name='email'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

            <FormFloatingInput
                label='Cognome'
                name='cognome'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

            <FormFloatingInput
                label='Nome'
                name='nome'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

            <FormSelect
                label='Gruppo'
                name='gruppo'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            >
                <option value=''>Seleziona voce</option>
                <option value='AMM'>AMMINISTRATORE</option>
                <option value='SEG'>SEGRETERIA</option>
                <option value='MED'>MEDICO</option>
            </FormSelect>

            {

                data.gruppo === 'MED' &&
                <>

                    <Form.Check
                        className='mb-3'
                        type='switch'
                        id='accedeSchede'
                        name='accedeSchede'
                        onChange={handleOnChange}
                        checked={data.accedeSchede}
                        label='Può accedere alle schede Pazienti'
                    />

                    <Form.Check
                        className='mb-3'
                        type='switch'
                        id='aggiornaCalendario'
                        name='aggiornaCalendario'
                        onChange={handleOnChange}
                        checked={data.aggiornaCalendario}
                        label='Può aggiornare il calendario appuntamenti'
                    />

                    <ActionInputWrapper>

                        <FloatingLabel
                            className='flex-grow-1'
                            label='Orari di ricevimento'
                        >
                            <Form.Control
                                style={{
                                    borderTopRightRadius : 0,
                                    borderBottomRightRadius : 0,
                                    borderRight: 0                   
                                }}
                                value={officeHours}
                                placeholder='Orari di ricevimento'
                                readOnly
                            />
                        </FloatingLabel>

                        <ButtonOfficeHoursSetter 
                            className='action-input'
                            name='orariRicevimento'
                            data={data}
                            onChange={handleOnChange}   
                            isModalVisible={handleChangeBlur}
                        />

                    </ActionInputWrapper>

                    <p
                        className='mb-3'
                    >
                        Colore di sfondo per appuntamenti in calendario

                        <ButtonColorPicker 
                            name='coloreSfondo'
                            bg={data.coloreSfondo} 
                            onChange={handleOnChange}   
                            isModalVisible={handleChangeBlur}
                        />
                    </p>                        

                </>

            }

            <FormPasswordInput
                label='Password'
                name='password'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

            <ButtonPasswordPolicy />

            <FormPasswordInput
                label='Conferma password'
                name='conferma'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

            <FormFloatingInput
                label='Ultimo accesso'
                name='ultimoAccesso'
                value={data}
                readOnly
            />

            <UserLogModal
                isOpen={isLogOpen}
                toggleModal={toggleLogModal}
                user={data._id}
            />

        </InterfaceModal>

    )

}