import './PaginatedSearchableTable.css';

import { useEffect, useState } from 'react';
import { Form, Pagination, Stack, Table } from 'react-bootstrap';
import { useRecoilValue } from 'recoil';
import { v4 } from 'uuid';
import { infoWarningState } from '../atoms';

export default function PaginatedSearchableTable (props) {

    const { data, className, columns, onRowClick, updateList } = props;

    const [ recordset, setRecordset ] = useState([]);

    const itemsPerPage = 50;

    const [currentPage, setCurrentPage] = useState(1);
    const pageCount = Math.ceil(recordset.length / itemsPerPage);

    const itemsStart = (currentPage - 1) * itemsPerPage;
    const itemsEnd = itemsStart + itemsPerPage;
    const itemsToShow = recordset.slice(itemsStart, itemsEnd);

    const [ filter, setFilter ] = useState('')

    const infoState = useRecoilValue(infoWarningState);

    const height = window.innerHeight - (infoState ? 250 : 176);

    useEffect(() => {

        setRecordset(data);

    }, [])

    useEffect(() => {

        setRecordset(
            data.filter(item =>
                columns.some(column => 
                    item[column.dataKey] && item[column.dataKey].toString().toLowerCase().includes(filter.toLowerCase())
                )
            )
        )

    }, [filter, data, updateList])

    const handleOnChange = (e) => {

        setCurrentPage(1);
        setFilter(e.target.value);

    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const paginationItems = () => {

        let items = [];

        items.push(
            <Pagination.Item active={currentPage === 1} key='first' onClick={() => handlePageChange(1)}>
                1
            </Pagination.Item>
        );
    
        if (currentPage > 3) {
            items.push(<Pagination.Ellipsis className='ellipsis' key='ell1' />);
        }
    
        let startPage = Math.max(2, currentPage - 1);
        let endPage = Math.min(pageCount - 1, currentPage + 1);
    
        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => handlePageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }
    
        if (currentPage < pageCount - 2) {
            items.push(<Pagination.Ellipsis className='ellipsis' key='ell2' />);
        }
    
        items.push(
            <Pagination.Item key='last' active={currentPage === pageCount} onClick={() => handlePageChange(pageCount)}>
                {pageCount}
            </Pagination.Item>
        );
    
        return items;

    };

    const highlightMatch = (text, filter) => {

        if (!filter) return text;

        if (typeof text !== 'string') {
            return text;
        }

        const parts = text.split(new RegExp(`(${filter})`, 'gi'));

        return parts.map((part, index) => 

            part.toLowerCase() === filter.toLowerCase() ? 
                <span key={v4()} style={{ textDecoration: 'underline' }}>{part}</span> : 
                part

        );

    }

    return (
        <div
            style={{
                height: height,
                overflow: 'auto'
            }}
        >
            <Stack 
                direction='horizontal'
                className='table-stack m-0 p-2'
                gap={2}
                style={{ 
                    position: 'sticky',
                    backgroundColor: 'white',
                    top: 0
                }}
            >

                <Form.Control 
                    type='text' 
                    placeholder='Cerca...' 
                    value={filter}
                    onChange={handleOnChange}
                />

                {

                    pageCount > 1 &&

                        <Pagination
                            className='m-0'
                        >
                            <Pagination.Prev onClick={() => handlePageChange(Math.max(1, currentPage - 1))} />
                            {
                                paginationItems()
                            }
                            <Pagination.Next onClick={() => handlePageChange(Math.min(pageCount, currentPage + 1))} />
                        </Pagination>

                }

            </Stack>

            <Table
                striped 
                borderless 
                hover 
                className={`searchable-table m-0 ${className}`}
            >
                <thead>
                    <tr>
                        {
                            columns.filter(item => item.header).map(item => <th key={v4()} colSpan={item.colSpan ?? 1}>{item.header}</th>)
                        }
                    </tr>
                </thead>
                <tbody>
                {

                    recordset.length > 0 ?

                    (

                        itemsToShow.map(record => (

                            <tr key={record._id} onClick={onRowClick(record._id)}>
                            {
                                columns.map((item, index) => (
                                    <td
                                        key={v4()}
                                        style={{
                                            ...((columns[index]?.style) ?? {})
                                        }}
                                    >
                                        {highlightMatch(record[item.dataKey], filter)}
                                    </td>
                                ))
                            }
                            </tr>

                        ))
                    ) : (

                        <tr><td colSpan={columns.length} style={{fontStyle : 'italic'}}>Nessun record in archivio.</td></tr>

                    )

                }                
                </tbody>
            </Table>        
        </div>
    )
}