import FormFloatingInput from './FormFloatingInput';
import InterfaceModal from './InterfaceModal';

export default function BackupModal(props) {

    const { data, isOpen, toggleModal } = props;

    return (

        <InterfaceModal
            show={isOpen}
            onHide={toggleModal}
            isAlertOpen={false}
            header='Dettaglio backup'
        >

            <FormFloatingInput
                label='File'
                name='nome'
                value={data}
                readOnly
            />

            <FormFloatingInput
                label='Data'
                name='data'
                value={data}
                readOnly
            />

            <FormFloatingInput
                label='Dimensione (b)'
                name='dimensione'
                value={data}
                readOnly
            />

            <FormFloatingInput
                label='Hash'
                name='hash'
                value={data}
                readOnly
            />

        </InterfaceModal>

    )

}