import axios from 'axios';

export default function Ajax({ route, parametri = {}, metodo = 'post' }) {

    return new Promise((resolve, reject) => {

        const startTime = performance.now();

        axios[metodo](`/api/${route}`, parametri)
        .then(response => {

            const endTime = performance.now();
            const elapsedTime = (endTime - startTime) / 1000;
                 
            const dataSize = new Blob([JSON.stringify(response.data)]).size / 1024;
            const speed = (dataSize / elapsedTime);

            console.table([{
                'Route' : route,
                'Dati (KB)': dataSize,
                'Tempo (s)': elapsedTime.toFixed(2),
                'Velocità (KB/sec)': speed.toFixed(2)
            }]);

            resolve(response.data);

        })
        .catch(err => {

            reject(err.response);

        });

    });

}
