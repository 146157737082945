import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";
import InfoModal from "./InfoModal";
import { getAppNamespace } from "../hooks/useApp";
import { useRecoilState } from "recoil";
import { infoWarningState } from "../atoms";

export default function InfoClickForDetails(props) {

    const [infoState, setInfoState] = useRecoilState(infoWarningState);

    const [show, setShow] = useState(infoState);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {

        setShow(infoState);

    }, [infoState])

    const handleOnClose = () => {

        setModalShow(true);
        setShow(false);

    }

    const handleOnModalClick = () => {

        localStorage.setItem(`${getAppNamespace}.avvisi`, false);

        setInfoState(false);
        setModalShow(false);

    }

    return (

        <>
            <Alert 
                className='d-flex align-items-center informazioni-dettaglio' 
                variant='success' 
                show={show}
                dismissible
                onClose={handleOnClose}
            >
                <InfoCircle size={20} className='me-2' /> <span> record per il dettaglio</span>
            </Alert>

            <InfoModal
                show={modalShow}
                body={`Vuoi nascondere questo avviso in modo permanente?\nPuoi riattivarlo in qualsiasi momento selezionando l'opzione 'Visualizza avvisi informativi' nel tuo profilo utente.`}
                onClose={() => setModalShow(false)}
                onClick={handleOnModalClick}
            />
        </>

    )            

}