import './Home.css';

import logo from '../img/logo-l.png';

import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Ajax from '../components/Ajax';

import { Routes, Route, BrowserRouter as Router, Link } from 'react-router-dom';
import { Container, Image, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { List, XLg } from 'react-bootstrap-icons';

import Calendar from './Calendar';
import Dashboard from './Dashboard';
import Patients from './Patients';

import RedirectTo404 from '../components/RedirectTo404';
import PageFooter from '../components/PageFooter';
import Users from './tools/Users';

import { useRecoilState, useSetRecoilState } from 'recoil';
import { infoWarningState, userState, wsState } from '../atoms';
import NoWsAlert from '../components/NoWsAlert';
import ProfileNavItem from '../components/ProfileNavItem';
import PasswordExpiredModal from '../components/PasswordExpiredModal';
import moment from 'moment';
import { getAppNamespace } from '../hooks/useApp';
import OfflineModal from '../components/OfflineModal';
import InputOutput from './tools/InputOutput';
import Report from './management/Report';
import Summary from './management/Summary';
import Log from './tools/Log';
import Help from './help/Help';
import Feedback from './help/Feedback';
import Backup from './tools/Backup';
import { usePermissions } from '../hooks/usePermissions';
import AboutModal from '../components/AboutModal';
import Planner from './tools/Planner';

export default function Home(props) {

    const { token, setToken } = props;

    const [ isExpanded, setIsExpanded ] = useState(false);
    const  [isOpen, toggleModal ] = useState(false);
    
    const [ connectedToWs ] = useRecoilState(wsState);

    const [ isPasswordExpired, setIsPasswordExpired ] = useState(false);

    const setUserState = useSetRecoilState(userState);
    const setInfoState = useSetRecoilState(infoWarningState);

    setUserState(token);
    setInfoState((localStorage.getItem(`${getAppNamespace}.avvisi`) ?? 'true') === 'true');

    useEffect(() => {

        const { dataScadenzaPassword } = token

        const isPasswordExpired = (!dataScadenzaPassword || moment(dataScadenzaPassword).toDate() < new Date());

        setIsPasswordExpired(isPasswordExpired);

    }, [token])

    const toggleMenu = () => {
        setIsExpanded(!isExpanded);
    };

    const doLogout = () => {

        const uuid = localStorage.getItem(`${getAppNamespace}.uuid`);

        Ajax({
            route : `logout/?uuid=${uuid}`
        })
        .then(() => {

            setToken(null);

        })
        .catch(err => {

        });

    };

    const { isManagementEnabled, isToolsEnabled } = usePermissions();

    return (

        <Router>        
            <Navbar collapseOnSelect expand='lg' bg='dark' variant='dark' sticky='top' className='mb-3'>
                <Container className='app-menu-container'>
                    <Navbar.Brand as='div' style={{ padding: 0 }}>
                        <Image 
                            className='logo' 
                            src={logo}
                            style={{height: 40}}    
                        />
                    </Navbar.Brand>

                    <NoWsAlert className='ms-auto responsive-alert' show={connectedToWs === false} />

                    <Navbar.Toggle 
                        onClick={toggleMenu} 
                        aria-controls='responsive-navbar-nav' 
                        className='menu-toggle'
                    >

                        {
                            isExpanded ? 
                                (
                                    <XLg size={26} />
                                ) : (
                                    <List size={32} />
                                )
                        }

                    </Navbar.Toggle>
                    <Navbar.Collapse id='responsive-navbar-nav'>              
                        <Nav className='me-auto'>

                            <Nav.Link as={Link} to='/'>Home</Nav.Link>
                            <Nav.Link as={Link} to='/calendario'>Calendario</Nav.Link>
                            <Nav.Link as={Link} to='/pazienti'>Pazienti</Nav.Link>

                            {
                                isManagementEnabled && 
                                    <NavDropdown
                                        title='Gestione'
                                    >
                                        <NavDropdown.Item as={Link} to='/gestione/prospettoMedici'>Prospetto medici</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to='/gestione/resocontoCassa'>Resoconto cassa</NavDropdown.Item>
                                    </NavDropdown>
                            }

                            {
                                isToolsEnabled &&
                                    <NavDropdown
                                        title='Strumenti'
                                    >
                                        <NavDropdown.Item as={Link} to='/strumenti/utenti'>Utenti</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to='/strumenti/planner'>Planner operativo</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to='/strumenti/input-output'>Input/output</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to='/strumenti/log'>Log di sistema</NavDropdown.Item>
                                        <NavDropdown.Item as={Link} to='/strumenti/backup'>Backup</NavDropdown.Item>
                                    </NavDropdown>
                            }

                            <NavDropdown
                                title='Aiuto'
                            >
                                <NavDropdown.Item as={Link} to='/giuda/aiuto'>Manuale uso</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to='/giuda/feedback'>Feedback</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={e => toggleModal(true)}>Informazioni su</NavDropdown.Item>
                            </NavDropdown>

                        </Nav>

                        <Nav>

                            <NoWsAlert show={connectedToWs === false} />

                            <NavDropdown
                                title={ token.email }
                                style={{ fontWeight: 'bold' }}
                                align='end'
                            >
                                <ProfileNavItem />
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={doLogout}>Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container className='app-root-container'>
                <Routes>

                    <Route exact path='/' element={<Dashboard />} />
                    <Route exact path='/calendario' element={<Calendar />} />
                    <Route exact path='/pazienti' element={<Patients />} />

                    {
                        isManagementEnabled && 
                            <>
                                <Route exact path='/gestione/prospettoMedici' element={<Report />} />
                                <Route exact path='/gestione/resocontoCassa' element={<Summary />} />
                            </>
                    }

                    { 
                        isToolsEnabled &&
                            <>
                                <Route exact path='/strumenti/utenti' element={<Users />} />
                                <Route exact path='/strumenti/planner' element={<Planner />} />
                                <Route exact path='/strumenti/input-output' element={<InputOutput />} />
                                <Route exact path='/strumenti/log' element={<Log />} />
                                <Route exact path='/strumenti/backup' element={<Backup />} />
                            </>
                    }

                    <Route exact path='/giuda/aiuto' element={<Help />} />
                    <Route exact path='/giuda/feedback' element={<Feedback />} />
                    
                    <Route path='*' element={<RedirectTo404 />} />
                    
                </Routes>  
            </Container>    
            <PageFooter /> 
            {
                isPasswordExpired &&
                    <PasswordExpiredModal 
                        doLogout={doLogout}
                    />
            } 
            <OfflineModal />
            <AboutModal 
                isOpen={isOpen}
                toggleModal={toggleModal}
            />
        </Router>

    )

}

Home.propTypes = {
    token: PropTypes.object.isRequired,
    setToken: PropTypes.func.isRequired
};
