import './OfflineModal.css';

import { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { WifiOff } from 'react-bootstrap-icons';

export default function OfflineModal() {

    const [isOnline, handleConnectionStatus] = useState(true);

    const modalExists = useRef(false);

    useEffect(() => {
        
        function handleOffline() {

            modalExists.current = document.querySelector('.modal-dialog') !== null;
            
            handleConnectionStatus(false);

        }

        function handleOnline() {
                
            handleConnectionStatus(true);

        }

        window.addEventListener('offline', handleOffline);
        window.addEventListener('online', handleOnline);

        return () => {
            window.removeEventListener('offline', handleOffline);
            window.removeEventListener('online', handleOnline);
        };

    }, []);

    return (

        <>
            { 
                !isOnline && 
                    modalExists.current &&
                        <div className='offline-modal-backdrop'></div>
            }
            <Modal
                className='offline-modal'
                show={!isOnline}
                backdrop='static'
                keyboard={false}
                enforceFocus={true}
                centered
            >

                <Modal.Body className='d-flex justify-content-center flex-column'>
                    <div className='text-center mr-4'>
                        <WifiOff className='mb-4' size={64} />
                    </div>
                    <div className='text-left'>
                        <p><b>Connessione ad internet persa.</b></p>
                        <p className='m-0 text-align-justify'>Torna online per continuare a utilizzare l'app.</p>
                    </div>
                </Modal.Body>

            </Modal>
        </>
        
    );

}
