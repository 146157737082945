import { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { getAppNamespace } from '../hooks/useApp';
import moment from 'moment';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userState, wsMessagesState, wsState, autoUpdateState } from '../atoms';

export default function WsToastNotification(props) {

    const { environment } = props;

    const token = useRecoilValue(userState);

    const [ cookies ] = useCookies();

    const [ messages, setMessages ] = useRecoilState(wsMessagesState);
    const [ lastUpdate, setLastUpdate ] = useState(0);

    const setConnected = useSetRecoilState(wsState);
    const setAutoUpdate = useSetRecoilState(autoUpdateState);

    const [ waitingToReconnect, setWaitingToReconnect ] = useState(false);

    useEffect(() => {
        const uuid = localStorage.getItem(`${getAppNamespace}.uuid`);
    
        const currentURL = new URL(window.location.href);
        const { hostname } = currentURL;

        let ws = null;

        const establishWebSocketConnection = () => {

            const schema = window.location.protocol === 'http:' ? 'ws' : 'wss';
            const port = environment === 'dev' ? ':3001' : '';

            ws = new WebSocket(`${schema}://${hostname}${port}?uuid=${uuid}`);

            ws.onerror = () => {
                setConnected(false);
            };

            ws.onopen = () => {

                setConnected(true);

                ws.send('opened');

            };

            ws.onclose = () => {

                setConnected(false);

                if (waitingToReconnect) {

                    return;

                };

                setWaitingToReconnect(true);

                setTimeout(() => setWaitingToReconnect(false), 10 * 1000);

            };

            ws.onmessage = (e) => {

                const data = JSON.parse(e.data);

                data.timeAgo = 'Adesso';
        
                setMessages((prevData) => [
                    ...prevData,
                    data,
                ]);

                const channel = new BroadcastChannel('messages_channel');

                channel.postMessage(data);
                channel.close();
            };

        };

        establishWebSocketConnection();

    }, [waitingToReconnect, cookies, environment, setConnected, setMessages]);

    useEffect(() => {

        const channel = new BroadcastChannel('messages_channel');

        channel.onmessage = (e) => {

            const data = e.data;

            setMessages((prevData) => [
                ...prevData,
                data,
            ]);

        };

        return () => channel.close();

    }, [setMessages]);

    useEffect(() => {

        const updates = messages.filter(item => item.contesto === 'Calendario' && item.timestamp >= lastUpdate);

        if (updates.length > 0) {

            setLastUpdate(moment().unix());
            setAutoUpdate(true);

        }

    }, [messages, lastUpdate, setAutoUpdate]);

    const getTimeAgo = (timestamp) => {

        const now = moment();
        const sentTime = moment.unix(timestamp);
        
        const diffInMinutes = now.diff(sentTime, 'minutes');

        if (diffInMinutes === 0) return 'Adesso';

        if (diffInMinutes === 1) return '1 minuto fa';

        if (diffInMinutes < 60) return `${diffInMinutes} minuti fa`;

        return sentTime.format('HH:mm');

    };

    useEffect(() => {

        const interval = setInterval(() => {

            setMessages(prevData => prevData.map(item => ({
                ...item,
                timeAgo: getTimeAgo(item.timestamp),
            })));

        }, 60 * 1000);

        return () => clearInterval(interval);

    }, []);

    const handleToastRemove = (data) => () => {

        const messageList = messages.filter(item => !(item.timestamp === data.timestamp && item.contesto === data.contesto));

        setMessages(messageList);

    };

    return (

        <ToastContainer 
            position='top-end'
        >
        {

            !token.disabilitaNotifiche && 
                messages
                    .filter(item => !item.messaggio.startsWith('sistema'))
                    .filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t.timestamp === value.timestamp && t.contesto === value.contesto
                        ))
                    )
                    .map(item => (

                        <Toast
                            className='m-1'
                            key={item.uuid}
                            onClose={handleToastRemove(item)}
                            bg={item.bg}
                        >
                            <Toast.Header>
                                <strong className='me-auto'>{item.contesto}</strong>
                                <small>{item.timeAgo}</small>
                            </Toast.Header>
                            <Toast.Body>{item.messaggio}</Toast.Body>
                        </Toast>

                    ))
        }
        </ToastContainer>

    );
}
