import './ButtonOfficeHoursSetter.css';

import { Button, ButtonGroup, Dropdown, Form, Modal } from 'react-bootstrap';
import RightModalFooterNav from './RightModalFooterNav';
import { DashCircle, Gear, PlusCircle, XLg, ZoomIn, ZoomOut } from 'react-bootstrap-icons';
import { useEffect, useState } from 'react';
import ButtonClearValue from './ButtonClearValue';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'

import moment from 'moment';
import { v4 } from 'uuid';
import AlertModal from './AlertModal';
import useDndCalendar from '../hooks/useDndCalendar';

export default function ButtonOfficeHoursSetter(props) {

    const { name, data, onChange, isModalVisible, readOnly=false, disabled, icon=<Gear size={20} /> } = props;

    const [ isOpen, setIsOpen ] = useState(false);

    const [ slotSelectError, setSlotSelectError ] = useState('');

    const handleClose = () => setSlotSelectError('');

    const { 

        localizer,
        minTime,
        maxTime,
        customFormats,

        events,
        setEvents,

        timeslots,
        
        eventStyleGetter,

        DnDCalendar,
        EventComponent,

        handleDeleteEvent,
        handleTimeslots

    } = useDndCalendar({ 
        defaultView: 'work_week' 
    });

    useEffect(() => {

        if (!isOpen) return;

        const weekdays = { 'lun': 1, 'mar': 2, 'mer': 3, 'gio': 4, 'ven': 5 };

        const startOfThisWeek = moment().startOf('isoWeek');

        const events = Object.entries(data[name] || {}).reduce((acc, [key, value]) => {

            const day = moment(startOfThisWeek).day(weekdays[key]);

            const h = value.map(item => {

                const { inizio, fine } = item;

                const title = `${inizio} ${fine}`;

                const start = moment(day).set({
                    hour: moment(inizio, 'HH:mm').hour(),
                    minute: moment(inizio, 'HH:mm').minute(),                
                }).toDate();

                const end = moment(day).set({
                    hour: moment(fine, 'HH:mm').hour(),
                    minute: moment(fine, 'HH:mm').minute(),                
                }).toDate();

                return (
                    {
                        _id : v4(),
                        start, 
                        end, 
                        title
                    }
                )

            });

            acc = [...acc, ...h];

            return acc;

        }, [])

        setEvents(events)

    }, [isOpen])

    const handleClick = (e) => {

        isModalVisible(!isOpen);

        setIsOpen(!isOpen);

    }

    const handleClearValue = (e) => {

        setEvents([]);

    }

    const handleSubmit = (e) => {

        const value = events.sort((a, b) => 

            new Date(a.start) - new Date(b.start)

        ).reduce((acc, item) => {

            const { start, end } = item;

            const dayOfWeek = moment(start).format('ddd');

            if (!acc[dayOfWeek]) acc[dayOfWeek] = [];

            acc[dayOfWeek].push({
                inizio: moment(start).format('HH:mm'),
                fine : moment(end).format('HH:mm'),
                durata : item.duration
            })

            return acc;

        }, {});

        onChange({ target : { name, value }});

        isModalVisible(!isOpen);

        setIsOpen(!isOpen);

    }

    moment.locale('it');

    const checkOverlap = (newEvent, existingEvents) => {

        return existingEvents.some(event => {
            return (
                (newEvent._id !== event._id && newEvent.start < event.end && newEvent.start > event.start) ||
                (newEvent._id !== event._id && newEvent.end > event.start && newEvent.end < event.end) ||
                (newEvent._id !== event._id && newEvent.start < event.start && newEvent.end > event.end)
            );
        });

    };    

    const handleSelectSlot = ({ start, end }) => {

        const title = `${moment(start).format('HH:mm')} ${moment(end).format('HH:mm')}`

        if (checkOverlap({ start, end }, events)) {

            setSlotSelectError('Lo slot impostato non è valido.');

            return;

        }

        setEvents(prevEvents => [
            ...prevEvents,
            { _id: v4(), start, end, title },
        ]);

    };    

    const handleEventResize = ({ event, start, end }) => {

        const { _id } = event;

        if (checkOverlap({ _id, start, end }, events)) {

            setSlotSelectError('Lo slot impostato non è valido.');

            return;

        }

        const title = `${moment(start).format('HH:mm')} ${moment(end).format('HH:mm')}`

        const updatedEvents = events.map(evt => {

            if (event._id === evt._id) {
                return { ...evt, title, start, end };
            }
            return evt;

        });

        setEvents(updatedEvents);

    };

    return (

        <>
            <Button 
                className='p-2 action-button'
                variant='light'
                title={readOnly ? 'Visualizza orari ricevimento' : 'Configura'}
                onClick={handleClick}
                disabled={disabled}
            >
                { icon }
            </Button>

            {

                isOpen &&

                    <>
                        <Modal
                            show={isOpen}
                            size='sm'
                            dialogClassName='modal-giorno-picker'
                            centered
                            backdrop={false}
                            keyboard={false}
                            onHide={handleClick}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>{ !readOnly ? 'Imposta orario' : 'Orario ricevimento' }</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>

                                <DnDCalendar 
                                    className='selettore-orari'
                                    events={events}
                                    culture='it-IT'
                                    localizer={localizer}
                                    startAccessor='start'
                                    endAccessor='end'
                                    step={15}
                                    timeslots={timeslots}                
                                    views={['work_week']}
                                    defaultView={'work_week'}
                                    min={minTime}
                                    max={maxTime}
                                    toolbar={false}
                                    eventPropGetter={eventStyleGetter}
                                    selectable={!readOnly}
                                    onSelectSlot={handleSelectSlot}
                                    resizable={!readOnly}
                                    onEventResize={handleEventResize}
                                    formats={customFormats}
                                    style={{
                                        width: 334
                                    }}
                                    components={{
                                        event: (props) => <EventComponent {...props} onDeleteEvent={handleDeleteEvent} readOnly={readOnly} />,
                                    }}
                                    tooltipAccessor={null}
                                    draggableAccessor={() => !readOnly}
                                />
                                
                            </Modal.Body>
                            <Modal.Footer>

                            {   
                                !readOnly &&
                                    <>
                                        <RightModalFooterNav>

                                            <ButtonClearValue
                                                onClick={handleClearValue}
                                                title='Elimina valore'
                                            />

                                            <Dropdown
                                                align='start'
                                                as={ButtonGroup}
                                                autoClose='inside'
                                                title='Imposta grandezza slot'
                                                className='ms-1'
                                                drop='up'
                                            >
                                                <Dropdown.Toggle variant='light'>
                                                    <ZoomIn size={18} />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div
                                                        className='calendar-range d-flex align-items-center justify-content-center'
                                                    >
                                                        <PlusCircle />
                                                        <Form.Range 
                                                            min={1}
                                                            max={4}
                                                            value={timeslots}
                                                            onChange={handleTimeslots}
                                                            style={{ width: 100 }} 
                                                            className='mx-1'
                                                            title='Zoom'
                                                        />
                                                        <DashCircle />
                                                    </div>
                                                </Dropdown.Menu>                            
                                            </Dropdown>

                                        </RightModalFooterNav>

                                        <Button 
                                            onClick={handleSubmit}
                                            variant='primary'
                                        >
                                            Applica
                                        </Button>
                                    </>
                                }

                                <Button variant='light' className='icon-button' onClick={handleClick} style={{margin: '0 calc(var(--bs-modal-footer-gap) * .5)'}}>
                                    <XLg />
                                </Button>

                            </Modal.Footer>                
                        </Modal>

                        <AlertModal
                            show={slotSelectError.length > 0}
                            body={slotSelectError}
                            onClose={handleClose}
                            showButton={false}
                        />

                    </>
                    
            }

        </>

    )

}