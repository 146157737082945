import React from 'react';
import { Link, useLocation } from 'react-router-dom'

import { Breadcrumb } from 'react-bootstrap';
import { v4 } from 'uuid';

export default function PathBreadcrumb() {

    const CreaBreadcrumb = () => {

        let path = useLocation()
                       .pathname
                       .split(/\//),
            relativePath = '';

        return path
               .map((item, i) => {

                    relativePath += `${item}/`;

                    const active = i;
                    const name = item.charAt(0).toUpperCase() + item.slice(1).replace(/([A-Z])/g, ' $1').replace('-', '/').toLowerCase();

                    return i < path.length -1 ?

                        <Breadcrumb.Item 
                            key={v4()}
                            linkAs={Link}
                            linkProps={{to : relativePath}}
                            active={active}
                        >
                        {name || 'Home'}
                        </Breadcrumb.Item>
                                                    
                        : 

                        <Breadcrumb.Item
                            key={v4()}
                            title={name}
                            style={{fontWeight : 'bold', color: 'var(--bs-nav-link-color)'}}
                            active={true}
                        >
                            { name }
                        </Breadcrumb.Item>;

               });

    }

    return (

        <Breadcrumb size='24'>

            { CreaBreadcrumb() }

        </Breadcrumb>
    
    )

}