import './NoWsAlert.css'

import { Nav } from "react-bootstrap";
import { Plugin } from "react-bootstrap-icons";

export default function NoWsAlert(props) {

    const { show, className } = props;

    return (

        show &&

            <Nav 
                className={`no-ws-alert ${className}`}
                title='Websocket non connesso'
            >
                <Nav.Item
                    className='my-auto me-2'
                    style={{
                        margin: 'auto'
                    }} 
                >

                    <Plugin 
                        color='#ffc107' 
                        size={24} 
                    />

                </Nav.Item>
            </Nav>

    )

}