import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { RecoilRoot } from 'recoil';

import useToken from './hooks/useToken';
import useSession from './hooks/useSession';

import Login from './layout/Login';
import Home from './layout/Home';

import WsToastNotification from './components/WsToastNotification';
import { getAppEnvironment, getAppNamespace } from './hooks/useApp';
import { v4 } from 'uuid';
import { Spinner } from 'react-bootstrap';
import NetworkSpeedToastNotification from './components/NetworkSpeedToastNotification';

function App() {

    const {token, setToken, isConfigLoaded} = useToken();
    const { session, isLoading } = useSession(token);

    if (!localStorage.getItem(`${getAppNamespace}.uuid`)) {

        localStorage.setItem(`${getAppNamespace}.uuid`, v4());

    }

    if (isLoading && !isConfigLoaded) {

        return (

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}>
                <Spinner animation='border' size='lg' />
            </div>

        );

    }

    const routeAppPage = () => {

        return (

            token && session ? 
            ( 

                <>
                    <WsToastNotification token={token} environment={getAppEnvironment} />
                    <NetworkSpeedToastNotification />
                    <Home token={token} setToken={setToken} />
                </>

            ) : (

                <Login setToken={setToken} />

            )

        )

    }

    return (

        <RecoilRoot>

        { 
            
            routeAppPage()

        }

        </RecoilRoot>

    );

}

export default App;
