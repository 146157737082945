import { useEffect, useRef, useState } from "react";

import Ajax from "../../components/Ajax";

import { Table } from "react-bootstrap";

import moment from "moment";
import LogModal from "../../components/LogModal";
import CardDate from "../../components/CardDate";
import useCalendar from "../../hooks/useCalendar";

export default function Log(props) {

    const { user } = props;

    const didMount = useRef(false);

    const [loading, isLoading] = useState(true);

    const [updateList, setUpdateList] = useState(false);

    const [data, setData] = useState([]);
    const [logData, setLogData] = useState({});

    const [isOpen, toggleModal] = useState(false);

    const [date, setDate] = useState(new Date());

    const { formatDateHeader } = useCalendar({updateList});

    useEffect(() => {

        if (didMount.current) return;

        const retrieveLog = async() => {

            const query = user ? `?utente=${user}` : ''

            return await Ajax({
                route : `eventi/${moment(date).format('YYYY/MM/DD')}${query}`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveLog()
        .then(data => {

            isLoading(false);

            setData(data);

        });
    
    }, [updateList, date]);

    const getLogData = id => e => {

        const retrieveLog = async(id) => {

            if (!id) {

                return Promise.resolve({});

            }

            return await Ajax({
                route : `eventi/${id}`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveLog(id)
        .then(data => {

            if (!data.utente) {

                data.utente = {};

            }

            setLogData(data);
            toggleModal(true);

        })
        .catch(err => {
 
        });

    }

    const header = () => {

        const description = user ? '' : 'Log';

        return formatDateHeader(description, date, 'DD/MM/');

    }

    const gotoNext = () => {

        setDate(moment(date).subtract('1', 'day'));

    }

    const gotoToday = () => {

        setDate(new Date());

    }

    const gotoPrev = () => {

        setDate(moment(date).add('1', 'day'));

    }

    return (

        <>

            <CardDate
                loading={loading}
                header={header()}
                gotoNext={gotoNext}
                gotoPrev={gotoPrev}
                gotoToday={gotoToday}
                onUpdateClick={e => setUpdateList(new Date())}
                showInfoClick
                showBreadcrumb={!user}
            >

                <Table responsive striped borderless hover className='m-0 tabella-eventi'>
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Messaggio</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length > 0 ?

                            (

                                data.map(record => {
                                    
                                    const { _id, data, evento, utente } = record;

                                    const account = utente ? <></> : <b>{utente?.email}</b>;

                                    return (

                                        <tr key={_id} onClick={getLogData(_id)}>
                                            <td style={{ whiteSpace : 'nowrap' }}>{moment(data).format('DD/MM/YY HH:mm:ss')}</td>
                                            <td>{account} {evento}</td>
                                        </tr>

                                    );

                                })

                            ) : (

                                <tr><td colSpan={3} style={{fontStyle : 'italic'}}>Nessun log in archivio.</td></tr>

                            )
                        }
                    </tbody>
                </Table>
                
            </CardDate>

            <LogModal
                data={logData}
                isOpen={isOpen}
                toggleModal={toggleModal}
                setUpdateList={setUpdateList}
            />

        </>
         
    )

}