import { useEffect, useState } from 'react';

import Ajax from './Ajax';

import { Alert, Button, ButtonGroup, Card, Col, Form, Row } from 'react-bootstrap';
import { Briefcase, CheckCircle, Envelope, FileEarmarkText, Telephone, XCircle } from 'react-bootstrap-icons';
import FormSelect from './FormSelect';
import InterfaceModal from './InterfaceModal';
import RightModalFooterNav from './RightModalFooterNav';
import FormFloatingTextarea from './FormFloatingTextarea';
import FormDayPicker from './FormDayPicker';
import moment from 'moment';
import FormFloatingAmountInput from './FormFloatingAmountInput';
import ActionInputWrapper from './ActionInputWrapper';
import SelectUpcomingAvailability from './SelectUpcomingAvailability';
import { usePermissions } from '../hooks/usePermissions';
import ButtonAppointmentSchedule from './ButtonAppointmentSchedule';
import PatientModal from './PatientModal';
import FormFloatingSearchSelectInput from './FormFloatingSearchSelectInput';
import ButtonDeleteItem from './ButtonDeleteItem';
import { useRecoilValue } from 'recoil';
import { deleteItemState } from '../atoms';
import SelectAppointmentType from './SelectAppointmentType';
import useCalendar from '../hooks/useCalendar';
import SelectAppointmentDuration from './SelectAppointmentDuration';
import ButtonOfficeHoursSetter from './ButtonOfficeHoursSetter';
import ButtonFisrtAppointmentSlot from './ButtonFisrtAppointmentSlot';
import FormSpinnerButton from './FormSpinnerButton';

export default function AppointmentModal(props) {

    const { isCalendarEditable, userFilter } = usePermissions();
    
    const { data, handleOnChange, isOpen, toggleModal, list, planner, updateList, setUpdateList, filters, selectedSlot, setSelectedSlot } = props;

    const [loading, isLoading] = useState(false);

    const [doctors, setDoctors] = useState([]);
    const [patients, setPatients] = useState([]);

    const [patientData, setPatientData] = useState({});
    const [newPatientID, setNewPatientID] = useState('');

    const [homonyms, setHomonyms] = useState([]);

    const [valid, setValidity] = useState({});

    const [isUpdated, setIsUpdated ] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isBlurred, setModalBlur] = useState(false);

    const [isCancelled, setCancelled] = useState(false);

    const [isFormOpen, toggleFormModal] = useState(false);

    const [isScheduleOpen, toggleScheduleModal ] = useState(false);
    const [isFirstAppointmentOpen, toggleFirstAppointmentOpen ] = useState(false);

    const itemPendingDeletion = useRecoilValue(deleteItemState);

    const { eventCategory } = useCalendar({});

    useEffect(() => {

        isLoading(false);
        setIsDeleted(false);

        setIsUpdated(false);
        setCancelled(false);
        
        setValidity({});

    }, [isOpen]);

    useEffect(() => {

        getDatastoreData();

        if (!isOpen) return;

        if (data._id) return;

        if (filters?.length === 1) {

            handleOnChange({ target : { name: 'medico', value: filters[0] }});

        }

        if (Object.keys(selectedSlot).length) {

            const { data, durata } = selectedSlot;

            handleOnChange({ target : { name: 'data', value: data }});
            handleOnChange({ target : { name: 'durata', value: durata }});

            setSelectedSlot({});

        }

    }, [isOpen, updateList]);

    const getDatastoreData = async id => {

        const retrieveDoctors = async() => {

            return await Ajax({
                route : `medici`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        const retrievePatients = async() => {

            return await Ajax({
                route : `pazienti`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        setDoctors(await retrieveDoctors());
        setPatients(await retrievePatients());

    }

    useEffect(() => {

        if (!isFormOpen) return;

        setPatientData(patients.find(item => item._id === data.paziente));

    }, [isFormOpen]);

    const handleOnChangePatient = e => {

        const { name, value } = e.target;

        setPatientData({
            ...patientData,
            [name] : value
        })

    }

    useEffect(() => {

        handleOnChange({ target : { name: 'paziente', value: newPatientID }});

    }, [newPatientID]);

    useEffect(() => {

        Object.keys(data).forEach(item => {

            if (['_id', 'medico', 'categoria', 'data', 'durata'].includes(item)) {
                return;
            };

            handleOnChange({ target : { name: item, value: '' }});
            
        })

    }, [data.categoria]);

    const searchHomonyms = () => {

        const homonymsMap = new Map();
        const homonymPairs = [];
        
        patients.forEach((patient, index) => {

          const key = `${patient.nome} ${patient.cognome}`;

          if (homonymsMap.has(key)) {

            const previousIndex = homonymsMap.get(key);

            if (previousIndex !== -1) {

              homonymPairs.push(patients[previousIndex]._id);
              homonymsMap.set(key, -1);

            }

            homonymPairs.push(patient._id);

          } else {

            homonymsMap.set(key, index);

          }

        });

        setHomonyms(homonymPairs);

    }

    useEffect(() => {

        searchHomonyms();

    }, [patients])

    const isFormDataValid = () => {

        const errors = {};

        if (!data.medico) {
            errors.medico = 'Selezionare il medico'
        }

        if (!data.categoria && !data.paziente) {
            errors.paziente = 'Selezionare il paziente'
        }

        if (!data.data) {
            errors.data = 'Selezionare una data'
        }

        if (data.data && !data.durata) {
            errors.data = 'Selezionare la durata della visita'
        }

        if (data.importoAcconto >= data.importoDovuto) {
            errors.importoAcconto = 'Acconto non valido'
        }

        if (data.confermato && !data.importoDovuto) {
            errors.importoDovuto = 'Importo non valido'
        }

        if (data.importoSaldato === 1 && !data.modalitàPagamento) {
            errors.modalitàPagamento = 'Selezionare la modalità di pagamento'
        }

        if (data.visitaConCollaboratore && !data.medicoPrimaVisita) {
            errors.medicoPrimaVisita = 'Selezionare il medico'
        }

        if (data.visitaConCollaboratore && !data.dataPrimaVisita) {
            errors.dataPrimaVisita = 'Selezionare una data'
        }

        if (data.visitaConCollaboratore && data.dataPrimaVisita && !data.durataPrimaVisita) {
            errors.dataPrimaVisita = 'Selezionare la durata della visita'
        }

        if (data.sposta === 1 && !data.nuovaData) {
            errors.nuovaData = 'Selezionare una data'
        }

        if (data.sposta === 1 && data.nuovaData && !data.durataNuovaData) {
            errors.nuovaData = 'Selezionare la durata della visita'
        }

        const checkDateOverlap = (start1, end1, start2, end2) => {

            return (

                (start1.isBefore(end2) && end1.isAfter(start2)) || 
                    (start2.isBefore(end1) && end2.isAfter(start1))

            );

        }

        if (!data._id &&
                list
                .filter(item => item.record.medico._id === data.medico)
                .some(item => {

                    const start = moment(data.data);
                    const end = moment(data.data).add(data.durata, 'minutes');

                    return checkDateOverlap(start, end, moment(item.start), moment(item.end));

                }) === true) {

            errors.data = 'Rilevato conflitto con altra visita in calendario'

        }

        if (data.sposta === 1 &&
                list
                .filter(item => item.record.medico._id === data.medico)
                .some(item => {

                    const start = moment(data.nuovaData);
                    const end = moment(data.nuovaData).add(data.durataNuovaData, 'minutes');

                    return checkDateOverlap(start, end, moment(item.start), moment(item.end));

                }) === true) {

            errors.nuovaData = 'Rilevato conflitto con altra visita in calendario'

        }

        setValidity(errors);

        return Object.keys(errors).length === 0;

    }

    const handleSubmit = e => {

        if (!isFormDataValid()) {

            return;

        }

        const updateAppointment = async() => {

            isLoading(true);

            const updateData = { ...data };

            delete updateData.conferma;

            if (updateData.annulla) {

                setCancelled(true);

            }

            if (updateData.sposta) {

                updateData.data = updateData.nuovaData;
                updateData.durata = updateData.durataNuovaData;

            }

            return await Ajax({
                route : `appuntamenti/${data._id ?? ''}`, 
                parametri : { data : updateData },
                metodo : data._id ? 'post' : 'put',
            })
            .catch(err => {

            });

        }

        updateAppointment()
        .then((res) => {

            isLoading(false);
            
            setUpdateList(new Date());

            if (res.esito === 'collisione') {

                const prop = data.sposta === 1 ? 'nuovaData' : 'data';

                setValidity({ [prop] : 'Rilevato conflitto con altra visita in calendario' })

                return;
            }

            setIsUpdated(true);

            if (!data._id) {

                handleOnChange({ target : { name :'_id', value : res.insertedId }});

            }

        })
        .catch(err => {

        });

    }

    const handleOnDelete = () => {

        const deleteAppointment = async () => {

            return await Ajax({
                route : `appuntamenti/${data._id}`, 
                parametri : { data : { data } },
                metodo : 'delete',
            })
            .catch(err => {

            })    
        }

        deleteAppointment()
        .then((res) => {

            setIsDeleted(true);
            setUpdateList(new Date());

            toggleModal(false);

        });

    }

    const handleChangeBlur = (isOpen) => {

        setModalBlur(isOpen);

    }

    const onConfirmClick = (key) => (e) => {

        const { name } = e.target;

        const value = Math.abs((data[key] || 0) - 1);

        handleOnChange({ target : { name, value } });

    }

    const onButtonGroupClick = type => (e) => {

        const value = data.tipo !== type ? type : '';

        handleOnChange({ target : { name : 'tipo', value } });
        handleOnChange({ target : { name : 'medicoPrimaVisita', value : '' } });

    }

    const onButtonClick = (e) => {

        const { name } = e.target;

        const value = Math.abs((data[name] || 0) - 1);

        handleOnChange({ target : { name, value } });
        handleOnChange({ target : { name : 'nuovaData', value : '' } });

        if (value === 1) {

            const swappedName = { 'annulla' : 'sposta', 'sposta' : 'annulla' };

            handleOnChange({ target : { name : swappedName[name], value : 0 } });

        }

    }

    const getOfficeHours = (doctor) => {

        if (!doctor) return {};

        return doctors.find(item => item._id === doctor)?.orariRicevimento;

    }

    const handleOnSwitch = (e) => {

        const { name, checked } = e.target;

        handleOnChange({ target : {name, value: checked}});

        if (name === 'visitaInPresenza') {

            handleOnChange({ target : {name: 'arrivato', value: ''}});
            handleOnChange({ target : {name: 'uscito', value: ''}});
            handleOnChange({ target : {name: 'videochiamata', value: ''}});

        }

    }

    const category = eventCategory[(data.categoria || '')].toLowerCase();

    return (

        <InterfaceModal
            isBlurred={isBlurred || isScheduleOpen || isFirstAppointmentOpen || isFormOpen || itemPendingDeletion}
            show={isOpen}
            onHide={toggleModal}
            isAlertOpen={isUpdated}
            header={

                <>
                    {data._id ? 'Dettaglio' : 'Aggiungi'} <span className={category !== 'visita' ? 'inverti-colori' : ''}>{category}</span>
                </>

            }
            footer= {

                <>
                    <RightModalFooterNav>

                    {
                        isCalendarEditable &&

                            <>
                                <ButtonDeleteItem
                                    disabled={!data._id || isCancelled || (data._id && data.primaVisita && data.tipo === 1)}
                                    body={`Eliminare la visita e tutti i dati associati?\nL'eliminazione è irreversibile e comporta una perdita di dati.\nNessuna notifica sarà generata da questa operazione.`}
                                    onClick={handleOnDelete}
                                />
                                {

                                    data._id && data.primaVisita && data.tipo === 1 &&
                                    <Alert className='eliminazione-prima-visita-collaboratore m-0 ms-2 px-2 py-0 d-flex align-items-center' style={{ height: '38px'}} variant='warning'><small>Eliminazione da <b>prima visita medico</b></small></Alert>

                                }
                            </>

                    }

                    </RightModalFooterNav>

                    <FormSpinnerButton 
                        caption='Salva' 
                        title='Salva dati' 
                        disabled={isCancelled} 
                        loading={loading} 
                        onClick={handleSubmit} 
                        error={Object.keys(valid).length > 0} 
                    />

                </>

            }
            overflowVisible={!!data.categoria}
        >

            <ActionInputWrapper>
                
                <FormFloatingSearchSelectInput
                    label='Medico'
                    name='medico'
                    data={
                        doctors
                        .filter(item => userFilter.length > 0 ? userFilter.includes(item._id) : true)
                        .sort((a, b) => a.cognome.localeCompare(b.cognome))                    
                    }
                    homonyms={[]}
                    onChange={handleOnChange}
                    value={data}
                    valid={valid}
                    readOnly={data._id || data.importoSaldato}
                    style={{
                        textOverflow: 'ellipsis',
                        //marginBottom: '-1rem',
                        borderBottomRightRadius : 0,
                        borderTopRightRadius : 0,
                        borderRight: 0,
                    }}
                    updateList={list}
                />

                <SelectAppointmentType
                    name='categoria' 
                    data={data}
                    onChange={handleOnChange}
                    disabled={!data.medico || data.importoSaldato}
                    noRadius
                />

                <ButtonOfficeHoursSetter
                    className='action-input'
                    name='orariRicevimento'
                    data={doctors.find(item => item._id === data.medico)}
                    onChange={handleOnChange}
                    isModalVisible={handleChangeBlur}
                    disabled={!data.medico}
                    readOnly
                    icon={<Briefcase size={20} />}
                />

            </ActionInputWrapper>

            {
                !data.categoria &&

                    <ActionInputWrapper>

                        <FormFloatingSearchSelectInput
                            label='Paziente'
                            name='paziente'
                            data={
                                patients
                                .filter(item => !item.medico || item.medico.split(';').includes(data.medico))
                                .sort((a, b) => a.cognome.localeCompare(b.cognome))
                            }
                            homonyms={homonyms}
                            onChange={handleOnChange}
                            value={data}
                            valid={valid}
                            disabled={!data.medico}
                            style={{
                                textOverflow: 'ellipsis',
                                //marginBottom: '-1rem',
                                borderBottomRightRadius : 0,
                                borderTopRightRadius : 0,
                                borderRight: 0,
                            }}
                            onAddNew={() => {setPatientData({}); toggleFormModal(true)}}
                            updateList={list}
                            readOnly={data.importoSaldato || data._id}
                        />

                        <Button 
                            variant='light' 
                            className='action-button p-2 d-flex align-items-center' 
                            size='lg'
                            disabled={!data.paziente}
                            title='Visualizza dettaglio'
                            onClick={() => toggleFormModal(true)}
                        >
                            <FileEarmarkText />
                        </Button>

                        <Button 
                            variant='light' 
                            className='action-button p-2 d-flex align-items-center' 
                            size='lg'
                            disabled={ !patients.find(item => item._id ===  data.paziente)?.telefono }
                            title='Chiama'
                            onClick={e => {

                                window.location.href = `tel:${patients.find(item => item._id ===  data.paziente)?.telefono}`;

                            }}
                        >
                            <Telephone />
                        </Button>

                        <Button 
                            variant='light' 
                            className=' action-button p-2 d-flex align-items-center' 
                            size='lg'
                            disabled={ !patients.find(item => item._id ===  data.paziente)?.email }
                            title={`Scrivi un'email`}
                            onClick={e => {

                                window.location.href = `mailto:${patients.find(item => item._id ===  data.paziente)?.email}`;

                            }}
                        >
                            <Envelope />
                        </Button>

                    </ActionInputWrapper>

            }

            <ActionInputWrapper>

                <FormDayPicker
                    style={{
                        marginBottom: '-1rem',
                        borderBottomRightRadius : 0,
                        borderTopRightRadius : 0,
                        borderRight: 0,
                        cursor: 'pointer'
                    }}
                    label='Data'
                    name='data'
                    onChange={handleOnChange}
                    value={data}
                    valid={valid}
                    isModalVisible={handleChangeBlur}
                    showTimeSelect
                    disabled={!data.medico}
                    readOnly={data._id || data.importoSaldato}
                    officeHours={getOfficeHours(data.medico)}
                    planner={
                        planner?.filter(item => (item.medico === data.medico) || (item.soggetto === 1 && item.disponibilità === 0))
                    }
                    excludeTimes={
                        list
                        .filter(item => item.record.medico._id === data.medico)
                        .map(item => (
                            {
                                inizio : moment(item.start).toDate(),
                                fine : moment(item.end).toDate(),
                            }
                        ))
                    }
                />

                <SelectAppointmentDuration
                    name='durata' 
                    data={data}
                    subject='data'
                    list={
                        list
                        .filter(item => item.record.medico._id === data.medico)
                    }
                    officeHours={getOfficeHours(data.medico)}
                    planner={
                        planner?.filter(item => (item.medico === data.medico) || (item.soggetto === 1 && item.disponibilità === 0))
                    }
                    onChange={handleOnChange}
                    disabled={!data.medico || !data.data}
                    readOnly={data.confermato || data.importoSaldato}
                />

                <SelectUpcomingAvailability 
                    name='data' 
                    subject={data.medico}
                    onChange={handleOnChange}
                    disabled={!data.medico || data._id || data.importoSaldato}
                    noRadius
                />

                <ButtonAppointmentSchedule
                    isOpen={isScheduleOpen}
                    toggleModal={toggleScheduleModal}
                    appointment={data}
                    doctors={doctors}
                    subject={patients.find(item => item._id ===  data.paziente)}
                    list={list}
                    setUpdateList={setUpdateList}
                    disabled={ !data.data || !data.paziente }
                    handleOnChange={handleOnChange}
                    handleSubmit={handleSubmit}
                />

            </ActionInputWrapper>

            {
                !data.categoria &&

                    <div className='d-flex justify-content-between gap-2 mb-3'>

                        <ButtonGroup style={{ flex: 1 }}>

                            <Button
                                className={data.importoSaldato && 'read-only'}
                                style={{ flex: 1 }}
                                variant={ data.tipo === 1 ? 'info' : 'light' } 
                                onClick={ onButtonGroupClick(1) }
                            >
                                Prima visita (1V)
                            </Button>
                            <Button
                                className={data.importoSaldato && 'read-only'}
                                style={{ flex: 1 }}
                                variant={ data.tipo === 2 ? 'info' : 'light' } 
                                onClick={ onButtonGroupClick(2) }
                            >
                                Controllo (C)
                            </Button>
                            <Button
                                className={data.importoSaldato && 'read-only'}
                                style={{ flex: 1 }}
                                variant={ data.tipo === 4 ? 'info' : 'light' } 
                                onClick={ onButtonGroupClick(4) }
                            >
                                Psicoterapia (PS)
                            </Button>
                            <Button
                                className={data.importoSaldato && 'read-only'}
                                style={{ flex: 1 }}
                                variant={ data.tipo === 3 ? 'info' : 'light' } 
                                onClick={ onButtonGroupClick(3) }
                            >
                                D+ (D+)
                            </Button>

                        </ButtonGroup>

                    </div>

            }

            {
                data.tipo === 1 &&

                (
                    data.primaVisita ? (
                       
                        <Alert
                            variant='success'
                        >
                            Appuntamento come medico collaboratore per la 1V di <b>{ moment(list.find(item => item._id === data.primaVisita).start).format('dddd DD MMMM HH:mm') }</b>.
                        </Alert>

                    ) : (

                        <Card className='mb-3'>

                            <Card.Header 
                                className='py-1 font-normal'
                                style={{
                                    border: !data.visitaConCollaboratore ? 'none' : ''
                                }}
                            >

                                <Form.Check
                                    id='visitaConCollaboratore'
                                    name='visitaConCollaboratore' 
                                    type='switch'
                                    label='Collaboratore 1V'
                                    checked={data.visitaConCollaboratore}
                                    onChange={handleOnSwitch}
                                    className='switch-collaboratore'
                                    style={{
                                        height: '28.39px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '.5rem',
                                    }}
                                    disabled={data._id}
                                />

                            </Card.Header>
                            {
                                data.visitaConCollaboratore &&
                                    <Card.Body
                                        className='pb-0'
                                    >
                                        <ActionInputWrapper>

                                            <FormFloatingSearchSelectInput
                                                label='Medico'
                                                name='medicoPrimaVisita'
                                                data={
                                                    doctors
                                                    .filter(item => item._id !== data.medico)
                                                    .sort((a, b) => a.cognome.localeCompare(b.cognome))                    
                                                }
                                                homonyms={[]}
                                                onChange={handleOnChange}
                                                value={data}
                                                valid={valid}
                                                readOnly={data._id || data.importoSaldato}
                                                style={{
                                                    textOverflow: 'ellipsis',
                                                    borderBottomRightRadius : 0,
                                                    borderTopRightRadius : 0,
                                                    borderRight: 0,
                                                }}
                                                disabled={!data.visitaConCollaboratore}
                                                updateList={list}
                                            />

                                            <ButtonOfficeHoursSetter
                                                className='action-input'
                                                name='orariRicevimento'
                                                data={doctors.find(item => item._id === data.medicoPrimaVisita)}
                                                onChange={handleOnChange}
                                                isModalVisible={handleChangeBlur}
                                                disabled={!data.medicoPrimaVisita}
                                                readOnly
                                                icon={<Briefcase size={20} />}
                                            />

                                        </ActionInputWrapper>

                                        <ActionInputWrapper>

                                            <FormDayPicker
                                                style={{
                                                    marginBottom: '-1rem',
                                                    borderBottomRightRadius : 0,
                                                    borderTopRightRadius : 0,
                                                    borderRight: 0,
                                                    cursor: 'pointer'
                                                }}
                                                label='Data'
                                                name='dataPrimaVisita'
                                                onChange={handleOnChange}
                                                value={data}
                                                valid={valid}
                                                isModalVisible={handleChangeBlur}
                                                showTimeSelect
                                                disabled={!data.medicoPrimaVisita}
                                                readOnly={data._id || data.importoSaldato}
                                                officeHours={getOfficeHours(data.medicoPrimaVisita)}
                                                planner={
                                                    planner?.filter(item => (item.medico === data.medicoPrimaVisita) || (item.soggetto === 1 && item.disponibilità === 0))
                                                }
                                                excludeTimes={
                                                    list
                                                    .filter(item => item.record.medico._id === data.medicoPrimaVisita)
                                                    .map(item => (
                                                        {
                                                            inizio : moment(item.start).toDate(),
                                                            fine : moment(item.end).toDate(),
                                                        }
                                                    ))
                                                }
                                            />

                                            <SelectAppointmentDuration
                                                name='durataPrimaVisita' 
                                                data={data}
                                                subject='dataPrimaVisita'
                                                list={
                                                    list
                                                    .filter(item => item.record.medico._id === data.medicoPrimaVisita)
                                                }
                                                officeHours={getOfficeHours(data.medicoPrimaVisita)}
                                                planner={
                                                    planner?.filter(item => (item.medico === data.medicoPrimaVisita) || (item.soggetto === 1 && item.disponibilità === 0))
                                                }
                                                onChange={handleOnChange}
                                                disabled={!data.medicoPrimaVisita || !data.dataPrimaVisita}
                                                readOnly={data._id || data.importoSaldato}
                                            />

                                            <SelectUpcomingAvailability 
                                                name='dataPrimaVisita' 
                                                subject={data.medicoPrimaVisita}
                                                onChange={handleOnChange}
                                                disabled={!data.medicoPrimaVisita || data._id || data.importoSaldato}
                                                noRadius
                                            />

                                            <ButtonFisrtAppointmentSlot
                                                isOpen={isFirstAppointmentOpen}
                                                toggleModal={toggleFirstAppointmentOpen}
                                                appointment={data}
                                                doctors={doctors}
                                                subject={[data.medico, data.medicoPrimaVisita]}
                                                list={list}
                                                setUpdateList={setUpdateList}
                                                disabled={ data._id || !data.medico || !data.medicoPrimaVisita }
                                                onChange={handleOnChange}
                                            />

                                        </ActionInputWrapper>
                                    </Card.Body>
                            }
                        </Card>

                    )

                )

            }

            <FormFloatingTextarea
                label='Note'
                name='note'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

            {
                !data.categoria &&
                    <>

                        <div className='d-grid gap-2 mb-3'>
                            
                            <Button
                                name='confermato'
                                variant={ data.confermato === 1 ? 'success' : 'light' } 
                                onClick={ onConfirmClick('confermato') }
                                disabled={data.importoSaldato}
                            >
                                Confermato
                            </Button>

                        </div>

                        <div className='d-flex justify-content-between gap-2 mb-3'>

                            <Form.Check
                                id='visitaInPresenza'
                                name='visitaInPresenza' 
                                type='switch'
                                label='Visita in presenza'
                                checked={data.visitaInPresenza}
                                onChange={handleOnSwitch}
                                disabled={!data.confermato}
                            />

                        </div>

                        <div className='d-flex justify-content-between gap-2 mb-3'>

                            <div style={{ width: '66.66%' }}>

                                <ButtonGroup style={{ width: '100%' }}>

                                    <Button
                                        style={{ width: '50%' }}                   
                                        name='arrivato'
                                        variant={ data.arrivato === 1 ? 'info' : 'light' } 
                                        onClick={ onConfirmClick('arrivato') }
                                        disabled={!data.confermato || !data.visitaInPresenza || data.uscito === 1}
                                    >
                                        Paziente arrivato (ARR)
                                    </Button>

                                    <Button
                                        style={{ width: '50%' }}
                                        name='uscito'
                                        variant={ data.uscito === 1 ? 'info' : 'light' } 
                                        onClick={ onConfirmClick('uscito') }
                                        disabled={!data.confermato || !data.visitaInPresenza || data.arrivato !== 1}
                                    >
                                        Paziente uscito (USC)
                                    </Button>

                                </ButtonGroup>
                            </div>

                            <Button
                                style={{ width: '33.33%' }}
                                name='videochiamata'
                                variant={ data.videochiamata === 1 ? 'info' : 'light' } 
                                onClick={ onConfirmClick('videochiamata') }
                                disabled={!data.confermato || data.visitaInPresenza}
                            >
                                Videochiamata (VID)
                            </Button>

                        </div>

                        <Card className='mb-3'>
                            <Card.Header className='py-1 font-normal'>Pagamento</Card.Header>
                            <Card.Body
                                className='pb-0'
                            >

                                <Row>

                                    <Form.Group as={Col} md={12} lg={4}>

                                        <FormFloatingAmountInput 
                                            label='Acconto'
                                            name='importoAcconto'
                                            onChange={handleOnChange}
                                            value={data}
                                            valid={valid}
                                            readOnly={isCalendarEditable === false || data.importoSaldato}
                                        />

                                    </Form.Group>

                                    <Form.Group as={Col} md={12} lg={4}>

                                        <FormFloatingAmountInput 
                                            label='Dovuto'
                                            name='importoDovuto'
                                            onChange={handleOnChange}
                                            value={data}
                                            valid={valid}
                                            readOnly={isCalendarEditable === false || data.importoSaldato}
                                            actionButton={
                                                <Button 
                                                    className='px-2'
                                                    variant={ data.importoSaldato === 1 ? 'success' : 'danger' }
                                                    readOnly={isCalendarEditable === false}
                                                    onClick={e => {
                                                        handleOnChange({ target : { name : 'importoSaldato', value : Math.abs((data.importoSaldato || 0) - 1) } })
                                                    }}
                                                >
                                                    { data.importoSaldato === 1 ? <CheckCircle size={28} /> : <XCircle size={28} /> }
                                                </Button>                                    
                                            }
                                        />

                                        <Form.Check
                                            className='mb-3'
                                            style={{ marginTop : '-.5rem' }}
                                            type='switch'
                                            id='bollo'
                                            name='bollo' 
                                            label='Imposta di bollo (€ 2.00)'
                                            checked={data.bollo}
                                            onChange={handleOnSwitch}
                                            disabled={!data.importoDovuto || data.importoDovuto < 77}
                                        />                

                                    </Form.Group>

                                    <Form.Group as={Col} md={12} lg={4}>

                                        <FormSelect
                                            label='Modalità'
                                            name='modalitàPagamento'
                                            onChange={handleOnChange}
                                            value={data}
                                            valid={valid}
                                            readOnly={isCalendarEditable === false || data.importoSaldato}
                                        >
                                            <option value=''>Seleziona voce</option>
                                            <option value='CONTANTI'>CONTANTI</option>
                                            <option value='POS'>POS</option>
                                            <option value='BONIFICO'>BONIFICO</option>
                                        </FormSelect>

                                    </Form.Group>

                                </Row>

                            </Card.Body>
                        </Card>
                    </>
            }

            {
                
                isCalendarEditable && data._id && !data.categoria && !data.confermato && !data.importoSaldato && !data.visitaConCollaboratore &&

                    <>
                        <div className='d-grid gap-2 mb-3'>
                            <Button
                                name='annulla' 
                                variant={ !data.annulla ? 'light' : 'danger' } 
                                onClick={ onButtonClick }
                            >
                                Annulla
                            </Button>

                            <Button 
                                name='sposta'
                                variant={ !data.sposta ? 'light' : 'warning' } 
                                onClick={ onButtonClick }
                            >
                                Sposta
                            </Button>
                        </div>

                        {

                            data.sposta === 1 &&

                                <ActionInputWrapper>

                                    <FormDayPicker
                                        style={{
                                            marginBottom: '-1rem',
                                            borderBottomRightRadius : 0,
                                            borderTopRightRadius : 0,
                                            borderRight: 0,
                                        }}
                                        label='Nuova data'
                                        name='nuovaData'
                                        onChange={handleOnChange}
                                        value={data}
                                        valid={valid}
                                        isModalVisible={handleChangeBlur}
                                        showTimeSelect
                                        disabled={!data.medico}
                                        officeHours={getOfficeHours(data.medico)}
                                        planner={
                                            planner?.filter(item => (item.medico === data.medico) || (item.soggetto === 1 && item.disponibilità === 0))
                                        }
                                        excludeTimes={
                                            list
                                            .filter(item => item.record.medico._id === data.medico)
                                            .map(item => (
                                                {
                                                    inizio : moment(item.start).toDate(),
                                                    fine : moment(item.end).toDate(),
                                                }
                                            ))
                                        }
                                    />

                                    <SelectAppointmentDuration
                                        name='durataNuovaData' 
                                        data={data}
                                        subject='nuovaData'
                                        list={
                                            list
                                            .filter(item => item.record.medico._id === data.medico)
                                        }
                                        officeHours={getOfficeHours(data.medico)}
                                        planner={
                                            planner?.filter(item => (item.medico === data.medico) || (item.soggetto === 1 && item.disponibilità === 0))
                                        }
                                        onChange={handleOnChange}
                                        disabled={!data.nuovaData}
                                    />

                                    <SelectUpcomingAvailability
                                        name='nuovaData' 
                                        subject={data.medico}
                                        onChange={handleOnChange}
                                        disabled={!data.medico}
                                    />

                                </ActionInputWrapper>

                        }

                    </>

            }
        
            <PatientModal
                data={patientData}
                handleOnChange={handleOnChangePatient}                   
                isOpen={isFormOpen}
                toggleModal={toggleFormModal}
                list={patients}
                setUpdateList={setUpdateList}
                setNewPatientID={setNewPatientID}
                useAutoUpdate
            />

            <style jsx>
                {`
                    .switch-collaboratore input {
                        margin-top: 0;
                    }
                `}
            </style>

        </InterfaceModal>

    )

}