import { useState, useMemo, useEffect } from 'react';
import { ButtonGroup, Col, Container, Row, Spinner } from 'react-bootstrap';
import { ArrowLeft, ArrowRight, CalendarCheck } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import useCalendar from '../hooks/useCalendar';
import moment from 'moment';
import { usePermissions } from '../hooks/usePermissions';

const weekDays = { nl: ['Lu', 'Ma', 'Me', 'Gi', 'Ve', 'Sa', 'Do'] };

const ListItem = ({ item, createAppointmentTypeLabel }) => {

    const {title, start, end, record : { tipo }} = item;

    const label = createAppointmentTypeLabel(tipo);

    return (
        <div className={`d-flex flex-column m-0 py-1 event-list-item ${moment(end).isBefore(new Date()) && 'text-secondary'}`}>
            <div className='no-wrap'>{title}</div>
            <small>{moment(start).format('HH:mm')} - {moment(end).format('HH:mm')}{label}</small>
        </div>
    );

}

const AppointmentWidget = () => {

    const today = new Date();

    const [ selectedDate, setSelectedDate ] = useState(today);

    const [showDate, setShowDate] = useState(today);

    const { isCalendarEditable, isCalendarFilterable, userFilter } = usePermissions();

    const { 

        loading,
        data,

        createAppointmentTypeLabel,

        filterEvents

    } = useCalendar({ loadDatastore: true });

    const [ eventsByDay, setEventsByDay ] = useState({});

    const [columns, setColumns] = useState([]);
    const itemsPerColumn = 6;

    const firstDayThisMonth = new Date(showDate.getFullYear(), showDate.getMonth(), 1);

    const dayOfWeek = (firstDayThisMonth.getDay() + 6) % 7;

    const firstDayNextMonth = new Date(showDate.getFullYear(), showDate.getMonth() + 1, 1);

    const month = useMemo(() => {

        const m = [];

        let currentDate = new Date(firstDayThisMonth);

        while (currentDate < firstDayNextMonth) {
            
            m.push(new Date(currentDate));

            currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));

        }

        return m;

    }, [showDate, firstDayThisMonth, firstDayNextMonth]);

    useEffect(() => {

        if (loading) return;

        const events = filterEvents(data).filter(item => Object.keys(item.record.paziente)).sort((a, b) => new Date(a.start) - new Date(b.start)).filter(item => moment(item.start).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD'));

        const numColumns = Math.ceil(events.length / itemsPerColumn);
        const newColumns = [];
    
        for (let i = 0; i < numColumns; i++) {
          newColumns.push(events.slice(i * itemsPerColumn, (i + 1) * itemsPerColumn));
        }
    
        setColumns(newColumns);

        setEventsByDay(filterEvents(data).reduce((acc, item) => {

            const date = moment(item.start).format('YYYY-MM-DD');

            if (!acc[date]) acc[date]=0;

            acc[date]++;

            return acc;

        }, {}))

    }, [loading, data, selectedDate]);

    const handleOnChange = (date) => (e) => {

        setSelectedDate(date);

    }

    return (

        <Container className='mb-3' style={{ maxWidth : 'none' }}>

            <Row className='gap-md-0 gap-3 overflow-auto'>

                <Col xs={12} md={6} lg='auto' className='d-flex justify-content-center justify-content-sm-start' style={{ position: 'sticky', left: 0, background: 'white' }}>
                        
                    <div className='hl-followus'>

                        <div className='hl-month d-flex flex-wrap flex-row align-items-baseline justify-content-between px-2 py-2'>

                            <div style={{ fontSize: '1.25rem' }}>
                                {showDate.toLocaleString('it-IT', { month: 'short', year: 'numeric' }).toUpperCase()}
                            </div>

                            <div className='d-flex'>

                                <ButtonGroup  size='sm' style={{marginRight: '.25rem', height: '30.81px'}}>

                                    <Button className='d-flex align-items-center' onClick={() => setShowDate(new Date(showDate.setMonth(showDate.getMonth() - 1)))}>
                                        <ArrowLeft size={18} />
                                    </Button>
                                    <Button className='d-flex align-items-center' onClick={() => setShowDate(new Date())}>
                                        <CalendarCheck size={18} />
                                    </Button>
                                    <Button className='d-flex align-items-center' onClick={() => setShowDate(new Date(showDate.setMonth(showDate.getMonth() + 1)))}>
                                        <ArrowRight size={18} />
                                    </Button>

                                </ButtonGroup>

                            </div>

                        </div>

                        <div className='hl-month d-flex flex-wrap flex-row'>
                            {
                                weekDays.nl.map((weekDay) => (
                                    <div key={weekDay} className='hl-day d-flex justify-content-center'>
                                        <small>{weekDay}</small>
                                    </div>
                                ))
                            }
                        </div>

                        <div className='hl-month d-flex flex-wrap flex-row'>
                            <div style={{ width: `${dayOfWeek * 14.28}%` }} />
                            {
                                month.map((day) => {

                                    const date = moment(day).format('YYYY-MM-DD');

                                    const highlightSelectedDate = date === moment(selectedDate).format('YYYY-MM-DD');

                                    const variant = eventsByDay[date] ? 'info' : 'light';

                                    return (
                                        <div key={day} className='hl-date hl-day d-flex justify-content-center'>
                                            <Button
                                                onClick={handleOnChange(day)}
                                                className={`hl-day-button p-0 ${!highlightSelectedDate &&
                                                    'hl-bc0 border-0'}`}
                                                variant={highlightSelectedDate ? 'primary' : variant}
                                            >
                                                {day.getDate()}
                                            </Button>
                                        </div>
                                    );

                                })
                            }
                        </div>
                    </div>

                </Col>

                <Col xs={12} md={6} lg='auto' className='min-width-max-content'>

                    <Container>
                        <Row>
                            <Col className='d-flex justify-content-center justify-content-sm-start my-2' style={{ fontSize: '1.25rem' }}>
                                Visite {selectedDate.toLocaleString('it-IT', { weekday: 'short', day: 'numeric', month: 'short' }).toUpperCase()}
                            </Col>
                        </Row>
                        <Row className='flex-nowrap flex-column flex-md-row'>
                        {

                            loading ?

                                <Spinner className='m-auto' animation='border' role='status'>
                                    <span className='visually-hidden'>Loading...</span>
                                </Spinner>                                 
                            :

                                columns.length === 0 ? (

                                    <div className='no-wrap'>Nessuna visita in agenda.</div>

                                ) : (

                                    columns.map((column, index) => (

                                        <Col key={index} className='min-width-max-content' xs={12} md={Math.floor(12 / columns.length)} lg={Math.floor(12 / columns.length)}>
                                        {
                                            column.map(item => (
                                                <ListItem key={item._id} item={item} createAppointmentTypeLabel={createAppointmentTypeLabel} />
                                            ))
                                        }
                                        </Col>
                                    ))
    
                                )
                        }
                        </Row>
                    </Container>

                </Col>

            </Row>

            <style jsx>
                {`
                    .hl-month {
                        width: 300px;
                    }
                    .hl-month .hl-date {
                        height: 40px;
                    }
                    .hl-month .hl-date > button {
                        margin-top: 3px;
                        width: 40px;
                    }
                    .hl-day {
                        flex: 0 0 14.28%;
                    }
                    .hl-followus :global(.hl-day-button) {
                        width: 36px;
                        height: 36px;
                    }
                    .event-list-item:not(:last-child) {
                        border-bottom: 1px solid #dee2e6;
                    }
                    .event-list-item:first-child {
                        margin-top: 0 !important;
                    }
                    @media (max-width: 992px) {

                        .first-col .event-list-item:last-child {
                            border-bottom: 1px solid #dee2e6 !important;
                        }
                        
                    }                    
                `}
            </style>

        </Container>

    );
};

export default AppointmentWidget;