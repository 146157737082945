
import { Image } from 'react-bootstrap';
import logo from '../img/logo.png';
import mern from '../img/mern-stack.png';

import InterfaceModal from "./InterfaceModal";
import moment from 'moment';

export default function AboutModal(props) {

    const { isOpen, toggleModal } = props;

    const buildDate = window.REACT_APP_BUILD_DATE;

    return (

        <InterfaceModal
            show={isOpen}
            onHide={toggleModal}
            isAlertOpen={false}
            size='sm'
            readOnly
        >

            <div
                className='d-flex flex-column align-items-center gap-3'
            >
                <Image className='logo mt-2' src={logo} />
                <p className='my-0 text-center'>Via degli Olmetti, 39D<br/>00060 - Formello (RM)<br/><a href='mailto:info@iwe-italia.com'>info@iwe-italia.com</a></p>
                <p className='my-2'>Rev. <b>{moment(buildDate).format('YYYYMMDD.HHmm')}</b></p>
                <Image className='logo' src={mern} style={{ height : 28 }} />
                <p className='my-0 text-center'>2023 :: iWE Italia Srl</p>
            </div>
        </InterfaceModal>

    )

}