import { Button, ButtonGroup, Container, Navbar } from 'react-bootstrap';
import { ArrowLeft, ArrowRight, CalendarCheck } from 'react-bootstrap-icons';

export default function CalendarNavbar(props) {
    const { header, gotoNext, gotoToday, gotoPrev, dropdownMenu, timeslots, handleTimeslots } = props;

    return (
        <Navbar 
            variant='light'
            style={{
                borderTopLeftRadius: '.375rem',
                borderTopRightRadius: '.375rem',
                border: '1px solid rgba(0,0,0,.175)',
                borderBottom: 0,
                backgroundColor: 'rgba(33,37,41,.03)',
                padding: 0,
                height: 49.91
            }}
        >
            <Container style={{padding: '0 .5rem', maxWidth: '100%'}}>
                <Navbar.Brand className='m-0 d-flex align-content-start gap-2'>{header}</Navbar.Brand>
                <Navbar.Collapse className='justify-content-end'>
                    <ButtonGroup  size='sm' style={{marginRight: '.25rem', height: '30.81px'}}>
                        <Button className='d-flex align-items-center' onClick={gotoNext}>
                            <ArrowLeft size={18} />
                        </Button>
                        <Button className='d-flex align-items-center' onClick={gotoToday}>
                            <CalendarCheck size={18} />
                        </Button>
                        <Button className='d-flex align-items-center' onClick={gotoPrev}>
                            <ArrowRight size={18} />
                        </Button>
                    </ButtonGroup>
                    {dropdownMenu}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
