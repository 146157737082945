import './Users.css';

import { useEffect, useRef, useState } from "react";

import Ajax from "../../components/Ajax";

import { Badge } from "react-bootstrap";
import { FileEarmarkMedicalFill, LockFill } from "react-bootstrap-icons";

import UserModal from "../../components/UserModal";

import CardList from "../../components/CardList";
import { getLastAccessData } from "../../hooks/useAccess";
import PaginatedSearchableTable from '../../components/PaginatedSearchableTable';

export default function Users(props) {

    const didMount = useRef(false);

    const [loading, isLoading] = useState(true);

    const [updateList, setUpdateList] = useState(false);

    const [data, setData] = useState([]);
    const [userData, setUserData] = useState({});

    const [isOpen, toggleModal] = useState(false);

    useEffect(() => {

        if (didMount.current) return;

        const retrieveUsers = async() => {

            return await Ajax({
                route : 'utenti', 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveUsers()
        .then(data => {

            isLoading(false);

            setData(data);

        });
    
    }, [updateList]);

    const getUserData = id => e => {

        const retrieveUser = async(id) => {

            if (!id) {

                return Promise.resolve({});

            }

            return await Ajax({
                route : `utenti/${id}`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveUser(id)
        .then(data => {

            data.ultimoAccesso = getLastAccessData(data);

            setUserData(data);
            toggleModal(true);

        })
        .catch(err => {
 
        });

    }

    const handleOnChange = e => {

        const { name, checked, value, type } = e.target;

        setUserData({
            ...userData,
            [name] : type === 'checkbox' ? checked : value
        });

    }

    return (

        <CardList
            loading={loading}
            header='Utenti'
            onClick={getUserData()}
            setUpdateList={setUpdateList}
            detailModal={

                <UserModal
                    data={userData}
                    handleOnChange={handleOnChange}                   
                    isOpen={isOpen}
                    toggleModal={toggleModal}
                    list={data}
                    setUpdateList={setUpdateList}
                />

            }
        >

            <PaginatedSearchableTable
                className='tabella-utenti'
                columns={[
                    {header : 'Email', dataKey : 'email'},
                    {header : 'Nominativo', dataKey : 'nominativo'},
                    {header : 'Gruppo', dataKey : 'contatti'}
                ]}
                data={data.reduce((acc, item) => {

                    const showMDLock = () => {

                        if (item.gruppo === 'MED' && 
                                !item.aggiornaCalendario) {

                                return <LockFill title='Non può modificare il calendario' />
                        }

                        return <></>;

                    }

                    const showPatientForm = () => {

                        if (item.gruppo === 'MED' && 
                                item.accedeSchede) {

                                return <FileEarmarkMedicalFill title='Può accedere alle schede Pazienti' />
                        }

                        return <></>;

                    }

                    const getMDBackground = () => {

                        return { 
                            backgroundColor : item.coloreSfondo || 'rgba(var(--bs-secondary-rgb),1)',
                            borderRadius: 'var(--bs-border-radius)'
                        }

                    }

                    acc.push({
                        ...item,
                        nominativo : `${item.cognome}, ${item.nome}`,
                        contatti : (
                            <>
                                <span style={getMDBackground()}>
                                    <Badge bg='secondary'>{item.gruppo}</Badge>
                                </span>
                                {showMDLock()}
                                {showPatientForm()}
                            </>
                        ),
                    });

                    return acc

                }, [])}
                onRowClick={getUserData}
                updateList={updateList}
            />
           
        </CardList>
         
    )

}