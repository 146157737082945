
export default function RightModalFooterNav(props) {

    const { children } = props;

    return (

        <div 
            className='margin-right-auto d-flex align-items-center'
        >

            { children }
            
        </div>

    )
}