
export default function ActionInputWrapper(props) {

    const { children } = props;

    return (

        <div className='action-input d-flex align-items-top w-100 mb-3'>
        { children }
        </div>
        
    )

}