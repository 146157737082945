import React, { useEffect, useState, memo, useCallback } from "react";
import useNotes from "../hooks/useNotes";
import FormFloatingTextarea from "./FormFloatingTextarea";

const NoteTextarea = memo(({ context, label, doctor, date, name, initialValue, height }) => {

    const [data, setData] = useState({ [name]: initialValue });

    useEffect(() => {

        setData({ [name]: initialValue });

    }, [date, initialValue]);

    const handleOnChange = useCallback((e) => {

        e.stopPropagation();
        
        const { value } = e.target;
        
        setData({
            [name]: value
        });

    }, [name]);

    const handleOnKeyUp = useCallback((e) => {

        e.preventDefault();

    }, []);

    const { handleOnClick, loading } = useNotes({ context });

    return (

        <FormFloatingTextarea
            className='d-none d-md-block'
            label={label}
            name={name}
            value={data || ''}
            height={height}
            onChange={handleOnChange}
            onKeyUp={handleOnKeyUp}
            onClick={handleOnClick({ medico: doctor, data: date, valore: data[name] })}
            loading={loading}
        />

    );
    
}, (prevProps, nextProps) => {
    return (
        prevProps.context === nextProps.context &&
        prevProps.label === nextProps.label &&
        prevProps.doctor === nextProps.doctor &&
        prevProps.date === nextProps.date &&
        prevProps.name === nextProps.name &&
        prevProps.initialValue === nextProps.initialValue &&
        prevProps.height === nextProps.height
    );
});

export default NoteTextarea;
