import { CheckLg } from 'react-bootstrap-icons';
import './CircularLabel.css';
import { calculateContrast } from '../services/utils/contrast';

export default function CircularLabel(props) {

    const { className, bg, animate, selected, onClick } = props;

    const contrastRatio = calculateContrast(bg, '#212529');

    const setDefaultColour = () => {

        return bg || '#d3d4d5';

    }

    return (

        <div 
            className={`${className || ''} circular-label d-flex align-items-center justify-content-center ${animate ? 'animate' : ''}`}
            style={{ 
                backgroundColor : setDefaultColour(),
                border : contrastRatio < 4.5 ? '' : '1px solid #d3d4d5'
            }}
            title={ animate ? bg : ''}
            onClick={onClick}
        >
        {
            selected &&
                <CheckLg
                    className='checked'
                    style={{
                        color: contrastRatio < 4.5 ? '#fff' : '#212529'
                    }} 
                    size='lg' 
                />
        }
        </div>

    )

}