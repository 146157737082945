import moment from 'moment';
import { memo, useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { Trash3Fill } from 'react-bootstrap-icons';
import Ajax from '../components/Ajax';
import { v4 } from 'uuid';

export default function useDndCalendar({ 
    
    defaultView = 'work_week',
    availability = 1,
    loadDatastore = false,
    subject = null,
    selected = null,

}) {

    moment.locale('it');

    const [ events, setEvents ] = useState([]);
    const [doctors, setDoctors] = useState([]);

    const [ date, setDate ] = useState();
    const [range, setRange] = useState({});

    const [timeslots, setTimeslots] = useState(4);

    const [loading, isLoading] = useState(true);

    const views = {
        month : 'Mese',
        work_week : 'Settimana',
    }

    const [ view, setView ] = useState(defaultView);
    const [localeView, setLocaleView] = useState(views[defaultView]);

    const localizer = momentLocalizer(moment);

    const minTime = moment().hour(8).minute(0);
    const maxTime = moment().hour(20).minute(30);

    const DnDCalendar = withDragAndDrop(Calendar);

    useEffect(() => {

        if (!loadDatastore) return;

        const retrieveEvents = async() => {

            await getDatastoreData();

            return await Ajax({
                route : 'planner', 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveEvents()
        .then(data => {

            isLoading(false);

            setEvents(data.map(item => {

                const updated = {
                    ...item,
                    start : moment(item.start).toDate(),
                    end : moment(item.end).toDate(),
                }

                return updated;

            }));

        });
    
    }, []);

    const EventComponent = memo(({ event, onDeleteEvent, readOnly }) => {

        const isWeekView = view === 'work_week';

        const owner = () => {

            if (!loadDatastore) return <></>

            if (!event.medico) return <b style={{ marginBottom : isWeekView ? '.25rem' : '' }}>STRUTTURA</b>

            const { nome, cognome } = doctors.find(item => item._id === event.medico);

            return <b style={{ marginBottom : isWeekView ? '.25rem' : '' }}>{`${cognome}, ${nome}`}</b>

        }

        const startMoment = moment(event.start);
        const endMoment = moment(event.end);

        const slotDuration = endMoment.diff(startMoment, 'minutes');
        const showTitle = view === 'month' || 
                              (
                                (timeslots === 4 && slotDuration >= 120) || 
                                    (timeslots === 3 && slotDuration >= 120) ||
                                        (timeslots === 2 && slotDuration >= 60) ||
                                            (timeslots === 1 && slotDuration >= 30)
                              );

        return (
            <>
                {owner()}
                {
                    showTitle &&
                        <div style={{ marginBottom: view === 'work_week' ? '.25rem' : '' }}>{event.title}</div>
                }
                {
                    !readOnly &&
                        <Trash3Fill 
                            title={`Elimina range (${startMoment.format('HH:mm')} - ${endMoment.format('HH:mm')})`}
                            className='elimina-range'
                            onClick={() => onDeleteEvent(event._id)} 
                        />
                }
            </>
        );
        
    });
    
    const customFormats = {

        dayFormat: (date, culture, localizer) => localizer.format(date, 'ddd', culture),

    };

    const eventStyleGetter = useCallback((event, start, end, isSelected) => {

        return {
            className: `evento ${event.disponibilità === 0 ? 'indisponibile' : 'disponibile'}`
        };
        
    }, []);

    const eventRangeFormatter = useCallback((start, end) => {

        let startDate = moment(start);
        let endDate = moment(end);

        if (startDate.clone().startOf('day').isSame(startDate)) {
        
            startDate.hours(minTime.hour()).minutes(minTime.minute()).seconds(0);

        }
        
        if (endDate.clone().startOf('day').isSame(endDate)) {

            endDate.subtract(1, 'days').hours(maxTime.hour()).minutes(maxTime.minute()).seconds(0);

        }

        return { 
            startDate : startDate.toDate(), 
            endDate : endDate.toDate() 
        }

    }, []);

    const handleSelectSlot = useCallback(async ({ action, start, end }) => {

        if (action === 'click') return;

        if (action === 'doubleClick') {

            setDate(start);
            setView('work_week');
            setLocaleView(views['work_week']);

            return;

        }

        const { startDate, endDate } = eventRangeFormatter(start, end);

        const title = `${moment(startDate).format('HH:mm')} ${moment(endDate).format('HH:mm')}`

        const data = {
            start : startDate, 
            end : endDate, 
            title, 
            disponibilità : availability, 
            soggetto : subject, 
            medico : selected
        }

        let insertedId = v4();

        if (loadDatastore) {

            const result = await Ajax({
                route : 'planner', 
                metodo : 'put',
                parametri : { data : data },            
            });

            insertedId = result.insertedId;

        }

        setEvents(prevEvents => [
            ...prevEvents,
            { _id: insertedId, ...data },
        ]);

    }, [eventRangeFormatter, availability, subject, selected]);

    const handleEventResize = useCallback(async ({ event, start, end }) => {

        const { startDate, endDate } = eventRangeFormatter(start, end);

        const title = `${moment(startDate).format('HH:mm')} ${moment(endDate).format('HH:mm')}`

        const data = {
            start : startDate, 
            end : endDate, 
            title, 
        }

        if (loadDatastore) {

            await Ajax({
                route : `planner/${event._id}`, 
                metodo : 'post',
                parametri : { data : data },            
            });

        }

        const updatedEvents = events.map(item => {

            if (event._id === item._id) {
                return { ...item, title, start: startDate, end: endDate };
            }

            return item;

        });

        setEvents(updatedEvents);

    }, [events, eventRangeFormatter]);

    const handleDeleteEvent = useCallback(async (id) => {

        const data = events.find(item => item._id === id);

        if (loadDatastore) {

            await Ajax({
                route : `planner/${id}`, 
                parametri : { data : { data } },            
                metodo : 'delete',
            });
    
        }

        setEvents(events.filter(item => item._id !== id));

    }, [events]);

    const handleOnViewChange = useCallback((view) => {

        setView(view);
        setLocaleView(views[view]);

    }, []);

    const handleOnNavigate = useCallback(date => {

        setDate(date);

    }, [setDate]);

    const handleOnRangeChange = useCallback(range => {

        setRange(range);

    }, []);

    const formatDateHeader = (label, date, format) => {

        const part = moment(date).format(format).toUpperCase();
        const year = moment(date).format('YY');
    
        return (
    
            <>
                {
                    label &&
                        <span className='etichetta-header'>{label}</span>
                }
                <span>
                    {part}
                    <span className='etichetta-anno'>
                        {year}
                    </span>
                </span>
            </>
                         
        )
    
    }

    const getDatastoreData = async id => {

        const retrieveDoctors = async() => {

            return await Ajax({
                route : `medici`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        setDoctors(await retrieveDoctors());

    }

    const handleTimeslots = (e) => {

        const { value } = e.target;

        setTimeslots(value);

    }

    return {

        localizer,
        minTime,
        maxTime,

        view,
        localeView,
        date,
        range,
        events,
        doctors,

        timeslots,

        setEvents,

        DnDCalendar,
        EventComponent,

        formatDateHeader,

        customFormats,
        eventStyleGetter,
        handleSelectSlot,
        handleEventResize,
        handleDeleteEvent,
        handleOnViewChange,
        handleOnNavigate,
        handleOnRangeChange,
        handleTimeslots,

    }

}