import { useEffect, useRef, useState } from "react";

import Ajax from "../components/Ajax";

import { Envelope, Link45deg, Telephone } from "react-bootstrap-icons";

import PatientModal from "../components/PatientModal";
import CardList from "../components/CardList";
import { usePermissions } from "../hooks/usePermissions";
import PaginatedSearchableTable from "../components/PaginatedSearchableTable";
import { Badge } from "react-bootstrap";

export default function Patients(props) {

    const { isCalendarEditable, userFilter } = usePermissions();

    const didMount = useRef(false);

    const [loading, isLoading] = useState(true);

    const [updateList, setUpdateList] = useState(false);

    const [data, setData] = useState([]);
    const [patientData, setPatientData] = useState({});

    const [isOpen, toggleModal] = useState(false);

    useEffect(() => {

        if (didMount.current) return;

        const retrievePatients = async() => {

            return await Ajax({
                route : 'pazienti', 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrievePatients()
        .then(data => {

            isLoading(false);

            setData(data?.filter(
                item => userFilter.length > 0 && item.medico ? userFilter.includes(item.medico) : true
            ));

        });
    
    }, [updateList]);

    const getPatientData = id => e => {

        const retrievePatient = async(id) => {

            if (!id) {

                return Promise.resolve({});

            }

            return await Ajax({
                route : `pazienti/${id}`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrievePatient(id)
        .then(data => {

            setPatientData(data);
            toggleModal(true);

        })
        .catch(err => {

        });

    }

    const handleOnChange = e => {

        const { name, value } = e.target;

        setPatientData({
            ...patientData,
            [name] : value
        })

    }

    const formatContacts = (record) => {

        const contacts = [];

        if (record.telefono) {

            contacts.push(
                <div key={`t-${record._id}`}><Telephone /> <a onClick={ e => e.stopPropagation() } href={`tel:${record.telefono}`}>{record.telefono}</a></div>
            ) 

        }

        if (record.email) {

            contacts.push(
                <div key={`e-${record._id}`}><Envelope /> <a onClick={ e => e.stopPropagation() } href={`mailto:${record.email}`}>{record.email}</a></div>
            )

        }

        return contacts.length > 0 ? contacts : [<>--</>];

    }

    return (

        <CardList
            loading={loading}
            header='Pazienti'
            onClick={getPatientData()}
            setUpdateList={setUpdateList}
            readOnly={isCalendarEditable === false}
            detailModal={

                <PatientModal
                    data={patientData}
                    handleOnChange={handleOnChange}                   
                    isOpen={isOpen}
                    toggleModal={toggleModal}
                    list={data}
                    setUpdateList={setUpdateList}
                />

            }
        >

            <PaginatedSearchableTable
                className='tabella-pazienti'
                columns={[
                    {header : 'Nominativo', dataKey : 'assegnato', style : {width : 0}, colSpan : 2},
                    {header : '', dataKey : 'nominativo'},
                    {header : 'Codice Fiscale', dataKey : 'codiceFiscale'},
                    {header : 'Contatti', dataKey : 'contatti'}
                ]}
                data={data.reduce((acc, item) => {

                    const assigned = item.medico ? <Badge as='div' style={{ padding: 2, margin: '0 -.5rem', borderRadius: '50%' }} bg='success' text='light' title='Medico abbinato'><Link45deg size={14} /></Badge> : <></>

                    acc.push({
                        ...item,
                        assegnato : assigned,
                        nominativo : `${item.cognome}, ${item.nome}`,
                        codiceFiscale : `${item.codiceFiscale || '--'}`,
                        contatti : formatContacts(item),
                    });

                    return acc

                }, [])}
                onRowClick={getPatientData}
                updateList={updateList}
            />

        </CardList>                
         
    )

}