import './AppointmentSummaryModal.css';

import { useEffect, useState } from 'react';

import { Badge, Col, Form, Navbar, Row, Table } from 'react-bootstrap';
import InterfaceModal from './InterfaceModal';
import CardList from './CardList';
import moment from 'moment';
import useCalendar from '../hooks/useCalendar';
import AppointmentModal from './AppointmentModal';
import AlertBadge from './AlertBadge';
import RightModalFooterNav from './RightModalFooterNav';
import PageSpinner from './PageSpinner';

export default function AppointmentSummaryModal(props) {

    const { subject, isOpen, toggleModal, perspective = 'paziente', format = '', date, showPastAppointments = false, showExport = false, onExportClick = (e => {}) } = props;

    const [total, setTotal] = useState(0);

    const [paid, setPaid] = useState({});
    const [toBePaid, setToBePaid] = useState(0);
    const [totalDue, setTotalDue] = useState(0);

    const [filterToBePaid, seFilterToBePaid] = useState(false);

    const [loading, isLoading] = useState(true);

    const [updateList, setUpdateList] = useState(false);

    const [filteredData, setFilteredData] = useState([]);
    const [listData, setListData] = useState([]);

    const [filterShowPastAppointments, setFilterShowPastAppointments] = useState(showPastAppointments);

    const [isAppontmentOpen, toggleAppointmentModal] = useState(false);

    const detail = perspective === 'medico' ? 'paziente' : 'medico';

    const { 
        
        data, 
        
        appointmentData, 
        getAppointmentData, 
        handleOnChange ,

        createAppointmentTypeLabel

    } = useCalendar({updateList, toggleAppointmentModal, loadDatastore: true});

    useEffect(() => {

        seFilterToBePaid(false);
        setFilterShowPastAppointments(showPastAppointments);

    }, [isOpen]);

    useEffect(() => {

        setFilteredData( 
            data
                .filter(item => !item.record.categoria) 
                .filter(item => item.record[perspective]?._id === subject?._id )
                .filter(item => filterShowPastAppointments ? true : !moment(item.start).isBefore(moment()))
                .filter(item => format ? moment(item.start).format(`${format} YYYY`) === moment(date).format(`${format} YYYY`) : true)
                .sort((a, b) => {

                    const dateA = new Date(a.start);
                    const dateB = new Date(b.start);
              
                    return dateB - dateA;

                }) 
        );

    }, [data, subject, perspective, date, format, filterShowPastAppointments]);

    useEffect(() => {

        setTotal(filteredData.length);
        setPaid(filteredData.reduce((acc, item) => {

            const { stato, importo, modalità } = item.record;

            const isAmountPaid = Boolean(stato);
            const method = String(modalità).slice(0, 3);

            if (isAmountPaid) {

                if (!acc[method]) acc[method] = 0;

                acc[method] += Number(importo);

            }

            return acc;

        }, {}))

        setToBePaid(filteredData.filter(item => Boolean(item.record.stato) === false).length);
        setTotalDue(filteredData.reduce((acc, item) => acc + (Boolean(item.record.stato) === false ? Number(item.record.importo ?? 0) - Number(item.record.acconto ?? 0) : 0) , 0));

        setListData(
        
            filteredData
            .reduce((acc, item) => {

                const { _id, start: data, record : { stato, tipo, importo = 0, acconto, modalità, confermato } } = item;

                const name = `${item.record[detail].cognome}, ${item.record[detail].nome}`;

                const isAmountPaid = Boolean(stato);
                const method = String(modalità).slice(0, 3);

                const paid = isAmountPaid ? ` (${method})` : '';

                const advance = !isAmountPaid && acconto > 0 ? ` (– € ${(importo - acconto).toFixed(2)})` : '';

                const dateInThePast = moment(data).isBefore(moment());

                acc.push({
                    _id,
                    data,
                    importo,
                    confermato,
                    name,
                    isAmountPaid,
                    paid,
                    advance,
                    dateInThePast,
                    record: {
                        medico: {
                            _id : item.record.medico._id
                        },
                    }
                });

                return acc;

            }, [])
        
        );

    }, [filteredData])

    useEffect(() => {

        if (data.length === 0) return;

        isLoading(false);

    }, [listData]);

    return (

        <>

            <InterfaceModal
                show={isOpen}
                onHide={toggleModal}
                isAlertOpen={false}
                isBlurred={isAppontmentOpen}
                header={
                    
                    <>{`${subject.cognome}, ${subject.nome}`}</>

                }
                footer= {

                    <RightModalFooterNav>

                        <Form.Check
                            id='mostraVisitePassate'
                            name='mostraVisitePassate' 
                            type='switch'
                            label='Mostra visite passate'
                            checked={filterShowPastAppointments}
                            onChange={() => setFilterShowPastAppointments(!filterShowPastAppointments)}
                        />

                    </RightModalFooterNav>
    
                }

            >

            <CardList
                header={'Visite ' + perspective}
                readOnly={true}
                showBreadcrumb={false}
                setUpdateList={setUpdateList}
                showExport={showExport}
                handleExport={onExportClick(listData)}
            >
            {
                loading ? 

                    (

                        <PageSpinner />

                    ) : (

                        <div style={{ maxHeight: '65vh', overflow : 'auto' }}>

                            <Table striped borderless hover className='m-0 tabella-appuntamenti'>
                                <thead className='sticky-header'>
                                    <tr>
                                        <th>Data</th>
                                        <th>{detail.charAt(0).toUpperCase() + detail.slice(1)}</th>
                                        <th className='text-right'>Importo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {

                                        listData.length > 0 ?

                                        (
                                            listData
                                            .filter(item => filterToBePaid ? Boolean(item.record.stato) === false : true)
                                            .map(record => {

                                                const { _id, data, tipo, importo = 0, confermato, name, isAmountPaid, paid, advance, dateInThePast } = record;

                                                return (

                                                    <tr 
                                                        className={dateInThePast ? 'appuntamento-nel-passato' : ''}
                                                        key={_id} 
                                                        onClick={getAppointmentData(record._id)}
                                                    >
                                                        <td>
                                                            <div className='d-flex align-items-center'>
                                                                <AlertBadge 
                                                                    show={!isAmountPaid}
                                                                />
                                                                <span className={`me-1 ${!confermato ? 'da-confermare' :''}`}>
                                                                    {moment(data).format('DD/MM HH:mm')}
                                                                </span>
                                                                {createAppointmentTypeLabel(tipo)}
                                                            </div>                                            
                                                        </td>
                                                        <td>{name}</td>
                                                        <td className='text-right'>€ {importo || '--'}{paid}{advance}</td>
                                                    </tr>
                                                )

                                            })

                                        ) : (

                                            <tr><td colSpan={3} style={{fontStyle : 'italic'}}>Nessuna visita in archivio.</td></tr>

                                        )

                                    }
                                </tbody>
                            </Table>

                        </div>

                    )

            }
            </CardList>                

            <Navbar className='justify-content-between'>

                <Form inline>

                    <Row>
                        <Col className='pe-0 d-flex gap-1 align-items-center' xs='auto'>
                            <b>Totale</b> <Badge>{total}</Badge>
                        </Col>
                        <Col className='pe-0 d-flex gap-1 align-items-center' xs='auto'>
                            <Form.Check
                                className='m-0'
                                type='switch'
                                id='to-be-paid'
                                disabled={toBePaid === 0}
                                label={
                                    <>
                                        Da saldare 
                                    </>
                                }
                                onChange={e => seFilterToBePaid(!filterToBePaid)}
                            />        
                            <Badge 
                                bg={toBePaid === 0 ? 'success' : 'warning'} 
                                text={toBePaid === 0 ? 'light' : 'dark'}
                            >
                            {toBePaid}
                            {
                                toBePaid > 0 &&
                                    ` (€ ${Number(totalDue).toFixed(2)})`
                            }
                            </Badge>

                        </Col>
                    </Row>

                </Form>

                <Form className='nascondi-992' inline>

                    <Row>
                        <Col className='d-flex gap-1 align-items-center' xs='auto'>
                            <Badge bg='secondary'>BON</Badge>{`€ ${Number(paid.BON ?? 0).toFixed(2)} `}
                            <Badge bg='secondary'>CON</Badge>{`€ ${Number(paid.CON ?? 0).toFixed(2)} `}
                            <Badge bg='secondary'>POS</Badge>{`€ ${Number(paid.POS ?? 0).toFixed(2)}`}
                        </Col>
                    </Row>

                </Form>

            </Navbar>

            </InterfaceModal>

            <AppointmentModal
                data={appointmentData}
                handleOnChange={handleOnChange}                   
                isOpen={isAppontmentOpen}
                toggleModal={toggleAppointmentModal}
                list={data}
                setUpdateList={setUpdateList}
            />

        </>

    )

}