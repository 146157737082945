import { ButtonGroup, Card, Dropdown, DropdownButton } from "react-bootstrap";
import CalendarNavbar from "./CalendarNavbar";
import PathBreadcrumb from "./PathBreadcrumb";
import { List } from "react-bootstrap-icons";
import InfoClickForDetails from "./InfoClickForDetails";
import PageSpinner from "./PageSpinner";

export default function CardDate(props) {
    
    const { header, gotoNext, gotoPrev, gotoToday, onUpdateClick, onExportClick, showInfoClick = false, children, showBreadcrumb = true, showViewDropdown = false, view, handleOnViewChange, loading } = props;

    const views = {
        month : 'Mese',
        day : 'Giorno',
    }

    return (
        <>

            {
                showBreadcrumb &&
                    <PathBreadcrumb />
            }

            {
                
                showInfoClick &&
                    <InfoClickForDetails />

            }

            <CalendarNavbar
                header={header}
                gotoNext={gotoNext}
                gotoPrev={gotoPrev}
                gotoToday={gotoToday}
                dropdownMenu={

                    <>
                    {

                        showViewDropdown &&

                            <DropdownButton
                                size='sm'
                                className='vista-calendario me-1'
                                title={views[view]}
                                align='end'
                                onSelect={handleOnViewChange}
                            >
                                <Dropdown.Item eventKey='month'>Mese</Dropdown.Item>
                                <Dropdown.Item eventKey='day'>Giorno</Dropdown.Item>
                            </DropdownButton>

                    }
                        <Dropdown
                            size='sm'
                            align='end'
                            as={ButtonGroup}
                        >
                            <Dropdown.Toggle variant="light">
                                <List size={22} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item disabled={onExportClick === undefined} onClick={onExportClick}>Esporta</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={onUpdateClick}>Aggiorna</Dropdown.Item>
                            </Dropdown.Menu>                            
                        </Dropdown>
                    </>
            
                }
            />
            <Card
                className='mb-2 elenco'
                style={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                }}
            >
                <Card.Body className='p-0' sx={{}}>
                {
                    loading ? 

                        (

                            <PageSpinner 
                                style={{
                                    marginTop : '-1.5rem'
                                }}
                            />

                        ) : (

                            <>
                                { children }
                            </>
                            
                        )

                }
                </Card.Body>
            </Card>

        </>
    )
 
}