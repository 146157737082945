
import Log from '../layout/tools/Log';
import InterfaceModal from './InterfaceModal';

export default function UserLogModal(props) {

    const { isOpen, toggleModal, user } = props;

    return (

        <InterfaceModal
            show={isOpen}
            onHide={toggleModal}
            isAlertOpen={false}
            header={

                <>
                    Log Utente
                </>

            }
        >

            <Log 
                user={user}
            />

        </InterfaceModal>

    )

}