import { FloatingLabel, Form } from "react-bootstrap";
import FormInputError from "./FormInputError";

export default function FormSelect(props) {

    const { label, name, onChange, value, valid, children, style, disabled, readOnly } = props;

    return (
        <div className='mb-3 flex-grow-1'>
            <FloatingLabel 
                label={label}
            >
                <Form.Select 
                    style={style}
                    onChange={onChange}
                    value={value[name]}
                    name={name}
                    readOnly={readOnly}
                    disabled={disabled}
                >
                    { children }
                </Form.Select>
            </FloatingLabel>
            <FormInputError show={valid?.[name]?.length > 0} caption={valid?.[name]} />
        </div>

    )

}