import './FormFloatingSearchSelectInput.css';

import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Button, FloatingLabel, Form, ListGroup, Stack } from 'react-bootstrap';
import FormInputError from './FormInputError';
import { AutoSizer, List } from 'react-virtualized';
import { X } from 'react-bootstrap-icons';

const FormFloatingSearchSelectInput = forwardRef((props, ref) => {

    const { label, name, onChange, value, valid, disabled, data, homonyms, style, onAddNew, readOnly, className, upward, multiple } = props;

    const listRef = useRef();

    const [ text, setText ] = useState('');
    const [ selected, setSelected ] = useState([]);

    const [showList, setShowList] = useState(false);
    
    const [recordset, setRecordset] = useState([]);
    const [filteredRecordset, setFilteredRecordset] = useState([]);
    const wrapperRef = useRef(null);

    const [ filter, setFilter ] = useState('');
    
    const handleOnChange = (e) => {

        setFilter(e.target.value);

    }

    useEffect(() => {

        setRecordset(data)

    }, [data])

    useEffect(() => {

        if (!value[name]) {

            setText('');
            setSelected([]);

            return;

        };

        const item = value[name].split(';').map(id => {

            const doctor = data.find(item => item._id === id);

            return doctor ? `${doctor.cognome}, ${doctor.nome}` : undefined;

        });

        if (!item) {

            setText('');
            setSelected([]);

            return;
            
        }

        setSelected(value[name].split(';'));

        setText(item.join('; '));

    }, [value, data]);

    useEffect(() => {

        setFilter('');
        
        if (!showList) return;

        const id = value[name];

        if (id) {

            setTimeout(() => {

                const index = (

                    id.split(';').map(id => data.findIndex(item => item._id === id))

                )
                .sort((a, b) => a - b)
                .at(0);

                scrollToIndex(index)

            }, 10)
    
        }
    
    }, [showList])

    useEffect(() => {

        if (showList) {

            const inputParts = filter
                .toLowerCase()
                .replace(',', '')
                .split(' ')
                .filter(Boolean);

            const filtered = recordset.filter((utente) =>

                inputParts.every((part) =>
                    utente.nome.toLowerCase().includes(part) ||
                        utente.cognome.toLowerCase().includes(part)
                )

            );

            setFilteredRecordset(filtered);

        } else {

            setFilteredRecordset([]);

        }

    }, [filter, recordset, showList]);
  
    useEffect(() => {

        function handleClickOutside(event) {

            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowList(false);
            }

        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };

    }, [wrapperRef]);
  
    const handleFocus = () => {

        if (readOnly) return;

        setShowList(true);

    };
  
    const handleSelectItem = (item) => {

        let value = [];

        if (multiple) {

            value = selected.includes(item._id) 
                ? selected.filter(id => id !== item._id) 
                : [...selected, item._id];

        } else {

            value = [item._id];

        }
    
        setSelected(value);
        onChange({ target: { name, value: value.join(';') } });
        setShowList(false);

    };
  
    const highlightMatch = (text, filter) => {

        if (!filter) return text;

        if (typeof text !== 'string') {
            return text;
        }

        const parts = text.split(new RegExp(`(${filter})`, 'gi'));

        return parts.map((part, index) => 

            part.toLowerCase() === filter.toLowerCase() ? 
                <span key={index} style={{ textDecoration: 'underline' }}>{part}</span> : 
                part

        );

    }
  
    const extractBirthDateFromFiscalCode = (fiscalCode) => {

        if (!fiscalCode || fiscalCode.length !== 16) {
            return '?';
        }
      
        const year = fiscalCode.substring(6, 8);
        const month = fiscalCode.charAt(8);
        const day = parseInt(fiscalCode.substring(9, 11), 10);
      
        const months = "ABCDEHLMPRST".split('');
        const monthIndex = months.indexOf(month.toUpperCase());

        if (monthIndex === -1) {
            return '?'
        }

        const birthMonth = monthIndex + 1;
      
        const birthDay = day > 40 ? day - 40 : day;
      
        const formattedDay = birthDay.toString().padStart(2, '0');
        const formattedMonth = birthMonth.toString().padStart(2, '0');
        
        return `${formattedDay}/${formattedMonth}/${year}`;

    }

    const rowRenderer = ({ key, index, style }) => {

        const item = filteredRecordset[index];
        const distinctive = homonyms.includes(item._id) ? ` (${extractBirthDateFromFiscalCode(item.codiceFiscale)})` : '';
        const fullName = `${item.cognome}, ${item.nome}${distinctive}`;

        return (
            <ListGroup.Item
                action
                key={key}
                style={style}
                onClick={() => handleSelectItem(item)}
                active={selected.includes(item._id) ? 'primary' : ''}
            >
                {highlightMatch(fullName, filter)}
            </ListGroup.Item>
        );

    };
  
    const scrollToIndex = (index) => {

        if (!listRef.current) return;

        listRef.current.scrollToRow(index);

    };

    const handleOnDelete = (e) => {
        
        onChange({ target : { name, value : '' }});
        setShowList(false);

    }

    const handleOnAddClick = (e) => {

        onChange({ target : { name, value : '' }});
        onAddNew();

    }

    const height = filteredRecordset.length > 8 ? 8 * 32 : filteredRecordset.length * 32 

    const message = multiple ? 'uno o più ' : '';
    const scope = multiple ? `${label.toLowerCase().slice(0, -1)}${{'o' : 'i', 'e' : 'i'}[label.at(-1)]}` : label.toLowerCase();

    const shownLabel = showList ? `Seleziona ${message}${scope} dall'elenco` : label

    return (

        <div 
            className={`w-100 ${className}`}
            ref={wrapperRef}
        >

            <FloatingLabel
                className={showList ? 'focused-label' : ''}
                label={shownLabel}
            >

                <Form.Control
                    type='text'
                    placeholder={shownLabel}
                    name={name}
                    defaultValue={text}
                    onFocus={handleFocus}
                    disabled={disabled}
                    readOnly
                    style={{
                        cursor: readOnly ? 'default' : 'pointer',
                        ...style
                    }}
                />
                {

                    value[name] && !readOnly &&
                        <X
                            title='Cancella valore'
                            style={{
                                position: 'absolute',
                                top: '50%',
                                transform: 'translateY(-50%)',
                                right: '.75rem',
                                cursor: 'pointer',
                                fontSize: '1.2rem',
                            }}
                            onClick={handleOnDelete}
                        />

                }
            </FloatingLabel>
            <FormInputError className='mt-_25' show={valid?.[name]?.length > 0} caption={valid?.[name]} />
            {

                showList && (

                    <ListGroup
                        className='virtualized-list position-absolute z-3 bg-white mt-2'
                        style={{
                            width: 'calc(100% - 2rem)',
                            border: '1px solid rgba(0,0,0,0.5)',
                            borderRadius : 0,
                            transform: `translateY(${ upward ? (height + 130) * -1 : 0}px)`,
                        }}
                    >

                        <Stack
                            className='m-2'
                            direction='horizontal'
                            gap={2}
                            style={{
                                width: 'calc(100% - 1rem)'
                            }}
                        >
                            <Form.Control 
                                type='text' 
                                placeholder='Cerca...' 
                                value={filter}
                                onChange={handleOnChange}
                            />
                            {
                                onAddNew &&
                                    <Button 
                                        variant='light'
                                        style={{
                                            borderColor: '#dee2e6',
                                            wordBreak: 'normal'
                                        }}
                                        onClick={handleOnAddClick}
                                    >
                                        Aggiungi
                                    </Button>    
                            }
                        </Stack>

                        <AutoSizer disableHeight>
                            {({ width }) => (
                            <List
                                ref={listRef}
                                width={width - 2}
                                height={height}
                                rowCount={filteredRecordset.length}
                                rowHeight={32}
                                rowRenderer={rowRenderer}
                                overscanRowCount={10}
                            />
                            )}
                        </AutoSizer>

                    </ListGroup>

                )
                
            }
        </div>
    );

});

export default FormFloatingSearchSelectInput;

