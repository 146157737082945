import './PageFooter.css';

import { Nav, Navbar } from 'react-bootstrap';
import { BoxArrowUpRight, Dot } from 'react-bootstrap-icons';

export default function PageFooter(props) {

    const { hidePrivacyPolicy } = props;

    const style = {
        transform: 'scaleY(-1)'
    }

    return (

        <Navbar 
            className='page-footer' 
            fixed='bottom'
            style={{
                bottom: '3.2rem',
                left: '.5rem',
                right: 'auto',
                transform: 'rotate(180deg)',
                textOrientation: 'mixed',
                writingMode: 'vertical-lr'
            }}            

        >

            <Nav 
            >


                <Nav.Item>
                    <Nav.Link 
                        href='https://iwe-italia.com'
                        target='_blank'
                        className='p-0 d-flex'
                    >
                        <b>iwe-italia.com</b>
                        <BoxArrowUpRight style={style} className='mt-2 align-self-center' size={12} />
                    </Nav.Link>
                </Nav.Item>

                {

                    !hidePrivacyPolicy &&

                        <>

                            <Dot size={24} className='my-0' />


                            <Nav.Item>
                                <Nav.Link 
                                    href='privacy_policy.html'
                                    target='_blank'
                                    className='p-0 d-flex'
                                >
                                    Privacy policy
                                    <BoxArrowUpRight style={style} className='mt-2 align-self-center' size={12} />
                                </Nav.Link>
                            </Nav.Item>

                        </>
                        
                }

            </Nav>

        </Navbar>
        
    )

}