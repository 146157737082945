import { Alert, Button, Card, Col, Collapse, Form, FormGroup, Row } from 'react-bootstrap';
import PathBreadcrumb from '../../components/PathBreadcrumb';
import FormSelect from '../../components/FormSelect';
import FormFloatingInput from '../../components/FormFloatingInput';
import FormFloatingTextarea from '../../components/FormFloatingTextarea';
import { useState } from 'react';
import { ExclamationCircle } from 'react-bootstrap-icons';
import FeedbackModal from '../../components/FeedbackModal';
import { usePermissions } from '../../hooks/usePermissions';

export default function Feedback(props) {

    const { isManagementEnabled, isToolsEnabled } = usePermissions();

    const [collapse, setCollapse] = useState(false);
    const [isOpen, toggleModal] = useState(false);

    const [ data, setData ] = useState({
        tipo: 'Report su bug',
    })

    const optionTypeValues = [
        { value: 'Report su bug', label: 'Report su bug' },
        { value: 'Richiesta di funzionalità', label: 'Richiesta di funzionalità' },
        { value: 'Problema di prestazioni', label: 'Problema di prestazioni' },
    ];

    const optionModuleValues = [
        { value: 'Home', label: 'Home' },
        { value: 'Calendario', label: 'Calendario' },
        { value: 'Pazienti', label: 'Pazienti' },
        { value: 'Gestione/Prospetto medici', label: 'Gestione/Prospetto medici' },
        { value: 'Gestione/Resoconto cassa', label: 'Gestione/Resoconto cassa' },
        { value: 'Strumenti/Utenti', label: 'Strumenti/Utenti' },
        { value: 'Strumenti/Input/Output', label: 'Strumenti/Input/Output' },
        { value: 'Strumenti/Log di sistema', label: 'Strumenti/Log di sistema' },
        { value: 'Strumenti/Backup', label: 'Strumenti/Backup' },
        { value: 'Aiuto/Manuale uso', label: 'Aiuto/Manuale uso' },
        { value: 'Aiuto/Feedback', label: 'Aiuto/Feedback' },
        { value: 'Aiuto/Informazioni su', label: 'Aiuto/Informazioni su' },
    ].filter(item => {

        if (!isManagementEnabled && 
                item.value.startsWith('Gestione')) {

            return false;

        }

        if (!isToolsEnabled && 
                item.value.startsWith('Strumenti')) {

            return false;
            
        }

        return true;

    });

    const handleOnChange = e => {

        const { name, value } = e.target;

        setData({
            ...data,
            [name] : value
        })

    }

    const handleSelectOnChange = option => e => {

        const { value } = option;

        handleOnChange({ target : { name : 'uo', value }})

    }
    
    const getSearchParams = () => {

        const params = new URLSearchParams(window.location.search);

        const output = [];

        for (const [key, value] of params) {

            output.push(`${key}: ${value}`);

        }

        return JSON.stringify(output);

    }

    const getSystemInfo = () => {

        const performance = window.performance || {};

        return {

            parametri : getSearchParams(),
            browser : navigator.userAgent,
            locale : navigator.language,
            memoria : `${performance.memory.usedJSHeapSize} (${((performance.memory.usedJSHeapSize / performance.memory.totalJSHeapSize) * 100).toFixed(2)}%)`,
            risoluzioneSchermo : `${window.screen.width}x${window.screen.height}`,
            risoluzioneFinestra : `${window.innerWidth}x${window.innerHeight}`,
            timing : JSON.stringify(performance.timing),
            navigazione : JSON.stringify(performance.navigation),
            risorse : JSON.stringify(performance.getEntries()),
            cookie : document.cookie,
            localStorage : JSON.stringify(localStorage),

        }

    }

    const systemInfo = getSystemInfo();

    const handleClickCollapse = e => {

        e.preventDefault();

        setCollapse(!collapse);

    }

    const handleOnClick = e => {

        toggleModal(true);

    }

    return (

        <>
            <PathBreadcrumb />

            <Alert className='d-flex align-items-center informazioni-dettaglio' variant='warning'>
                <ExclamationCircle size={20} className='me-2' style={{ minWidth : 20 }} /> <div>Prima di segnalare un problema qui, <a href='#' onClick={handleOnClick}>rivedi le indicazioni fornite</a>.</div>
            </Alert>

            <Card>
                <Card.Header className='py-1 font-normal'>Feedback</Card.Header>
                <Card.Body>

                    <FormSelect
                        label='Questo è un'
                        value={optionTypeValues.find(item => item.value === data.tipo) || ''}
                        onChange={handleSelectOnChange('tipo')}
                        //invalid={valid.modulo?.length > 0}
                        //infoText={valid.modulo}
                    >
                        <option value=''>Seleziona voce</option>
                        {
                            optionTypeValues.map(item => <option key={item.value} value={item.value}>{item.label}</option>)
                        }
                    </FormSelect>

                    <FormSelect
                        label='Ambito segnalazione'
                        value={optionModuleValues.find(item => item.value === data.modulo) || ''}
                        onChange={handleSelectOnChange('modulo')}
                        //invalid={valid.modulo?.length > 0}
                        //infoText={valid.modulo}
                    >
                        <option value=''>Seleziona voce</option>
                        {
                            optionModuleValues.map(item => <option key={item.value} value={item.value}>{item.label}</option>)
                        }
                    </FormSelect>
                    
                    <FormFloatingInput 
                        label='Titolo' 
                        name='titolo'
                        value={data} 
                        onChange={handleOnChange}
                    />

                    <FormFloatingTextarea 
                        label='Passaggi da riprodurre'
                        name='passaggi'
                        value={data} 
                        onChange={handleOnChange}
                        height={132}
                    />                            
                    <div style={{ marginTop : '-1rem' }} className='mb-3'>
                        <small>Indicare i passaggi necessari per riprodurre il problema in modo affidabile. Includere i risultati effettivi e quelli previsti.</small>
                    </div>

                    <Form.Group controlId='formFile' className='mb-3'>
                        <Form.Label>Allega</Form.Label>
                        <Form.Control type='file' multiple />
                    </Form.Group>

                    <Form.Check
                        id='server-error-log'
                        type='switch'
                        label={<>Allega il file log di errori di sistema</>} 
                    />

                    <Form.Check
                        id='informazioni-sistema'
                        type='switch'
                        label={<>Includi informazioni sul sistema (<a href='#' onClick={handleClickCollapse}>mostra</a>)</>} 
                    />

                    <Collapse in={collapse}>
                        <Card
                            className='no-after'
                        >
                            <Card.Body
                                className='pb-0'
                            >

                                <Row><Col xs='12' md='12'><b>Parametri</b></Col><Col>{systemInfo.parametri || '--'}</Col></Row>
                                <Row><Col xs='12' md='12'><b>Browser</b></Col><Col>{systemInfo.browser}</Col></Row>
                                <Row><Col xs='12' md='12'><b>Localizzazione</b></Col><Col>{systemInfo.locale}</Col></Row>
                                <Row><Col xs='12' md='12'><b>Memoria disponibile (b)</b></Col><Col>{systemInfo.memoria}</Col></Row>
                                <Row><Col xs='12' md='12'><b>Risoluzione schermo (px)</b></Col><Col>{systemInfo.risoluzioneSchermo}</Col></Row>
                                <Row><Col xs='12' md='12'><b>Risoluzione finestra (px)</b></Col><Col>{systemInfo.risoluzioneFinestra}</Col></Row>
                                <Row><Col xs='12' md='12'><b>Timing</b></Col><Col>{systemInfo.timing}</Col></Row>
                                <Row><Col xs='12' md='12'><b>Navigazione</b></Col><Col>{systemInfo.navigazione}</Col></Row>
                                <Row><Col xs='12' md='12'><b>Risorse</b></Col><Col>{systemInfo.risorse}</Col></Row>
                                <Row><Col xs='12' md='12'><b>Cookie</b></Col><Col>{systemInfo.cookie}</Col></Row>
                                <Row><Col xs='12' md='12'><b>Local storage</b></Col><Col>{systemInfo.localStorage}</Col></Row>

                            </Card.Body>
                        </Card>
                    </Collapse>

                    <Button color='primary' disabled className='mt-3'>
                        Invia segnalazione
                    </Button>
                    </Card.Body>
            </Card>

            <FeedbackModal
                isOpen={isOpen}
                toggleModal={toggleModal}
            />

        </>

    )

}