
let appNamespace = '';
let appEnvironment = ''

export const setAppNamespace = (namespace) => {
    appNamespace = namespace;
};

export const setAppEnvironment = (environment) => {
    appEnvironment = environment;
};

const appInfoState = (localStorage.getItem(`${appNamespace}.avvisi`) ?? 'true') === 'true'

export { appNamespace as getAppNamespace, appEnvironment as getAppEnvironment, appInfoState };