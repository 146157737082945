import React, { useEffect, useState } from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

export default function NetworkSpeedToastNotification() {

    const [effectiveType, setEffectiveType] = useState(null);

    const [show, setShow] = useState(false);

    useEffect(() => {

        const updateNetworkInfo = () => {

            if (navigator.connection) {

                setEffectiveType(navigator.connection.effectiveType);
                setShow(effectiveType === '2g' || effectiveType === 'slow-2g');

            }

        };

        updateNetworkInfo();

        if (navigator.connection) {
            navigator.connection.addEventListener('change', updateNetworkInfo);
        }

        return () => {

            if (navigator.connection) {
                navigator.connection.removeEventListener('change', updateNetworkInfo);
            }

        };

    }, []);

    return (

        <ToastContainer 
            position='top-end'
        >            
        {
            show && (

                <Toast
                    className='m-1'
                    onClose={() =>setShow(false)}
                    bg='danger'
                >
                    <Toast.Header>
                        <strong className='me-auto'>Connessione internet</strong>
                    </Toast.Header>
                    <Toast.Body>La tua connessione è <b>lenta</b> [{effectiveType}]. Potresti riscontrare problemi di prestazioni.</Toast.Body>
                </Toast>

            )
        }
        </ToastContainer>

    );

};
