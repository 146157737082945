import { useRecoilValue } from "recoil";
import { userState } from "../atoms";

export function usePermissions() {
    
    const token = useRecoilValue(userState);
  
    const isCalendarEditable = ["AMM", "SEG"].includes(token.gruppo) || (["MED"].includes(token.gruppo) && token.aggiornaCalendario === true);
    const isCalendarFilterable = ["AMM", "SEG"].includes(token.gruppo);

    const isPatientFormAccessible = (["MED"].includes(token.gruppo) && token.accedeSchede === true);

    const userFilter = isCalendarFilterable ? [] : [token._id];

    const isManagementEnabled = ["AMM", "SEG"].includes(token.gruppo);
    const isToolsEnabled = ["AMM"].includes(token.gruppo);
  
    return { 
        isManagementEnabled, 
        isToolsEnabled,
        isPatientFormAccessible,
        isCalendarEditable,
        isCalendarFilterable,
        userFilter
    };

}
