import { atom } from 'recoil';

export const userState = atom({
    key: 'userState',
    default: {},
});

export const wsState = atom({
    key: 'wsState',
    default: false,
});

export const wsMessagesState = atom({
    key: 'wsMessagesState',
    default: [],
});

export const deleteItemState = atom({
    key: 'deleteItemState',
    default: false,
});

export const openedModalsState = atom({
    key: 'openedModalsState',
    default: [],
});

export const infoWarningState = atom({
    key: 'infoWarningState',
    default: true,
});

export const autoUpdateState = atom({
    key: 'autoUpdateState',
    default: false,
});
