
import { useEffect, useState } from "react";
import moment from "moment";
import CardDate from "../../components/CardDate";
import PageSpinner from "../../components/PageSpinner";
import { Table } from "react-bootstrap";
import useCalendar from '../../hooks/useCalendar';
import AlertBadge from '../../components/AlertBadge';
import AppointmentSummaryModal from "../../components/AppointmentSummaryModal";
import Ajax from "../../components/Ajax";
import { getAppNamespace } from "../../hooks/useApp";

import LZString from 'lz-string';

export default function Report(props) {

    const [date, setDate] = useState(new Date());

    const [loading, isLoading] = useState(true);
    
    const [updateList, setUpdateList] = useState(false);
    
    const [filteredData, setFilteredData] = useState([]);
    const [listData, setListData] = useState([]);

    const [filter, setFilter] = useState();
    
    const [isOpen, toggleModal] = useState(false);

    const [view, setView] = useState('month');
    const [format, setFormat] = useState('MMM ');
        
    const { 
        
        data, 
        formatDateHeader, 
        
    } = useCalendar({updateList, toggleModal, loadDatastore: true});
    
    const header = () => {

        return formatDateHeader('Prospetto', date, format);

    }

    const gotoNext = () => {

        setDate(moment(date).subtract('1', view));

    }

    const gotoToday = () => {

        setDate(new Date());

    }

    const gotoPrev = () => {

        setDate(moment(date).add('1', view));

    }

    const handleOnViewChange = view => {

        setView(view);

        setFormat(`${view === 'day' ? 'DD' : ''} MMM `);

    };

    const handleOnClick = id => e => {

        setFilter(id);

        toggleModal(true);

    }

    useEffect(() => {

        setFilteredData( 

            data
            .filter(item => !item.record.categoria) 
            .filter(item => moment(item.start).format(`${format} YYYY`) === moment(date).format(`${format} YYYY`)) 
            .sort((a, b) => {
          
                return a.record.medico.cognome.localeCompare(b.record.medico.cognome);

            })

        );

        setFilter(undefined);
        
    }, [data, date, format]);

    useEffect(() => {

        setListData(

            filteredData
            .reduce((acc, item) => {

                const { record : { stato, importo, medico } } = item;

                const isAmountPaid = Boolean(stato);

                const name = `${medico.cognome}, ${medico.nome}`;

                if (!acc[name]) acc[name]= {};

                if (!acc[name].incassi) acc[name].incassi = 0;

                acc[name]._id = medico._id;
                acc[name].incassi += Number(importo || 0);

                if (!isAmountPaid) acc[name].daSaldare = 1;

                return acc;

            }, {})

        )

    }, [filteredData]);

    useEffect(() => {

        if (Object.keys(listData).length === 0) return;

        isLoading(false);

    }, [listData]);

    let totalRevenue = 0;

    let totalServices = 0;
    let totalIva = 0;

    let totalDue = 0;

    const handleExport = () => {

        const compressedData = LZString.compressToEncodedURIComponent(JSON.stringify(listData));

        Ajax({
            route : 'stampe',
            parametri : { params : { tipo : 'prospetto_medici', data : moment(date).format('MMM-YYYY'), dati : compressedData }, responseType: 'blob' },
            blob: true,
            metodo : 'get'
        })
        .then(data => {

            const link = document.createElement('a');
            const url = URL.createObjectURL(data);

            const file = `${getAppNamespace}-prospetto_medici-${moment().format('MMM YYYY').toUpperCase()}-${moment().format('YYYYMMDD.HHmm')}.xlsx`;

            link.href = url;
            link.download = file;
            link.click();

            link.remove();
            URL.revokeObjectURL(url);

        });

    }

    const handleExportDetail = (data) => (e) => {

        const { cognome, nome } = filteredData.find(item => item.record.medico._id === filter).record.medico;
        const doctor = `${cognome}, ${nome}`;

        const listData = data.reduce((acc, item) => {

            const { name, importo, data, isAmountPaid, paid } = item;

            acc.push({
                name,
                importo,
                data : moment(data).format('DD/MM HH:mm'),
                isAmountPaid : isAmountPaid ? 1 : 0,
                paid : paid.replace(/[( )]/g, '')
            })

            return acc;

        }, []);

        const compressedData = LZString.compressToEncodedURIComponent(JSON.stringify(listData));

        Ajax({
            route : 'stampe',
            parametri : { params : { tipo : 'prospetto_medici_dettaglio', data : moment(date).format('MMM-YYYY'), dati : { medico : doctor, lista : compressedData }}, responseType: 'blob' },
            blob: true,
            metodo : 'get'
        })
        .then(data => {

            const link = document.createElement('a');
            const url = URL.createObjectURL(data);

            const file = `${getAppNamespace}-dettaglio_prospetto_medici-${doctor.toUpperCase()}-${moment().format('MMM YYYY').toUpperCase()}-${moment().format('YYYYMMDD.HHmm')}.xlsx`;

            link.href = url;
            link.download = file;
            link.click();

            link.remove();
            URL.revokeObjectURL(url);

        });

    }

    return (

        <>

            <CardDate
                header={header()}
                gotoNext={gotoNext}
                gotoPrev={gotoPrev}
                gotoToday={gotoToday}
                onUpdateClick={e => setUpdateList(new Date())}
                showInfoClick
                showViewDropdown
                view={view}
                onExportClick={e => handleExport()}
                handleOnViewChange={handleOnViewChange}
                loading={loading}
            >

            {
                !loading && 

                    <Table className='m-0 tabella-prospetti table table-striped table-borderless table-hover'>
                        <thead>
                            <tr>
                                <th>Medico</th>
                                <th className='text-right'>Incassi</th>
                                <th className='text-right'>Servizi (30%)</th>
                                <th className='text-right'>IVA (22%)</th>
                                <th className='text-right'>Dovuto</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                Object.entries(

                                    listData

                                )
                                .sort((a, b) => 
                                    
                                    a.toString().localeCompare(b.toString())

                                )
                                .map(([key, item]) => {

                                    const revenue = item.incassi;

                                    const services = revenue * 0.30;
                                    const iva = services * 0.22;

                                    const due = services + iva;

                                    totalRevenue += revenue;
                                    totalServices += services;
                                    totalIva +=  iva;
                                    totalDue += due;

                                    return (

                                        <tr
                                            key={item._id}
                                            onClick={handleOnClick(item._id)}
                                        >
                                            <td>
                                                <div className='d-flex align-items-center'>
                                                    <AlertBadge
                                                        show={item.daSaldare}
                                                    />
                                                    {key}
                                                </div>
                                            </td>
                                            <td className='text-right'>€ {Number(revenue).toFixed(2)}</td>
                                            <td className='text-right'>€ {Number(services).toFixed(2)}</td>
                                            <td className='text-right'>€ {Number(iva).toFixed(2)}</td>
                                            <td className='text-right font-bold'>€ {Number(due).toFixed(2)}</td>
                                        </tr>

                                    )

                                })
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Totale generale</th>
                                <th className='text-right no-wrap'>
                                    € {Number(totalRevenue).toFixed(2)}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {Number(totalServices).toFixed(2)}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {Number(totalIva).toFixed(2)}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {Number(totalDue).toFixed(2)}
                                </th>
                            </tr>
                        </tfoot>
                    </Table>
                        
            }

            </CardDate>
            {

                filter &&

                     <AppointmentSummaryModal
                        subject={filteredData.find(item => item.record.medico._id === filter).record.medico}
                        isOpen={isOpen}
                        toggleModal={toggleModal}
                        perspective='medico'
                        format={format}
                        date={date}
                        showPastAppointments
                        showExport
                        onExportClick={handleExportDetail}
                    />

            }

        </>

    )
    
}