import moment from "moment";
import { useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import useCalendar from "../hooks/useCalendar";

export default function SelectAppointmentDuration(props) {

    const { data, subject, list, officeHours, planner, name, onChange, disabled, readOnly } = props;

    const [show, setShow] = useState(false);

    const [selectedDate, setSelectedDate] = useState();

    const [appointments, setAppointments] = useState([]);

    const { minTime, maxTime } = useCalendar({});

    const calculateUnavailableIntervals = (date, availableSlots) => {

        const day = moment(date).startOf('day');

        const start = moment(minTime).format('HH:mm');
        const end = moment(maxTime).format('HH:mm');

        const timeToMinutes = time => {
            const [hours, minutes] = time.split(':').map(Number);
            return hours * 60 + minutes;
        };
      
        const workStart = timeToMinutes(start);
        const workEnd = timeToMinutes(end);
      
        const sortedSlots = availableSlots.sort((a, b) => timeToMinutes(a.inizio) - timeToMinutes(b.inizio));
      
        let unavailableIntervals = [];
        let lastEnd = workStart;
      
        sortedSlots.forEach(slot => {
            const slotStart = timeToMinutes(slot.inizio);
            const slotEnd = timeToMinutes(slot.fine);
            if (slotStart > lastEnd) {
                unavailableIntervals.push({ start: lastEnd, end: slotStart });
            }
            lastEnd = Math.max(lastEnd, slotEnd);
        });
      
        if (lastEnd < workEnd) {
            unavailableIntervals.push({ start: lastEnd, end: workEnd });
        }
      
        return unavailableIntervals.map(interval => ({
            start: moment(day).add(interval.start, 'minutes'),
            end: moment(day).add(interval.end, 'minutes')            
        }));

    }    

    useEffect(() => {

        if (!data[subject]) return;

        const selected = moment(data[subject]);

        setSelectedDate(selected);

        const availableSlots = [
            ...(officeHours[selected.format('ddd')] || []),
            ...planner.filter(item => (
                selected.isBetween(moment(item.start), moment(item.end), undefined, '[]') && item.disponibilità === 1)
            ).map(item => (
                { 
                    inizio : moment(item.start).format('HH:mm'),
                    fine : moment(item.end).format('HH:mm')
                }
            ))
        ];

        setAppointments(
            [
                ...list.map(appointment => (
                    {
                        start: moment(appointment.start),
                        end: moment(appointment.end)
                    }
                )).filter(appointment => 
                    appointment.start?.format('YYYY-MM-DD') === selected?.format('YYYY-MM-DD')
                ),
                ...calculateUnavailableIntervals(selected, availableSlots)
            ]                
        );

    }, [show, data[subject], list])

    const handleOnSelect = (value) => {

        onChange({ target : { name , value : Number(value) }});

    }

    const handleOnToggle = (show) => {
        
        setShow(show);

    }

    return (

        <DropdownButton 
            variant='light'
            title={`${data[name] || '?'} min`}
            className='action-dropdown no-radius'
            disabled={disabled}
            onSelect={handleOnSelect}
            onToggle={handleOnToggle}
            show={show}
            align='end'
            style={{
                pointerEvents: readOnly ? 'none' : 'auto'
            }}
        >
            <Dropdown.Header>Durata slot:</Dropdown.Header>
            { 
                [15, 30, 45, 60].map(item => {
                    
                    const slotStart = moment(selectedDate);
                    const slotEnd = moment(selectedDate).add(item, 'minutes');

                    const isDisabled = appointments.filter(appointment => 
                        !slotStart.isSame(appointment.start)
                    ).some(appointment => 
                        slotStart.isBefore(appointment.end) && slotEnd.isAfter(appointment.start)
                    );

                    return (

                        <Dropdown.Item 
                            eventKey={item} 
                            key={item}
                            active={data[name] === item}
                            disabled={isDisabled}
                        >
                            {item} min
                        </Dropdown.Item>

                    )
                })
            }
        </DropdownButton>        

    )

}