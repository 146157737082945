
import { useEffect, useRef, useState } from "react";
import Ajax from "../../components/Ajax";
import moment from "moment";
import CardDate from "../../components/CardDate";
import useCalendar from "../../hooks/useCalendar";
import PageSpinner from "../../components/PageSpinner";
import { Table } from "react-bootstrap";
import AlertBadge from "../../components/AlertBadge";
import SummaryDetailModal from "../../components/SummaryDetailModal";

export default function Summary(props) {

    const didMount = useRef(false);

    const [date, setDate] = useState(new Date());

    const [loading, isLoading] = useState(true);
    
    const [updateList, setUpdateList] = useState(false);
    
    const [data, setData] = useState([]);
    const [doctors, setDoctors] = useState([]);

    const [filter, setFilter] = useState('');
    const [toggleModal, setToggleModal] = useState(false);
    
    const { formatDateHeader } = useCalendar({updateList});

    useEffect(() => {
    
        if (didMount.current) return;
    
        const retrieveSummaries = async() => {
    
            isLoading(true);

            return await Ajax({
                route : `resoconti/${moment(date).format('YYYY/MM/DD')}`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }
    
        retrieveSummaries()
        .then(data => {
    
            isLoading(false);
    
            setData(data.filter(item => item.paziente !== undefined));

            setDoctors(data.reduce((acc, item) => {

                if (acc.filter(i => i._id === item._id).length === 0) {

                    const { _id, cognome, nome } = item.medico;

                    acc.push({
                        _id,
                        cognome,
                        nome,
                    });

                }

                return acc;

            }, []));
    
        });
    
    }, [updateList, date]);
    
    const header = () => {

        return formatDateHeader('Resoconto', date, 'DD/MM/');

    }

    const gotoNext = () => {

        setDate(moment(date).subtract('1', 'day'));

    }

    const gotoToday = () => {

        setDate(new Date());

    }

    const gotoPrev = () => {

        setDate(moment(date).add('1', 'day'));

    }

    const handleOnClick = id => e => {

        setFilter(id);

        setToggleModal(true);

    }

    let totalVisits = 0;

    let totalDeposit = 0;
    let totalCash = 0;
    let totalPOS = 0;
    let totalTransfer = 0;

    return (

        <CardDate
            header={header()}
            gotoNext={gotoNext}
            gotoPrev={gotoPrev}
            gotoToday={gotoToday}
            onUpdateClick={e => setUpdateList(new Date())}
            loading={loading}
        >

            {
                !loading && 

                    <Table className='m-0 tabella-prospetti table table-striped table-borderless table-hover'>
                        <thead>
                            <tr>
                                <th>Medico</th>
                                <th className='text-right'>Visite</th>
                                <th className='text-right'>Acconto</th>
                                <th className='text-right'>Contanti</th>
                                <th className='text-right'>POS</th>
                                <th className='text-right'>Bonifico</th>
                                <th className='text-right'>Totale</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            Object.entries(

                                data
                                .sort((a, b) => {

                                    const cognomeCompare = a.medico.cognome.localeCompare(b.medico.cognome);

                                    if (cognomeCompare !== 0) {
                                        
                                        return cognomeCompare;

                                    }

                                    return a.medico.nome.localeCompare(b.medico.nome);

                                })
                                .reduce((acc, item) => {

                                    const { importoAcconto = 0, importoDovuto = 0, importoSaldato, modalitàPagamento, bollo = false, medico } = item;

                                    const name = `${medico.cognome}, ${medico.nome}`;
                                    
                                    const debit = Number(importoAcconto);
                                    
                                    const duty = bollo ? 2 : 0;
                                    const due = Number(importoDovuto) + duty;

                                    if (!acc[name]) acc[name]= { _id : medico._id, visite: 0, acconto : 0, contanti : 0, pos : 0, bonifico : 0 };

                                    acc[name].visite++;

                                    acc[name].acconto += debit;

                                    if (importoSaldato === 1 && 
                                            modalitàPagamento === 'CONTANTI') {

                                        acc[name].contanti += due;
                                        
                                        totalCash += due;

                                    }

                                    if (importoSaldato === 1 && 
                                            modalitàPagamento === 'POS') {

                                        acc[name].pos += due;

                                        totalPOS += due;

                                    }

                                    if (importoSaldato === 1 && 
                                            modalitàPagamento === 'BONIFICO') {

                                        acc[name].bonifico += due;

                                        totalTransfer += due;

                                    }

                                    if (!importoSaldato) acc[name].daSaldare = 1;

                                    totalVisits++;

                                    totalDeposit += debit;
                                
                                    return acc;

                                }, {})

                            )
                            .map(([key, item]) => {

                                const { _id, visite, acconto, contanti, pos, bonifico, daSaldare } = item;

                                return (

                                    <tr
                                        key={_id}
                                        onClick={handleOnClick(_id)}
                                    >
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <AlertBadge
                                                    show={daSaldare}
                                                />
                                                {key}
                                            </div>
                                        </td>
                                        <td className='text-right'>{visite}</td>
                                        <td className='text-right'>€ {acconto.toFixed(2)}</td>
                                        <td className='text-right'>€ {contanti.toFixed(2)}</td>
                                        <td className='text-right'>€ {pos.toFixed(2)}</td>
                                        <td className='text-right'>€ {bonifico.toFixed(2)}</td>
                                        <td className='text-right font-bold'>€ {Number(acconto + contanti + pos + bonifico).toFixed(2)}</td>
                                    </tr>

                                )

                            })
                        }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Totale generale</th>
                                <th className='text-right no-wrap'>
                                    {totalVisits}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {totalDeposit.toFixed(2)}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {totalCash.toFixed(2)}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {totalPOS.toFixed(2)}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {totalTransfer.toFixed(2)}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {(totalDeposit + totalCash + totalPOS + totalTransfer).toFixed(2)}
                                </th>
                            </tr>
                        </tfoot>
                    </Table>

            }

            {
                toggleModal &&

                    <SummaryDetailModal
                        date={date}
                        subject={doctors.find(item => item._id === filter)}
                        data={data.filter(item => item.medico._id === filter)}
                        isOpen={toggleModal}
                        toggleModal={setToggleModal}
                    />

            }

        </CardDate>
        
    )
    
}