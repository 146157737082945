import './InputOutput.css';

import React, { useState, useEffect, useRef, } from 'react';

import { Button, Card, Form, ListGroup, Badge } from 'react-bootstrap';

import moment from 'moment';
import Ajax from '../../components/Ajax';
import PathBreadcrumb from '../../components/PathBreadcrumb';
import Loader from '../../components/Loader';

export default function InputOutput() {
    
    const didMount = useRef(false);

    const [loading, isLoading] = useState(true);

    const [data, setData] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        
        if (didMount.current) return;

        const retrieveCollections = async() => {

            return await Ajax({
                route : 'database', 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveCollections()
        .then(data => {

            isLoading(false);

            setData(data);

        });
    
    }, []);

    const handleOnClick = (item) => (e) => {

        const updatedSelected = [...selected];

        const index = updatedSelected.indexOf(item);

        if (index === -1) {

            updatedSelected.push(item);

        } else {

            updatedSelected.splice(index, 1);

        }

        setSelected(updatedSelected);

    }

    const exportSelected = () => {

        const scope = 'collezioni'

        Ajax({
            route : 'download',
            parametri : { params : { ambito : scope, parametri : selected.toLocaleString() }, responseType: 'blob' },
            blob: true,
            metodo : 'get'
        })
        .then(data => {

            const link = document.createElement('a');
            const url = URL.createObjectURL(data);

            const file = `iwe-output-${moment().format('YYYYMMDD.HHmm')}.xlsx`;

            link.href = url;
            link.download = file;
            link.click();

            link.remove();
            URL.revokeObjectURL(url);

        });

    }

    return (
        <>
            <PathBreadcrumb />

            <Form>
                <Card disabled className='mb-3'>
                    <Card.Header>
                        Input
                        <Button size='sm' type='submit' disabled style={{ float : 'right' }}>Importa</Button>
                    </Card.Header>
                    <Card.Body>
                        
                        <Form.Group>

                            <Form.Control type='file' accept='.xls,.xlsx' />

                        </Form.Group>

                    </Card.Body>
                </Card>
            </Form>

            <Card className='mb-3'>
                <Card.Header>
                    Output
                    <Button size='sm' onClick={exportSelected} disabled={selected.length === 0} style={{ float : 'right' }}>Esporta</Button>
                </Card.Header>
                <Card.Body className='p-0'>
                    <ListGroup
                        variant='flush'
                        className='list'
                    >

                    {
                        loading ? 
                        
                        (

                            <Loader />

                        ) : (

                            data
                            .map(item => (

                                <ListGroup.Item
                                    key={item.name} 
                                    variant={selected.includes(item.name) ? 'primary' : ''}
                                    onClick={handleOnClick(item.name)}
                                >
                                    {item.name.toUpperCase()}
                                    <div className='ms-2 d-inline text-muted'>
                                        <Badge>{item.record}</Badge>
                                    </div>
                                </ListGroup.Item>

                            ))

                        )

                    }
                    </ListGroup>
                </Card.Body>
            </Card>
        </>
        
    )

}