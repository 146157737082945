import { Alert } from "react-bootstrap";
import { InfoCircle } from "react-bootstrap-icons";

export default function InfoDataUpdated(props) {

    const { show } = props;

    return (

        <Alert 
            show={show}
            className='mt-3 d-flex align-items-center'
            variant='success'
            transition={true}
        >

            <InfoCircle size={20} className='me-2' /> Dati aggiornati correttamente

        </Alert>

    )            

}