import InterfaceModal from "./InterfaceModal";

export default function FeedbackModal(props) {

    const { isOpen, toggleModal } = props;

    return (

        <InterfaceModal
            show={isOpen}
            onHide={toggleModal}
            header=' Scrivere buone segnalazioni di bug e richieste di funzionalità'
            isAlertOpen={false}
        >
                
                <p
                    className='mb-2'
                >
                    Invia una singola richiesta per problema o richiesta di funzionalità.
                    <ul
                        className='m-0'
                    >
                        <li>Non riportare più bug o richieste di funzionalità nello stesso problema.</li>
                    </ul>
                </p>

                <p
                    className='mb-2'
                >
                    Più informazioni puoi fornire, più è probabile che qualcuno riesca a riprodurre il problema e a trovare una soluzione.
                </p>

                <p
                    className='mb-2'
                >
                    Si prega di includere quanto segue in ogni problema.
                    <ul
                        className='m-0'
                    >
                        <li>Passaggi riproducibili (1... 2... 3...) e cosa ti aspettavi rispetto a ciò che hai effettivamente visto.</li>
                        <li>Immagini, animazioni o un video tramite la funzione Allega. Tieni presente che le immagini e le animazioni illustrano i passaggi della riproduzione ma non li sostituiscono.</li>
                        <li>Errori visualizzati nella console degli strumenti di sviluppo, se possibile.</li>
                    </ul>
                </p>

                <p
                    className='mb-2'
                >
                    Non sentirti in colpa se non riusciamo a riprodurre il problema e ti chiediamo maggiori informazioni :)
                </p>

                <p>Grazie del supporto!</p>

        </InterfaceModal>

    )

}