import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Calendar2Event } from "react-bootstrap-icons";

import useCalendar from "../hooks/useCalendar";

export default function SelectAppointmentType(props) {

    const { data, name, onChange, disabled, noRadius } = props;

    const [show, setShow] = useState(false);

    const handleOnSelect = (value) => {

        onChange({ target : { name , value }});

    }

    const handleOnToggle = (show) => {

        setShow(show);

    }

    const { eventCategory } = useCalendar({});

    return (

        <DropdownButton 
            variant={!data.categoria ? 'light' : 'warning'}
            title={<Calendar2Event title='Seleziona categoria' />}
            className={`action-dropdown${noRadius ? ' no-radius' : ''}`}
            size='lg'
            disabled={disabled}
            onSelect={handleOnSelect}
            onToggle={handleOnToggle}
            show={show}
            align='end'
        >

        {
            Object.entries(eventCategory).map(([key, value]) => (

                    <Dropdown.Item 
                        eventKey={key}
                        active={key === (data.categoria || '')}
                    >
                        {value}
                    </Dropdown.Item>

                )
            )
        }

        </DropdownButton>

    )

}