import moment from "moment";

export const getLastAccessData = (data) => {

    const { login = {} } = data;

    if (Object.keys(login).length > 0) {

        return `${moment(login.data).format('ddd DD/MM/YYYY HH:mm')} da ${login.ip}`;

    }

    return '';

}
