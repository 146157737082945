import moment from 'moment';
import useDndCalendar from '../../hooks/useDndCalendar'
import CalendarNavbar from '../../components/CalendarNavbar';
import PageSpinner from '../../components/PageSpinner';
import { Button, ButtonGroup, Dropdown, DropdownButton, Form } from 'react-bootstrap';
import { DashCircle, List, PlusCircle, ZoomIn } from 'react-bootstrap-icons';
import { useState } from 'react';
import PathBreadcrumb from '../../components/PathBreadcrumb';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import AlertModal from '../../components/AlertModal';

export default function Planner (props) {

    const [updateList, setUpdateList] = useState(false);

    const [ availability, setAvailability ] = useState(1);
    const [ subject, setSubject ] = useState(1);
    const [ selected, setSelected ] = useState(null);

    const [ slotSelectError, setSlotSelectError ] = useState('');

    const handleClose = () => setSlotSelectError('');

    const handleOnClick = (value) => (e) => {

        setAvailability(value);

    }

    const handleOnSelect = (value) => (e) => {

        setSubject(value);

        if (value === 1) setSelected(null);

    }

    const handleOnSelectDoctor = (id) => {

        setSelected(id);

    }

    const setDropdownTitle = () => {

        if (!selected) return 'Seleziona'

        const doctor = doctors.find(item => item._id === selected);

        const { nome, cognome } = doctor;

        return `${cognome}, ${nome}`;

    }

    const { 

        localizer,
        minTime,
        maxTime,
        customFormats,

        events,
        doctors,
        date,
        range,
        view,
        localeView,

        timeslots,
        handleExpand,

        eventStyleGetter,

        DnDCalendar,
        EventComponent,

        formatDateHeader,

        handleSelectSlot,
        handleEventResize,
        handleDeleteEvent,
        handleOnViewChange,
        handleOnNavigate,
        handleOnRangeChange,
        handleTimeslots

    } = useDndCalendar({
     
        defaultView : 'month',
        availability,
        loadDatastore : true,
        subject : subject,
        selected : selected,

    });

    const toolbar = (loading) => (toolbar) => {

        const header = () => {

            const data = moment(date);
    
            const header = [];
    
            switch(view) {
    
                case 'work_week':

                    const start = moment(range[0]).format('YY-MM'); 
                    const end =  moment(range[4]).format('YY-MM');

                    const headerStart = formatDateHeader('', range[0], 'MMM '); 
                    const headerEnd =  formatDateHeader('', range[4], 'MMM ');
                                        
                    if (start === end) {

                        header.push(headerStart);

                    } else {

                        header.push(<div className='label-range'>{headerStart}-{headerEnd}</div>)

                    }

                    break;
    
                default:

                    header.push(formatDateHeader('', data, 'MMM '));
    
            }

            return typeof header === 'string' ? header.toUpperCase() : header;
    
        }
    
        const gotoNext = () => {

            toolbar.onNavigate('PREV');
    
        }    
    
        const gotoToday = () => {
    
            toolbar.onNavigate('TODAY');
    
        }    
    
        const gotoPrev = () => {

            toolbar.onNavigate('NEXT');
    
        }

        return (

            <CalendarNavbar
                header={header()}
                gotoNext={gotoNext}
                gotoPrev={gotoPrev}
                gotoToday={gotoToday}
                timeslots={view === 'work_week' ? timeslots : undefined}
                handleTimeslots={handleTimeslots}
                dropdownMenu={
                    
                    <>
                        { 
                            loading &&
                                <PageSpinner />
                        }
                        <DropdownButton 
                            size='sm'
                            className='vista-calendario me-1'
                            title={<div>{localeView}</div>}
                            align='end'
                            onSelect={handleOnViewChange}
                        >
                            <Dropdown.Item eventKey='month'>Mese</Dropdown.Item>
                            <Dropdown.Item eventKey='work_week'>Settimana</Dropdown.Item>
                        </DropdownButton>
                        {
                            ['work_week'].includes(view) &&
                                <Dropdown
                                    size='sm'
                                    align='end'
                                    as={ButtonGroup}
                                    autoClose='outside'
                                    title='Imposta grandezza slot'
                                    className='calendar-zoom'
                                >
                                    <Dropdown.Toggle variant='light'>
                                        <ZoomIn size={18} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div
                                            className='calendar-range d-flex align-items-center justify-content-center'
                                        >
                                            <PlusCircle />
                                            <Form.Range 
                                                min={1}
                                                max={4}
                                                value={timeslots}
                                                onChange={handleTimeslots}
                                                style={{ width: 100 }} 
                                                className='mx-1'
                                                title='Zoom'
                                            />
                                            <DashCircle />
                                        </div>
                                    </Dropdown.Menu>                            
                                </Dropdown>
                        }
                        <Dropdown
                            size='sm'
                            align='end'
                            as={ButtonGroup}
                        >
                            <Dropdown.Toggle variant='light'>
                                <List size={22} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={e => setUpdateList(new Date())}>Aggiorna</Dropdown.Item>
                            </Dropdown.Menu>                            
                        </Dropdown>
                    </>

                }
            />

        )

    }

    const handleOnSelectSlot = (slotInfo) => {

        if (slotInfo.action === 'select') {

            const start = moment(slotInfo.start);
            const end = moment(slotInfo.end);

            const overlappingEvents = events.reduce((acc, record) => {

                const { disponibilità, medico } = record;

                if (disponibilità === 0 && (medico === null || medico === selected)) {

                    acc.push(record);

                }

                return acc;

            }, []).filter(event => 

                start.isBefore(moment(event.end)) && 
                end.isAfter(moment(event.start))
                
            ).length;

            if (overlappingEvents) {
                setSlotSelectError('La selezione dello slot è incoerente rispetto alla pianificazione.');
                return;
            }
            
        }

        handleSelectSlot(slotInfo);

    }

    return (
        <>
            <PathBreadcrumb />
            <DnDCalendar
                className='selettore-orari planner'
                style={{ height : window.innerHeight - 170 }}                
                events={events}
                culture='it-IT'
                localizer={localizer}
                startAccessor='start'
                endAccessor='end'
                step={15}
                timeslots={timeslots}                
                views={['month', 'work_week']}
                view={view}
                date={date}
                min={minTime}
                max={maxTime}
                eventPropGetter={eventStyleGetter}
                selectable={subject === 1 || selected !== null}
                onSelectSlot={handleOnSelectSlot}
                resizable={subject === 1 || selected !== null}
                onEventResize={handleEventResize}
                onView={handleOnViewChange}
                onNavigate={handleOnNavigate}
                onRangeChange={handleOnRangeChange}
                formats={customFormats}
                components={{
                    toolbar : toolbar(''),
                    event: (props) => <EventComponent {...props} onDeleteEvent={handleDeleteEvent} />,
                    work_week: {
                        header: ({ date }) => {

                            const data = moment(date);
                    
                            const label = data.format('DD ') + data.format('ddd');
                            
                            return <b>{label}</b>;
                            
                        }
                    }
                }}
                showAllEvents
                showMultiDayTimes
                tooltipAccessor={null}
            />

            <div className='planner-toolbar p-2 d-flex align-items-center justify-content-between'>
                <ButtonGroup>
                    <Button 
                        variant={availability === 1 ? 'success' : 'light'}
                        onClick={handleOnClick(1)}
                    >
                        Disponibilità
                    </Button>
                    <Button 
                        variant={availability === 0 ? 'danger' : 'light'}
                        onClick={handleOnClick(0)}
                    >
                        Indisponibilità
                    </Button>
                </ButtonGroup>

                <div 
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >

                    <Form.Check
                        type={'radio'}
                        label='Struttura'
                        id='struttura'
                        name='subject'
                        checked={subject === 1}
                        onChange={handleOnSelect(1)}
                    />                    
                    <Form.Check
                        type={'radio'}
                        label='Medico'
                        id='medico'
                        name='subject'
                        checked={subject === 2}
                        onChange={handleOnSelect(2)}
                    /> 

                    <DropdownButton
                        className='filtro-medici'
                        disabled={subject === 1}
                        align='start'
                        title={setDropdownTitle()}
                        onSelect={handleOnSelectDoctor}
                        variant={subject === 2 ? 'primary' : 'light'}
                    >
                        {

                            doctors
                            .sort((a, b) => a.cognome.localeCompare(b.cognome))
                            .map(item => { 
                                
                                const { _id, nome, cognome } = item;

                                const active = _id === selected;

                                return (

                                    <Dropdown.Item 
                                        key={_id} 
                                        eventKey={_id} 
                                        active={active}
                                        className='d-flex align-items-center'
                                    >
                                    {
                                        `${cognome}, ${nome}`
                                    }
                                    </Dropdown.Item>

                                )

                            })

                        }
                    </DropdownButton>

                </div>
            </div>

            <AlertModal
                show={slotSelectError.length > 0}
                body={slotSelectError}
                onClose={handleClose}
                showButton={false}
            />

        </>
    )
}