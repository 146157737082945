import './AppointmentSummaryModal.css';

import { Table } from 'react-bootstrap';
import InterfaceModal from './InterfaceModal';
import CardList from './CardList';
import moment from 'moment';
import RightModalFooterNav from './RightModalFooterNav';
import AlertBadge from './AlertBadge';

export default function SummaryDetailModal(props) {

    const { date, subject, data, isOpen, toggleModal } = props;

    let totalDeposit = 0;
    let totalCash = 0;
    let totalPOS = 0;
    let totalTransfer = 0;

    const handleExport = () => {

        /*
        Ajax({
            route : 'stampe',
            parametri : { params : { tipo : `calendario_${localeView.toLowerCase()}`, data : moment(date).format('YYYY-MM-DD') }, responseType: 'blob' },
            blob: true,
            metodo : 'get'
        })
        .then(data => {

            const link = document.createElement('a');
            const url = URL.createObjectURL(data);

            const file = `${getAppNamespace}-calendario_${localeView.toLowerCase()}-${moment().format('YYYYMMDD.HHmm')}.xlsx`;

            link.href = url;
            link.download = file;
            link.click();

            link.remove();
            URL.revokeObjectURL(url);

        });
        */

    }

    return (

        <>

            <InterfaceModal
                show={isOpen}
                onHide={toggleModal}
                isAlertOpen={false}
                header={
                    
                    <>{`${subject?.cognome}, ${subject?.nome}`}</>

                }
                footer= {

                    <RightModalFooterNav>

                    </RightModalFooterNav>
    
                }

            >

                <CardList
                    header={`Resoconto ${moment(date).format('DD/MM/YYYY')}`}
                    readOnly={true}
                    showBreadcrumb={false}
                    setUpdateList={() => {}}
                    showExport
                    handleExport={handleExport}
                >

                    <Table responsive striped borderless hover className='m-0 tabella-appuntamenti'>
                        <thead>
                            <tr>
                                <th>Ora</th>
                                <th>Paziente</th>
                                <th className='text-right'>Anticipo</th>
                                <th className='text-right'>Contanti</th>
                                <th className='text-right'>POS</th>
                                <th className='text-right'>Bonifico</th>
                            </tr>
                        </thead>
                        <tbody>
                        {

                            data
                            .sort((a, b) => new Date(a.data) - new Date(b.data))
                            .filter(item => item.paziente !== undefined)
                            .map(item => {

                                const { data, paziente, importoAcconto = 0, importoDovuto = 0, bollo, importoSaldato, modalitàPagamento } = item;

                                const debit = Number(importoAcconto);
                                        
                                const duty = bollo ? 2 : 0;
                                const due = Number(importoDovuto) + duty;

                                const cash = importoSaldato === 1 && modalitàPagamento === 'CONTANTI' ? due : 0;
                                const pos = importoSaldato === 1 && modalitàPagamento === 'POS' ? due : 0; 
                                const transfer = importoSaldato === 1 && modalitàPagamento === 'BONIFICO' ? due : 0;

                                totalDeposit += debit;
                                totalCash += cash;
                                totalPOS += pos;
                                totalTransfer += transfer;

                                return (
                                    <tr
                                        key={item._id}
                                    >
                                        <td>
                                            <div className='d-flex align-items-center'>
                                                <AlertBadge
                                                    show={!importoSaldato}
                                                />
                                                { moment(data).format('HH:mm') }
                                            </div>
                                        </td>
                                        <td>{ `${paziente.cognome}, ${paziente.nome}` }</td>
                                        <td className='text-right'>{ debit.toFixed(2) }</td>
                                        <td className='text-right'>{ cash.toFixed(2) }</td>
                                        <td className='text-right'>{ pos.toFixed(2) }</td>
                                        <td className='text-right'>{ transfer.toFixed(2) }</td>
                                    </tr>
                                )

                            })

                        }
                        </tbody>
                        <tfoot>
                            <tr>
                                <th colSpan={2}></th>
                                <th className='text-right'>Anticipo</th>
                                <th className='text-right'>Contanti</th>
                                <th className='text-right'>POS</th>
                                <th className='text-right'>Bonifico</th>
                            </tr>
                            <tr>
                                <th colSpan={2}>Totale generale</th>
                                <th className='text-right no-wrap'>
                                    € {totalDeposit.toFixed(2)}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {totalCash.toFixed(2)}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {totalPOS.toFixed(2)}
                                </th>
                                <th className='text-right no-wrap'>
                                    € {totalTransfer.toFixed(2)}
                                </th>
                            </tr>
                        </tfoot>

                    </Table>

                </CardList>

            </InterfaceModal>

        </>

    )

}