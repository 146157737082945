
import './Loader.css';

import { Card, Spinner } from 'react-bootstrap';

export default function Loader() {

    return (

        <Card.Body className='loader'>
            <Spinner size='lg' />
        </Card.Body>

    )

}