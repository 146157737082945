import { useEffect, useState } from "react";
import { Button, Card, Table } from "react-bootstrap";
import InterfaceModal from "./InterfaceModal";
import { usePermissions } from "../hooks/usePermissions";
import Calendar2Search from "./Calendar2Search";
import PageSpinner from "./PageSpinner";
import Ajax from "./Ajax";
import moment from "moment";
import { CaretDownFill, CaretUpFill } from "react-bootstrap-icons";
import AlertModal from "./AlertModal";
import FormSpinnerButton from "./FormSpinnerButton";

const AvailabilityTable = ({ data, header, selectedSlot, handleOnClick }) => {

    const [expandedRows, setExpandedRows] = useState([]);

    const [doctor, doctorFirstAppointment] = header;

    if (Object.keys(data).length === 0) return <>Nessuno slot contiguo trovato.</>

    const handleRowClick = (id) => {
        const currentExpandedRows = expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(id);

        const newExpandedRows = isRowCurrentlyExpanded ? 
            currentExpandedRows.filter(rowId => rowId !== id) : 
            currentExpandedRows.concat(id);

        setExpandedRows(newExpandedRows);
    };

    return (

        <Table hover size='sm'>
            <thead>
                <tr>
                    <th className='w-100'>Giorno</th>
                    <th className='text-end'>Slot</th>
                    <td></td>
                </tr>
            </thead>
            <tbody>
            {
                Object.entries(data).map(([ key, value ]) => (

                    <>
                        <tr key={key} style={{ cursor : 'pointer' }} onClick={() => handleRowClick(key)}>
                            <td className={ expandedRows.includes(key) && 'font-bold'}>{moment(key).format('dddd DD MMMM')}</td>
                            <td className='text-end'>{value.length}</td>
                            <td className='text-end'>
                            {

                                expandedRows.includes(key) ? 
                                ( 
                                    <CaretUpFill size={10} />
                                ) : (
                                    <CaretDownFill size={10} />
                                )

                            }
                            </td>
                        </tr>
                        {
                            expandedRows.includes(key) && (
                                <tr key={`details-${key}`}>
                                    <td colSpan={3} className='p-3'>
                                        <Card>
                                            <Card.Body>

                                                <Table hover size='sm'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-center'>Slot {doctorFirstAppointment.toUpperCase()}</th>
                                                            <th className='text-center'>Slot {doctor.toUpperCase()}</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {   
                                                        value.map(item => {

                                                            const {collaboratore, medico} = item;

                                                            const selected = selectedSlot.join('') === `${moment(collaboratore).format('YYYY-MM-DD HH:mm')}${moment(medico).format('YYYY-MM-DD HH:mm')}`;
                                                            
                                                            return (

                                                                <tr key={collaboratore}>
                                                                    <td className={`text-center align-middle ${ selected && 'bg-warning' }`}>{moment(collaboratore).format('HH:mm')}</td>
                                                                    <td className={`text-center align-middle ${ selected && 'bg-warning' }`}>{moment(medico).format('HH:mm')}</td>
                                                                    <td className={`text-right ${ selected && 'bg-warning' }`}>
                                                                        <Button 
                                                                            size='sm'
                                                                            onClick={handleOnClick([
                                                                                moment(collaboratore).format('YYYY-MM-DD HH:mm'), 
                                                                                moment(medico).format('YYYY-MM-DD HH:mm')
                                                                            ])}
                                                                        >
                                                                            Seleziona
                                                                        </Button>
                                                                    </td>
                                                                </tr>

                                                            )

                                                        })
                                                    }
                                                    </tbody>
                                                </Table>

                                            </Card.Body>
                                        </Card>

                                    </td>
                                </tr>
                            )
                        } 
                    </>

                ))
            }
            </tbody>        
        </Table>

    )

}

export default function ButtonFisrtAppointmentSlot (props) {

    const { variant, action = true, subject, appointment, doctors, list, setUpdateList, disabled, isOpen, toggleModal, onChange } = props

    const { userFilter } = usePermissions();

    const [valid, setValidity] = useState({});

    const [data, setData] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);

    const [isBlurred, setModalBlur] = useState(false);

    const [loading, isLoading] = useState(false);

    const [header, setHeader] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState([]);

    const [ selectError, setSelectError ] = useState('');

    const handleClose = () => setSelectError('');

    const retriveFirstAppointmentSlots = async () => {

        const retrieveSlots = async() => {

            const [medico, medicoPrimaVisita] = subject;

            return await Ajax({
                route : `medici/disponibilitaPrimaVisita?medico=${medico}&collaboratore=${medicoPrimaVisita}`,
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        isLoading(true);

        retrieveSlots()
        .then(data => {

            setData(data);

            isLoading(false);

        })
        .catch(err => {

        });

    }

    useEffect(() => {

        if (!isOpen) return;

        setSelectedSlot([]);

        retriveFirstAppointmentSlots();

        if (!isOpen) return;

        const [medico, medicoPrimaVisita] = subject;

        const doctor = doctors.find(item => item._id === medico).cognome;
        const doctorFirstAppointment = doctors.find(item => item._id === medicoPrimaVisita).cognome

        setHeader([doctor, doctorFirstAppointment]);

    }, [isOpen])

    const handleOnClick = (slot) => (e) => {

        setSelectedSlot(slot);

    }

    const handleSubmit = e => {

        if (selectedSlot.length === 0) {

            setSelectError('Nessuno slot selezionato.');
            return;

        }

        const [doctorFirstAppointment, doctor] = selectedSlot;

        onChange({ target : { name : 'data', value : moment(doctor).format('YYYY-MM-DD HH:mm') }})
        onChange({ target : { name : 'dataPrimaVisita', value : moment(doctorFirstAppointment).format('YYYY-MM-DD HH:mm') }})

        setTimeout(() => {

            onChange({ target : { name : 'durata', value : 30 }})
            onChange({ target : { name : 'durataPrimaVisita', value : 60 }})
    
        }, 10);

        toggleModal(false);

    }

    return (

        <>

            <Button 
                variant={variant ?? 'light'}
                className={action ? 'action-button p-2 d-flex align-items-center' : 'ms-2 icon-button'}
                size={action ? 'lg' : ''}
                disabled={disabled}
                title='Trova slot combinati disponibili'
                onClick={e => toggleModal(true)}
                style={{
                    backgroundColor: 'rgba(25, 135, 84, .4)'
                }}
            >
                <Calendar2Search />
            </Button>

            <InterfaceModal
                show={isOpen}
                onHide={toggleModal}
                isAlertOpen={isUpdated}
                size='md'
                isBlurred={selectError.length}
                header={

                    <>
                        Prima visita {header.join('/')}
                    </>

                }
                footer= {

                    <>
                        <FormSpinnerButton 
                            caption='Applica'
                            title='Applica modifica'
                            disabled={isUpdated}
                            onClick={handleSubmit}
                            error={Object.keys(valid).length > 0} 
                        />
                    </>
    
                }
    
            >
            { 
                
                loading ?

                (

                    <PageSpinner />

                ) : (

                    <AvailabilityTable 
                        data={data}
                        header={header}
                        selectedSlot={selectedSlot}
                        handleOnClick={handleOnClick} 
                    />

                )

            }

            <AlertModal
                show={selectError.length > 0}
                body={selectError}
                onClose={handleClose}
                showButton={false}
            />


            </InterfaceModal>

        </>
        
    )

}