import moment from "moment";
import { useEffect, useState } from "react";
import { usePermissions } from "./usePermissions";
import Ajax from "../components/Ajax";
import { calculateContrast } from "../services/utils/contrast";
import AlertBadge from "../components/AlertBadge";

export default function useCalendar({ 

    updateList = null, 
    toggleModal = {}, 
    view = 'day', 
    loadDatastore = false ,
    autoUpdate = false,
    setAutoUpdate = () => {}

}) {

    const { isCalendarEditable, isCalendarFilterable, userFilter } = usePermissions(updateList);

    const [filters, setFilters] = useState(userFilter);

    const minTime = moment().hour(8).minute(0);
    const maxTime = moment().hour(20).minute(30);

    const [data, setData] = useState([]);
    const [doctors, setDoctors] = useState([]);

    const [planner, setPlanner] = useState([]);

    const [ selectedSlot, setSelectedSlot ] = useState({});

    const [appointmentData, setAppointmentData] = useState({});

    const [loading, isLoading] = useState(true);

    useEffect(() => {

        if (!loadDatastore) return;

        getDatastoreData();

        const retrieveAppointments = async() => {

            return await Ajax({
                route : 'appuntamenti', 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveAppointments()
        .then(data => {

            isLoading(false);

            setData(data);
            setAutoUpdate(false);

        });
    
    }, [updateList]);

    const getDatastoreData = async id => {

        const retrieveDoctors = async() => {

            return await Ajax({
                route : `medici`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        setDoctors(await retrieveDoctors());

        const retrievePlanner = async() => {

            return await Ajax({
                route : `planner`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        setPlanner(await retrievePlanner());

    }

    const filterEvents = (eventi) => {
    
        return (
            
            eventi
            .filter(record => !filters.length || filters.includes(record.record.medico._id))
            .map(record => {
            
                return {
                    ...record,
                    start: new Date(record.start),
                    end : new Date(record.end)
                }
    
            })
    
        )
    
    }

    const event = ({ event }) => {
        
        const { start, title, record : { stato, categoria, tipo, durata, medico, confermato, arrivato, uscito, videochiamata } } = event;

        const time = moment(start).format('HH:mm')

        let size = ''

        if (durata < 45) size = '-small';
        if (durata < 30) size = '-extra-small';

        return (

            <>
            
                <div
                    className={`rbc-event-label${size}`}
                >
                    {
                        view !== 'agenda' &&

                            <>{time} &bull; </>

                    }

                    {medico.cognome}, {medico.nome}
                </div>

                <div
                    className='d-flex align-items-center'
                    style={{
                        height: 'calc(100% - 17px)'
                    }}
                >
                    <AlertBadge show={!categoria && stato !== 1} />
                    <div className='w-100 descrizione-evento'>

                    {
                        !categoria ? (

                            <>
                                <span className={`${!confermato ? 'da-confermare' :''}`}>
                                    <span className='font-bold'>{title}</span>
                                </span>

                                {createAppointmentTypeLabel(tipo)}
                                {createAppointmentPatientStatusLabel(arrivato, uscito, videochiamata)}
                            </>
                        ) : (

                            <div className={`font-bold ${categoria ? 'w-100 inverti-colori' : ''}`}>{title}</div>

                        )
                    }

                    </div>
                </div>
            </>

        )

    }

    const eventWrapper = ({ event, children }) => {

        const { record : { medico : { coloreSfondo } } } = event;

        const backgroundColor = userFilter.length === 0 ? coloreSfondo : '';
        const contrastRatio = calculateContrast(backgroundColor || '#d3d4d5', '#212529');

        const style = {
            color: contrastRatio < 4.5 ? '#fff' : '#212529',
            backgroundColor : backgroundColor || '#d3d4d5'
        }

        return (
            <div
                className='rbc-event-container'
                style={style}
            >
            {children}
            </div>  
        );

    }     

    const formatDateHeader = (label, date, format) => {

        const part = moment(date).format(format).toUpperCase();
        const year = moment(date).format('YY');
    
        return (
    
            <>
                {
                    label &&
                        <span className='etichetta-header'>{label}</span>
                }
                <span>
                    {part}
                    <span className='etichetta-anno'>
                        {year}
                    </span>
                </span>
            </>
                         
        )
    
    }

    const getAppointmentData = (id) => e => {

        const retrieveAppointment = async(id) => {

            if (!id) {

                return Promise.resolve({});

            }

            return await Ajax({
                route : `appuntamenti/${id}`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveAppointment(id)
        .then(data => {

            if (!id) {
                
                data.visitaInPresenza = true;

            }

            setAppointmentData(data);
            toggleModal(true);

        })
        .catch(err => {

        });

    }

    const handleOnChange = e => {

        const { name, value } = e.target;

        setAppointmentData(previousData => ({
            ...previousData,
            [name] : value
        }))

        if (autoUpdate) return;

        if (name === 'medico') {

            handleOnChange({ target : { name: 'paziente', value : '' }});
            handleOnChange({ target : { name: 'data', value : '' }});
            handleOnChange({ target : { name: 'durata', value: '' }});
            handleOnChange({ target : { name: 'nuovaData', value : '' }});
            handleOnChange({ target : { name: 'durataNuovaData', value: '' }});

        }

        if (name === 'data') {
            
            handleOnChange({ target : { name: 'durata', value: '' }});

        }

        if (name === 'medicoPrimaVisita') {

            handleOnChange({ target : { name: 'dataPrimaVisita', value: '' }});

        }

        if (name === 'dataPrimaVisita') {

            handleOnChange({ target : { name: 'durataPrimaVisita', value: '' }});

        }

        if (name === 'importoDovuto' && value < 77) {

            handleOnChange({ target : { name: 'bollo', value: false }});

        }

        if (name === 'nuovaData') {

            handleOnChange({ target : { name: 'durataNuovaData', value: '' }});
            
        }

    }

    const createAppointmentTypeLabel = type => {

        if (!type) return '';

        const codes = {
            1 : '1V',
            2 : 'C',
            3 : 'D+',
            4 : 'PS'
        }

        return ` (${codes[type]})`;

    }

    const createAppointmentPatientStatusLabel = (arrived, exited, remote) => {

        if (exited === 1) return ' (USC)';
        if (arrived === 1) return ' (ARR)';
        if (remote === 1) return ' (VID)';

        return '';

    }

    const eventCategory = {
        '' : 'Visita',
        '1' : 'No',
        '2' : 'Pausa',
        '3' : 'Recupero',
        '4' : 'Scorta',
        '5' : 'Solo se serve',
        '6' : 'Vede 1V',
    };

    const views = {
        month : 'Mese',
        work_week : 'Settimana',
        day : 'Giorno',
        agenda : 'Agenda',
    }

    return {

        views,
        
        isCalendarEditable, 
        isCalendarFilterable, 

        loading,
        data,
        doctors,
        planner,

        minTime,
        maxTime,
        filterEvents,

        setFilters,
        filters,

        selectedSlot,
        setSelectedSlot,

        event,
        eventWrapper,
        eventCategory,

        getAppointmentData,        
        appointmentData,
        handleOnChange,

        formatDateHeader,
        createAppointmentTypeLabel,
        createAppointmentPatientStatusLabel

    }
    
}
