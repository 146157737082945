import { FloatingLabel, Form } from "react-bootstrap";
import FormInputError from "./FormInputError";
import FormSpinnerButton from "./FormSpinnerButton";

export default function FormFloatingTextarea(props) {

    const { autoFocus, label, name, onChange, onKeyUp, onClick, value, valid, height = 132, readOnly, className, loading } = props;

    return (

        <div className={`mb-3 ${className}`}>

            <FloatingLabel
                label={label}
            >
                { 
                    onClick &&
                        <FormSpinnerButton
                            style={{
                                position: 'absolute',
                                right: '.75rem',
                                bottom: '.5rem',
                            }}
                            caption='Salva'
                            title='Salva dati'
                            size='sm'
                            loading={loading}
                            onClick={onClick}
                        />
                }
                <Form.Control
                    autoFocus={autoFocus}
                    as='textarea'
                    name={name}
                    onChange={onChange}
                    value={value[name]}
                    placeholder={label}
                    readOnly={readOnly}
                    onKeyUp={onKeyUp}
                    style={{
                        height: height,
                        paddingBottom : onClick ? '3rem' : '.75rem',
                    }}
                />
            </FloatingLabel>

            <FormInputError show={valid?.[name]?.length > 0} caption={valid?.[name]} />
            
        </div>

    )
}