import { useEffect, useState } from "react";
import { Button, Card, Collapse } from "react-bootstrap";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import { getAppNamespace } from "../hooks/useApp";

export default function CardWidget(props) {

    const { header, children } = props;

    const key = `${getAppNamespace}.widget.${header.toLowerCase()}`;

    const defaultState = (localStorage.getItem(key) ?? 'true') === 'true';

    const [open, setOpen] = useState(defaultState);
    const [exited, setExited] = useState(defaultState);

    useEffect(() => {

        localStorage.setItem(key, open);

    }, [open])

    return (

        <Card className='widget mb-3'>
            <Card.Header
                style={{
                    borderBottom: exited ? 'var(--bs-card-border-width) solid var(--bs-card-border-color)' : 'none'
                }}
            >
                { header }
                <div style={{float : 'right'}}>
                    <Button 
                        size='sm'
                        variant='light'
                        onClick={e => setOpen(!open)}
                    >
                    {
                        open ? 
                            (
                                <ArrowUp />
                            ) : (
                                <ArrowDown />
                            )
                    }
                    </Button>
                    
                </div>
            </Card.Header>
            <Collapse
                in={open}
                onEntering={e => setExited(true)}
                onExited={e => setExited(false)}
            >
                <Card.Body>
                    { children }
                </Card.Body>
            </Collapse>
        </Card>
        
    )

}