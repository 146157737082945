import React from "react";

function Calendar2Search() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      className="bi bi-calendar2"
      version="1.1"
      viewBox="0 0 16 16"
    >
      <path d="M3.5 0a.5.5 0 01.5.5V1h8V.5a.5.5 0 011 0V1h1a2 2 0 012 2v11a2 2 0 01-2 2H2a2 2 0 01-2-2V3a2 2 0 012-2h1V.5a.5.5 0 01.5-.5M2 2a1 1 0 00-1 1v11a1 1 0 001 1h12a1 1 0 001-1V3a1 1 0 00-1-1z"></path>
      <path
        strokeWidth="0.424"
        d="M9.552 11.221a2.695 2.818 0 10-.58.606q.019.026.041.05l1.596 1.67a.415.434 0 00.587-.614L9.6 11.264a.415.434 0 00-.048-.043zm.107-1.666a2.28 2.384 0 11-4.56 0 2.28 2.384 0 014.56 0"
      ></path>
      <path d="M2.5 4a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v1a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5z"></path>
      <ellipse
        cx="7.382"
        cy="9.545"
        fill="#fff"
        fillOpacity="0"
        stroke="#000"
        strokeOpacity="1"
        strokeWidth="0.396"
        opacity="1"
        rx="2.116"
        ry="2.245"
      ></ellipse>
    </svg>
  );
}

export default React.memo(Calendar2Search);