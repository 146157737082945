import { NavDropdown } from "react-bootstrap";
import { useState } from "react";

import ProfileModal from "./ProfileModal";

export default function ProfileNavItem(props) {

    const [isOpen, toggleModal] = useState(false);

    return (
        <>
            <NavDropdown.Item 
                onClick={e => toggleModal(true)}
            >
                Profilo
            </NavDropdown.Item>
            {

                isOpen &&
                    <ProfileModal 
                        isOpen={isOpen} 
                        toggleModal={toggleModal} 
                    />

            }
        </>

    )

}