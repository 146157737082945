import './FormPasswordInput.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import { forwardRef, useState } from 'react';
import FormInputError from './FormInputError';
import { Eye, EyeSlash } from 'react-bootstrap-icons';

const FormPasswordInput = forwardRef((props, ref) => {

    const {name, label, value, onChange, onKeyUp, valid, autoFocus} = props;

    const [isVisible, setIsVisible] = useState(false);

    const handleVisibility = (e) => {

        e.preventDefault();

        setIsVisible(!isVisible);

    }

    return (

        <div className='mb-3'>

            <FloatingLabel className='password' label={label || 'Password'}>
                <Form.Control
                    ref={ref}
                    name={name}
                    type={isVisible ? 'text' : 'password'}
                    placeholder={label || 'Password'}
                    aria-label='Password'
                    aria-describedby='basic-addon1'
                    value={value[name]}
                    onChange={onChange}
                    onKeyUp={onKeyUp}
                    autoFocus={autoFocus}
                />
                {
                    !isVisible ? 
                    (
                        <Eye 
                            className='toggle-password' 
                            onMouseDown={handleVisibility}
                        />
    
                    ) : (
                        <EyeSlash
                            className='toggle-password' 
                            onMouseDown={handleVisibility}
                        />
    
                    )
                }
            </FloatingLabel>
            
            <FormInputError show={valid?.[name]?.length > 0} caption={valid?.[name]} />

        </div>

    );

});

export default FormPasswordInput;
