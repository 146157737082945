import { useState, useEffect } from 'react';
import { getAppNamespace, setAppNamespace, setAppEnvironment } from './useApp';

export default function useToken() {

    const [token, setToken] = useState(null);
    const [isConfigLoaded, setIsConfigLoaded] = useState(false);

    useEffect(() => {

        fetch('/config/default.json')
            .then(response => response.json())
            .then(config => {

                setAppNamespace(config.namespace);
                setAppEnvironment(config.environment);

                setIsConfigLoaded(true);
            })
            .catch(error => console.error('Errore durante il caricamento della configurazione:', error));

    }, []);

    useEffect(() => {

        if (isConfigLoaded) {

            const tokenString = localStorage.getItem(`${getAppNamespace}.token`);
            setToken(tokenString ? JSON.parse(tokenString) : null);

        }

    }, [isConfigLoaded, getAppNamespace]);

    const saveToken = userToken => {

        if (userToken) {
            localStorage.setItem(`${getAppNamespace}.token`, JSON.stringify(userToken));
        } else {
            localStorage.removeItem(`${getAppNamespace}.token`);
        }

        setToken(userToken);

    };

    return {
        setToken: saveToken,
        isConfigLoaded,
        token
    };

}
