import './ButtonPasswordPolicy.css';

import { useEffect, useRef, useState } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';

export default function ButtonPasswordPolicy(props) {

    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
  
    const ref = useRef();

    const [width, setWidth] = useState(0);

    const handleClick = (event) => {

        setShow(!show);
        setTarget(event.target);

    };

    useEffect(() => {

        setWidth(ref.current.clientWidth)

    }, [ref])

    return (

        <div 
            ref={ref}
            className='d-grid gap-2'
            style={{
                margin: '-.5rem 0 1rem'
            }}
        >

            <Button
                size='sm'
                onClick={handleClick}
                ref={ref}
            >
                Password policy
            </Button>

            { 
                show &&
                    <Overlay
                        show={true}
                        target={target}
                        placement='bottom'
                        containerPadding={20}
                        rootClose={true}
                        onHide={e => setShow(false)}
                    >
                        <Popover 
                            className='overlay-password-policy'
                            style={{
                                minWidth: width
                            }}
                        >
                        <Popover.Body>
                            La password deve essere lunga <b>almeno</b> 8 caratteri e contenere: 
                            una lettera MAIUSCOLA, una lettera minuscola, 
                            un numero e un carattere speciale (ad esempio: .,-!$_^|).
                        </Popover.Body>
                        </Popover>
                </Overlay>
            }

        </div>

    )

}