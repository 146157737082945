import { Button, Spinner } from "react-bootstrap";

export default function FormSpinnerButton(props) {

    const { caption, title, style, size, disabled, loading, onClick, error } = props;

    return (

        <Button
            variant={error ? 'danger' : 'primary'}        
            disabled={loading || disabled}
            size={size}
            style={{
                ...style,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            onClick={onClick}
            title={title}
        >
            {
                loading &&
                    <Spinner
                        size='sm'
                        animation='border' 
                        style={{
                            position: 'absolute',
                        }}
                    />
            }
            <span
                style={{
                    opacity: loading ? 0 : 1
                }}
            >
                { caption }
            </span>
        </Button>

    )

}