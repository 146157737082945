import { useEffect, useState } from 'react';

import { ArrowLeft, ArrowRight, XLg } from 'react-bootstrap-icons';

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './FormDayPicker.css';

import it from 'date-fns/locale/it';

import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';

import Button from 'react-bootstrap/Button';
import { addMonths } from 'date-fns';
import { Modal } from 'react-bootstrap';

import moment from 'moment';
import FormInputError from './FormInputError';
import RightModalFooterNav from './RightModalFooterNav';
import ButtonClearValue from './ButtonClearValue';

registerLocale(
    'it', {
        ...it,
        timeHeader: 'Orario'
    }
);

export default function FormDayPicker(props) {

    const { label, name, value, valid, onChange, isModalVisible, minDate, maxDate, showTimeSelect, disabled, readOnly, excludeTimes, style, officeHours, planner } = props;

    const [date, setDate] = useState();
    const [internalValue, setInternalValue] = useState('');

    const [ internalValid, setInternalValidity ] = useState({});

    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (e) => {

        setDate(moment(e).startOf('day').toDate());

    }

    const handleChange = (e) => {

        setDate(e);

    };

    const handleClick = (e) => {

        isModalVisible(!isOpen);

        e.preventDefault();
        setIsOpen(!isOpen);

    };

    const handleClearDate = e => {

        setDate(null);

    }

    const handleMonthChange = (date, change) => e => {

        setDate(prevDate => {

            const nextDate = addMonths(prevDate ?? moment().toDate(), change);

            return nextDate;

        });

    };

    const renderCustomHeader = ({
          date,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled
        }) => {

          return (
            <div className='d-flex justify-content-between'>
              <Button
                  className='ms-1'
                  variant='light'
                  disabled={prevMonthButtonDisabled}
                  onClick={handleMonthChange(date, -1)}
              >
                  <ArrowLeft size={18} />
              </Button>
              <h2>
                  {
                      date.toLocaleDateString('it-IT', {
                          month: '2-digit', year: 'numeric'
                      })
                  }
              </h2>
              <Button
                  className='me-1'
                  variant='light'
                  disabled={nextMonthButtonDisabled}
                  onClick={handleMonthChange(date, 1)}
              >
                  <ArrowRight size={18} />
              </Button>
            </div>
        );
    };

    useEffect(() => {

        const date = value[name];

        if (date) {

            setDate(moment(date).toDate());

            setInternalValue(moment(value[name]).format('dddd DD MMM YYYY HH:mm'));

            return;
    
        } 

        setDate(null);
        
        setInternalValue('');

    }, [setDate, value, name]);

    const handleFilterDate = date => {

        var plannerAvailability = planner.filter(item => (

            moment(date).startOf('day').isBetween(
                moment(item.start).startOf('day'), 
                moment(item.end).startOf('day'),
                undefined,
                '[]'
            )

        ));

        const dayOfWeek = moment(date).format('ddd');

        if (plannerAvailability.length > 0) {

            const officeHoursDuration = officeHours[dayOfWeek]?.reduce((acc, item) => 
                acc + Math.ceil(moment(item.fine, 'HH:mm').diff(moment(item.inizio, 'HH:mm'), 'minutes', true))
            , 0);

            const {plannerDisponibilityDuration, plannerIndisponibilityDuration} = plannerAvailability.reduce((acc, item) => {

                const type = item.disponibilità === 1 ? 'Disponibility' : 'Indisponibility';
                const minutes = Math.ceil(moment(item.end).diff(moment(item.start), 'minutes', true));

                acc[`planner${type}Duration`] += minutes;

                return acc;

            }, { plannerDisponibilityDuration : 0, plannerIndisponibilityDuration : 0 });

            if (plannerDisponibilityDuration > 0) return true;

            if (plannerIndisponibilityDuration >= (officeHoursDuration ?? 0)) return false;

            return true;

        }

        if (!officeHours || Object.keys(officeHours).length === 0) return true;

        return officeHours[dayOfWeek]?.length > 0;

    }

    const handleFilterTime = time => {

        const timestamp = moment(time);

        var plannerAvailability = planner.filter(item => (

            timestamp.isBetween(moment(item.start), moment(item.end), undefined, '[)')

        ));

        if (plannerAvailability.length > 0) {

            const [ item ] = plannerAvailability;

            if (item.disponibilità === 0) return false;

            return excludeTimes.some(item => timestamp.isBetween(item.inizio, item.fine, undefined, '[)')) === false;

        }

        const dayOfWeek = timestamp.format('ddd');
        const officeHoursForDayOfWeek = officeHours[dayOfWeek]

        if (officeHoursForDayOfWeek) {

            if (

                officeHoursForDayOfWeek.some(item => {

                    const [hoursStart, minutesStart] = item.inizio.split(':').map(Number);
                    const [hoursEnd, minutesEnd] = item.fine.split(':').map(Number);
                    
                    const start = moment(timestamp).hours(hoursStart).minutes(minutesStart).seconds(0).milliseconds(0);
                    const end = moment(timestamp).hours(hoursEnd).minutes(minutesEnd).seconds(0).milliseconds(0);

                    return timestamp.isBetween(start, end, undefined, '[)');

                }) === false

            ) return false;

        } else {

            return false

        }

        return excludeTimes.some(item => timestamp.isBetween(item.inizio, item.fine, undefined, '[)')) === false;

    }

    const isFormDataValid = () => {

        if (showTimeSelect === false) {

            return true;

        }

        const errors = {};


        if (moment(date).format('HH:mm:ss').endsWith('00:00:00') === true) {
            errors.data = 'Orario non selezionato';
        }

        setInternalValidity(errors);

        return Object.keys(errors).length === 0;

    }

    const handleSubmit = () => {

        if (!isFormDataValid()) {

            return;

        }

        let value = '';
        let internalValue = ''

        if (date) {

            const m = moment(date);

            value = m.format('YYYY-MM-DD HH:mm:ss');
            
            internalValue = m.format(`dddd DD/MM/yyyy${showTimeSelect === true ? ' HH:mm' : ''}`);

        }

        setInternalValue(internalValue);

        onChange({ target : { name, value } });

        isModalVisible(!isOpen);
        setIsOpen(!isOpen);

    }   

    return (
        <div className='mb-3 flex-grow-1'>

            <FloatingLabel 
                className='form-day-picker'
                label={label}
                readOnly={readOnly}
            >
                <Form.Control
                    style={style}
                    disabled={disabled}
                    value={internalValue}
                    name={name}
                    placeholder={label}
                    onMouseDown={handleClick}
                    readOnly
                />
            </FloatingLabel>

            <FormInputError show={valid?.[name]?.length > 0} caption={valid?.[name]} />

            {

                (isOpen && !readOnly) &&
                    <Modal
                        show={isOpen}
                        size='sm'
                        dialogClassName='modal-giorno-picker'
                        centered
                        backdrop='static'
                        keyboard={false}
                    >
                        <Modal.Body
                            className={`giorno-picker${showTimeSelect ? ' time-select' : ''}`}
                        >
                            <DatePicker
                                selected={date} 
                                locale='it'
                                dateFormat={`yyyy-MM-dd${showTimeSelect ? ' HH:mm' : ''}`}
                                name={name}
                                onSelect={handleSelect}
                                onChange={handleChange}
                                renderCustomHeader={renderCustomHeader}
                                maxDate={maxDate}
                                minDate={minDate}
                                timeIntervals={15}
                                showTimeSelect={showTimeSelect}
                                minTime={new Date().setHours(8, 30, 0)}
                                maxTime={new Date().setHours(20, 30, 0)}
                                timeCaption='Ora'
                                filterDate={handleFilterDate}
                                filterTime={handleFilterTime}
                                inline />

                            <FormInputError 
                                style={{
                                    margin: '0 .5rem .5rem'
                                }}
                                show={internalValid?.[name]?.length > 0} 
                                caption={internalValid?.[name]} 
                            />
                        </Modal.Body>
                        <Modal.Footer>

                            <RightModalFooterNav>

                                <ButtonClearValue
                                    onClick={handleClearDate}
                                    title='Elimina valore'
                                />

                            </RightModalFooterNav>

                            <Button 
                                onClick={handleSubmit}
                                variant={ Object.keys(internalValid).length > 0 ? 'danger' : 'primary'}
                            >
                                Applica
                            </Button>

                            <Button variant='light' className='icon-button' onClick={handleClick} style={{margin: '0 calc(var(--bs-modal-footer-gap) * .5)'}}>
                                <XLg />
                            </Button>

                        </Modal.Footer>                
                    </Modal>

            }

        </div>

    );

}