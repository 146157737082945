import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { Calendar2Plus, Trash3Fill } from "react-bootstrap-icons";
import InterfaceModal from "./InterfaceModal";
import { usePermissions } from "../hooks/usePermissions";
import ActionInputWrapper from "./ActionInputWrapper";
import FormDayPicker from "./FormDayPicker";
import SelectUpcomingAvailability from "./SelectUpcomingAvailability";
import moment from "moment";
import FormFloatingInput from "./FormFloatingInput";
import { v4 } from "uuid";
import FormInputError from "./FormInputError";
import Ajax from "./Ajax";
import FormFloatingSearchSelectInput from "./FormFloatingSearchSelectInput";
import FormSelect from "./FormSelect";
import FormFloatingAmountInput from "./FormFloatingAmountInput";
import FormSpinnerButton from "./FormSpinnerButton";

moment.locale('it');

export default function ButtonAppointmentSchedule (props) {

    const { variant, action = true, subject, appointment, doctors, list, setUpdateList, disabled, isOpen, toggleModal, handleOnChange : handleOnParentChange, handleSubmit : handleParentSubmit } = props

    const { userFilter } = usePermissions();

    const [valid, setValidity] = useState({});

    const [data, setData] = useState({});
    const [isUpdated, setIsUpdated] = useState(false);

    const [isBlurred, setModalBlur] = useState(false);

    const [loading, isLoading] = useState(false);
    const [availabilityLoading, isAvailabilityLoading] = useState(false);

    const eventTypes = {
        2 : 'Controllo (C)',
        3 : 'D+ (D+)',
        4 : 'Psicoterapia (PS)',
    }
    useEffect(() => {

        setIsUpdated(false);
        isLoading(false);

        if (!isOpen) return;

        setData({
            ...data,
            ...appointment,
            gestisciCollisioni : true, 
            piano : [],
            visite : '',
            cadenza : '',
            tipo : '',
            importoDovuto: '',
        });

        setValidity({});

    }, [isOpen])

    const handleOnChange = e => {

        const { name, checked, value, type } = e.target;

        const prevData = { 
            ...data ,
            [name] : type === 'checkbox' ? checked : value
        }

        if (name === 'medico') {

            prevData.data = '';

        }

        setData({
            ...prevData
        });

    }

    const handleChangeBlur = (isOpen) => {

        setModalBlur(isOpen);

    }

    const getOfficeHours = () => {

        if (!data.medico) return {};

        return doctors.find(item => item._id === data.medico).orariRicevimento;

    }

    const isFormDataValid = () => {

        const errors = {};

        if (!data.visite || data.visite < 0) {
            errors.visite = 'Totale non valido'
        }

        if (!data.cadenza) {
            errors.cadenza = 'Cadenza non valida'
        }

        if (!data.durata) {
            errors.durata = 'Durata non valida'
        }

        setValidity(errors);

        return Object.keys(errors).length === 0;

    }

    function checkAvailability(slots, startTime, durationMinutes) {

        const dateFormat = 'YYYY-MM-DD HH:mm:ss';
        const moment = require('moment');
        let requiredSlots = durationMinutes / 15;
        let currentTime = moment(startTime);

        for (let i = 0; i < requiredSlots; i++) {

            let slotToCheck = currentTime.format(dateFormat);
            
            if (!slots.includes(slotToCheck)) {
                return false;
            }
    
            currentTime.add(15, 'minutes');
        }
    
        return true;

    }
            
    function findFirstAvailableSlots(slots, startTime, requiredDurationMinutes) {

        const requiredSlots = requiredDurationMinutes / 15;

        let consecutiveSlotsFound = 0;
        let startSlotIndex = slots.findIndex(slot => moment(slot).isSameOrAfter(moment(startTime)));
    
        if (startSlotIndex === -1) {

            return null;

        }
    
        for (let i = startSlotIndex; i < slots.length; i++) {

            if (consecutiveSlotsFound === 0 || moment(slots[i]).diff(moment(slots[i - 1]), 'minutes') === 15) {

                consecutiveSlotsFound++;

                if (consecutiveSlotsFound === requiredSlots) {
                    return slots[i - requiredSlots + 1];
                }

            } else {

                consecutiveSlotsFound = 1;

            }
        }
    
        return null;        

    }

    const handleOnClick = async () => {

        if (!isFormDataValid()) {

            return;

        }

        const startDate = data.data;
        const dayInterval = data.visite * 7;
        const maxItems = dayInterval * 100;

        isAvailabilityLoading(true);

        const availability = await Ajax({
            route : `medici/${data.medico}/disponibilita/?data=${startDate}&giorni=${dayInterval}&elementi=${maxItems}`, 
            metodo : 'get'
        });

        isAvailabilityLoading(false);

        const schedule = [];

        const { data : inizio, durata, tipo, importoDovuto, visite, cadenza, gestisciCollisioni } = data;

        const scheduledDay = moment(inizio).startOf('week').add(cadenza - 1, 'days');
        const [scheduledHours, scheduleMinutes] = moment(inizio).format('HH:mm').split(':');

        schedule.push({
            _id : v4(),
            data : inizio,
            durata,
            tipo,
            importoDovuto,
        });

        for (let i = 1; i < visite; i++) {

            let collision = 0;

            let visit = moment(scheduledDay).add(7 * i, 'days').add(scheduledHours, 'hours').add(scheduleMinutes, 'minutes');

            if (gestisciCollisioni && 
                    !checkAvailability(availability, visit, durata)) {

                collision = 1;

                visit = findFirstAvailableSlots(availability, visit, durata);

            }

            schedule.push({
                _id : v4(),
                data : visit,
                durata,
                tipo,
                importoDovuto,
                collisione : collision,
            });

        }

        handleOnChange({ target : { name : 'piano', value : schedule }})

    }

    const handleOnDelete = (id) => (e) => {

        const schedule = [...data.piano];

        handleOnChange({ target : { name : 'piano', value : schedule.filter(item => item._id !== id ) }});

    }

    const isScheduleValid = () => {

        const errors = {};

        if (data.piano.length === 0) {
            errors.piano = 'Nessun piano visite impostato'
        }

        setValidity(errors);

        return Object.keys(errors).length === 0;

    }

    const handleSubmit = e => {

        if (!isScheduleValid()) {

            return 
        }

        const insertAppointment = async(data) => {

            return await Ajax({
                route : 'appuntamenti/', 
                parametri : { data : { ...data } },
                metodo : 'put',
            })
            .catch(err => {

            });

        }

        isLoading(true);

        const { medico } = data;
        const { _id : paziente } = subject;

        const appointmentPromises = data.piano.slice(1).map(item => {

            const { data, durata, tipo, importoDovuto } = item;

            return insertAppointment({
                medico,
                data,
                durata,
                tipo,
                importoDovuto,
                paziente,
            });

        })

        Promise.all(appointmentPromises)
        .then(() => {

        }).catch(err => {

        }).finally(() => {

            setIsUpdated(true);
            isLoading(false);

            if (!data._id) {
                
                const { durata, tipo, importoDovuto } = data.piano[0];
                
                handleOnParentChange({ target : { name: 'durata', value: durata }});
                handleOnParentChange({ target : { name: 'tipo', value: Number(tipo) }});
                handleOnParentChange({ target : { name: 'importoDovuto', value: importoDovuto }});
                
                handleParentSubmit(e);

            } else {

                setUpdateList(new Date());

            }

        });

    }

    return (

        <>

            <Button 
                variant={variant ?? 'light'}
                className={action ? 'action-button p-2 d-flex align-items-center' : 'ms-2 icon-button'}
                size={action ? 'lg' : ''}
                disabled={disabled}
                title={`Crea piano visite`}
                onClick={e => toggleModal(true)}
            >
                <Calendar2Plus />
            </Button>

            <InterfaceModal
                show={isOpen}
                onHide={toggleModal}
                isAlertOpen={isUpdated}
                header={

                    <>
                        Piano visite {subject?.cognome}, {subject?.nome}
                    </>

                }
                footer= {

                    <FormSpinnerButton 
                        caption='Salva'
                        title='Salva dati'
                        loading={loading}
                        disabled={isUpdated}
                        onClick={handleSubmit}
                        error={Object.keys(valid).length > 0} 
                    />
    
                }
    
            >

                <FormFloatingSearchSelectInput
                    className='mb-3'
                    label='Medico'
                    name='medico'
                    data={
                        doctors
                        .filter(item => userFilter.length > 0 ? userFilter.includes(item._id) : true)
                        .filter(item => subject?.medico ? item._id === subject.medico : true)
                        .sort((a, b) => a.cognome.localeCompare(b.cognome))                    
                    }
                    homonyms={[]}
                    onChange={handleOnChange}
                    value={data}
                    valid={valid}
                    style={{
                        textOverflow: 'ellipsis',
                    }}
                    updateList={list}
                />

            <Row>

                <Form.Group as={Col} md={12} lg={9}>

                    <ActionInputWrapper>

                        <FormDayPicker
                            style={{
                                marginBottom: '-1rem',
                                borderBottomRightRadius : 0,
                                borderTopRightRadius : 0,
                                borderRight: 0,
                                cursor: 'pointer'
                            }}
                            label='Data inizio schedulazione'
                            name='data'
                            onChange={handleOnChange}
                            value={data}
                            valid={valid}
                            isModalVisible={handleChangeBlur}
                            showTimeSelect
                            disabled={!data.medico}
                            officeHours={getOfficeHours()}
                            excludeTimes={
                                list
                                .filter(item => item.record.medico._id === data.medico)
                                .map(item => (
                                    {
                                        inizio : moment(item.start).toDate(),
                                        fine : moment(item.end).toDate(),
                                    }
                                ))
                            }
                        />

                        <SelectUpcomingAvailability 
                            name='data' 
                            subject={data.medico}
                            onChange={handleOnChange}
                            disabled={!data.medico}
                        />

                    </ActionInputWrapper>

                </Form.Group>

                    <Form.Group as={Col} md={12} lg={3}>

                        <FormFloatingInput
                            label='Totale visite'
                            name='visite'
                            onChange={handleOnChange}
                            value={data}
                            valid={valid}
                            type='number'
                        />

                    </Form.Group>

                </Row>
                <Row>

                    <Form.Group as={Col} md={12} lg={3}>

                        <FormSelect
                            label='Cadenza settimanale'
                            name='cadenza'
                            onChange={handleOnChange}
                            value={data}
                            valid={valid}
                        >
                            <option value=''>Seleziona</option>
                            <option value={1}>Lunedì</option>
                            <option value={2}>Martedì</option>
                            <option value={3}>Mercoledì</option>                            
                            <option value={4}>Giovedì</option>                            
                            <option value={5}>Venerdì</option>                            
                        </FormSelect>

                    </Form.Group>

                    <Form.Group as={Col} md={12} lg={3}>

                        <FormSelect
                            label='Durata'
                            name='durata'
                            onChange={handleOnChange}
                            value={data}
                            valid={valid}
                        >
                            <option value=''>Seleziona</option>
                            <option value={15}>15 min</option>
                            <option value={30}>30 min</option>
                            <option value={45}>45 min</option>                            
                            <option value={60}>60 min</option>                            
                        </FormSelect>

                    </Form.Group>

                    <Form.Group as={Col} md={12} lg={3}>

                        <FormSelect
                            label='Tipologia'
                            name='tipo'
                            onChange={handleOnChange}
                            value={data}
                            valid={valid}
                        >
                            <option value=''>Seleziona</option>
                            <option value={2}>Controllo (C)</option>
                            <option value={3}>D+ (D+)</option>                            
                            <option value={4}>Psicoterapia (PS)</option>                            
                        </FormSelect>

                    </Form.Group>

                    <Form.Group as={Col} md={12} lg={3}>

                        <FormFloatingAmountInput
                            label='Dovuto'
                            name='importoDovuto'
                            onChange={handleOnChange}
                            value={data}
                            valid={valid}
                            type='number'
                        />

                    </Form.Group>

                </Row>

                <Form.Check
                    className='mb-3'
                    type='switch'
                    id='gestisciCollisioni'
                    name='gestisciCollisioni' 
                    label='Gestisci automaticamente collisioni date visita'
                    checked={data.gestisciCollisioni}
                    onChange={handleOnChange}
                />                

                <div className='d-grid mb-3'>
                
                    <Button
                        variant='primary' 
                        disabled={!data.medico || !data.data || availabilityLoading}
                        onClick={handleOnClick}
                    >
                        Genera piano visite
                    </Button>

                    <FormInputError show={valid?.['piano']?.length > 0} caption={valid?.['piano']} />

                </div>

                <Card className='mb-3 elenco'>
                    <Card.Header className='py-1 font-normal'>Riepilogo date</Card.Header>
                    <Card.Body
                        className='p-0'
                    >

                        <Table responsive striped borderless hover className='m-0 tabella-piano-visite'>
                            <thead>
                                <tr>
                                    <th>Data</th>
                                    <th>Durata</th>
                                    <th>Tipologia</th>
                                    <th>Dovuto</th>
                                    <th>Avvisi</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {

                                    data.piano?.length > 0 ?

                                    (

                                        data.piano.map((record, index) => {

                                            const { _id, data, durata, tipo, importoDovuto, collisione } = record;

                                            const alert = () => {

                                                if (!data) return '[!] Impossibile determinare una data valida';
                                                if (collisione) return 'Data non disponibile: collisione risolta';

                                                return '';

                                            }

                                            return (
                                                <tr key={_id} className={ index === 0 && 'riga-disabilitata'}>
                                                    <td className='no-wrap'>
                                                        {
                                                            data ?
                                                                moment(data).format('ddd DD/MM/YYYY HH:mm') : '?'
                                                        }
                                                    </td>
                                                    <td className='no-wrap'>
                                                        { durata ? `${durata} min` : '--' }
                                                    </td>
                                                    <td className='no-wrap'>
                                                        { tipo ? eventTypes[tipo] : '--' }
                                                    </td>
                                                    <td className='no-wrap'>
                                                        { importoDovuto ? `€ ${importoDovuto}` : '--' }
                                                    </td>
                                                    <td
                                                        style={{
                                                            width: '100%'
                                                        }}
                                                    >
                                                        { alert() }
                                                    </td>
                                                    <td>
                                                        <Button
                                                            className='icon-button'
                                                            size='sm'
                                                            variant='danger'
                                                            onClick={handleOnDelete(_id)}
                                                            title={'Elimina elemento'}
                                                            disabled={index === 0}
                                                        >
                                                            <Trash3Fill />
                                                        </Button>

                                                    </td>
                                                </tr>
                                            )

                                        })

                                    ) : (

                                        <tr><td colSpan={6} style={{fontStyle : 'italic'}}>Nessun piano visite impostato.</td></tr>

                                    )

                                }
                            </tbody>

                        </Table>

                    </Card.Body>
                </Card>

            </InterfaceModal>

        </>
        
    )

}