import { FloatingLabel, Form, InputGroup } from "react-bootstrap";

import FormInputError from "./FormInputError";
import { useEffect, useState } from "react";

export default function FormFloatingAmountInput(props) {

    const { autoFocus, label, name, onChange, value, valid, readOnly, actionButton, disabled } = props;

    const [amount, setAmount] = useState('');

    const handleAmount = (e) => {

        const { value } = e.target;

        if (/^(\d{1,4}([.|,]\d{0,2})?$)?$/s.test(value) === false) {

            return;

        }

        setAmount( value );

        onChange({ target: { name, value } });

        return value;

    }

    function formatAmount(e) {

        const { value } = e.target;

        const formattedValue = value && !isNaN(value) ? Number(value.replace(',', '.')).toFixed(2) : null;

        setAmount( formattedValue );

        onChange({ target: { name, value: formattedValue } });

    }

    useEffect(()=> {

        setAmount(value[name]);

    }, [setAmount, value, name]);

    return (

        <div className='mb-3'>

            <InputGroup>
                <InputGroup.Text>&euro;</InputGroup.Text>                                

                <FloatingLabel 
                    label={label}
                >
                    <Form.Control
                        autoFocus={autoFocus}
                        name={name}
                        onChange={handleAmount}
                        onBlur={formatAmount}
                        value={amount}
                        placeholder={label}
                        readOnly={readOnly}
                        disabled={disabled}
                    />
                </FloatingLabel>

                { actionButton }

            </InputGroup>

            <FormInputError show={valid?.[name]?.length > 0} caption={valid?.[name]} />

        </div>

    )

}