import AppointmentWidget from "../components/AppointmentWidget";
import SummaryWidget from "../components/SummaryWidget";

export default function Dashboard(props) {

    return (
        <>
        
            <AppointmentWidget />
            <SummaryWidget />

        </>
    )
    
}