import './AlertBadge.css';

import { Badge } from "react-bootstrap";
import { ExclamationLg } from "react-bootstrap-icons";

export default function AlertBadge (props) {

    const { show, margin = 'e', className } = props;

    return (

        show &&
            <Badge as='div' className={`alert-badge m${margin}-1 p-0 ${className}`} bg='warning' text='dark'><ExclamationLg /></Badge>

    )

}