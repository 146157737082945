import { Button } from "react-bootstrap";
import { Trash3Fill } from "react-bootstrap-icons";

export default function ButtonClearValue(props) {

    const { title, disabled, className, onClick } = props;

    return (

        <Button
            disabled={disabled}
            className={`${className} icon-button`}
            variant='danger'
            onClick={onClick}
            title={title || 'Elimina elemento'}
        >
            <Trash3Fill />
        </Button>


    )

}