import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userState, infoWarningState } from "../atoms";
import Ajax from "../components/Ajax";
import moment from "moment";
import useToken from "./useToken";
import { getLastAccessData } from "./useAccess";
import { getAppNamespace } from "./useApp";

const bcrypt = require('bcryptjs');

export default function usePassword(isOpen, toggleModal) {

    const [loading, isLoading] = useState(false);

    const [data, setData] = useState({});
    const [valid, setValidity] = useState({});    

    const [isAlertOpen, setIsAlertOpen ] = useState(false);

    const [token, setRecoilToken] = useRecoilState(userState);
    const [infoState, setInfoState] = useRecoilState(infoWarningState);
    
    const {setToken} = useToken();

    useEffect(() => {

        if (!isOpen) return;

        const retrieveUser = async() => {

            return await Ajax({
                route : `utenti/${token._id}`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveUser()
        .then(data => {

            if (data.dataScadenzaPassword) {

                data.dataScadenzaPassword = moment(data.dataScadenzaPassword).format('ddd DD/MM/YYYY HH:mm');

            }

            data.ultimoAccesso = getLastAccessData(data);

            setData({
                ...data,
                visualizzaAvvisi : infoState,
                vecchiaPassword : ''
            });

        })
        .catch(err => {
    
        });
    
    }, [isOpen]);

    const handleOnChange = e => {

        const { name, checked, value, type } = e.target;

        setData({
            ...data,
            [name] : type === 'checkbox' ? checked : value
        });

    }

    const retrieveOldPassword = async() => {

        const retrievePassword = async () => {

            return await Ajax({
                route: `utenti/${token._id}?password=1`,
                metodo: 'get'
            })
            .catch(err => {

            });

        }
        
        const passwordResult = await retrievePassword();
          
        return passwordResult.password;

    }

    const isFormDataValid = async() => {

        const errors = {};

        if (toggleModal || data.password || data.conferma) {

            const password = await retrieveOldPassword();

            if (await bcrypt.compare(data.vecchiaPassword, password) === false) {
                errors.vecchiaPassword = 'Password non corrispondente'
            }

            if (data.password === data.vecchiaPassword) {
                errors.password = 'La password nuova non può essere uguale a quella vecchia';
            }

            if (/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/.test(data.password) === false) {
                errors.password = 'Password non valida';
            }

            if (data.password !== data.conferma) {
                errors.conferma = 'Le password non corrispondono';
            }

        }

        setValidity(errors);

        return Object.keys(errors).length === 0;

    }

    const handleSubmit = async e => {

        const valid = await isFormDataValid();

        setIsAlertOpen(false);

        if (!valid) {

            return;

        }

        const updateUser = async() => {

            isLoading(true);

            const updateData = { ...data };

            delete updateData.conferma;
            delete updateData.vecchiaPassword;
            delete updateData.dataScadenzaPassword;

            setInfoState(data.visualizzaAvvisi);

            localStorage.setItem(`${getAppNamespace}.avvisi`, data.visualizzaAvvisi);

            return await Ajax({
                route : `utenti/${data._id}/?profilo=1`, 
                parametri : { data : updateData },
                metodo : 'post',
            })
            .catch(err => {

            });
    
        }

        updateUser()
        .then(() => {

            isLoading(false);

            setIsAlertOpen(true);

            const { disabilitaNotifiche } = data;

            const updatedToken = {
                ...token,
                disabilitaNotifiche
            }

            setRecoilToken(updatedToken);
            setToken(updatedToken);

            if (toggleModal) toggleModal(false);

        })
        .catch(err => {

        });

    }

    return {

        token,
        data,
        setData,

        valid,
        isAlertOpen,
        loading,

        handleOnChange,
        handleSubmit

    }

}