import { Button, Modal } from "react-bootstrap"
import { XLg } from "react-bootstrap-icons"
import { useRecoilValue } from "recoil"
import { openedModalsState } from "../atoms"

export default function AlertModal(props) {

    const {show, body, onClose, showButton = true, onClick} = props

    const openedModals = useRecoilValue(openedModalsState);

    return (

        <Modal 
            show={show}
            onHide={onClose}
            backdrop={openedModals.length === 0}
            centered
        >

            <Modal.Body>
                <h5>Attenzione!</h5>
                {
                    body.split('\n').map((line, index, array) => (

                        index === array.length - 1 ? line : <>{line}<br /></>

                    ))
                    
                }
            </Modal.Body>

            <Modal.Footer>

                {
                    showButton && 

                        <Button 
                            variant='danger'
                            onClick={onClick}
                        >
                            Elimina
                        </Button>
                }

                <Button
                    className='icon-button' 
                    onClick={() => {onClose()}} 
                    variant='light'
                    title='Chiudi modale'
                >
                    <XLg />
                </Button>

            </Modal.Footer>

        </Modal>

    )
}