import { useEffect, useState } from 'react';

import Ajax from './Ajax';

import { Alert, Button } from 'react-bootstrap';
import { Calendar2Week, InfoCircle } from 'react-bootstrap-icons';
import FormFloatingInput from './FormFloatingInput';
import RightModalFooterNav from './RightModalFooterNav';
import InterfaceModal from './InterfaceModal';
import { usePermissions } from '../hooks/usePermissions';
import AppointmentSummaryModal from './AppointmentSummaryModal';
import AlertBadge from './AlertBadge';
import ButtonAppointmentSchedule from './ButtonAppointmentSchedule';
import useCalendar from '../hooks/useCalendar';
import ButtonPatientForm from './ButtonPatientForm';
import FormFloatingTextarea from './FormFloatingTextarea';
import ButtonDeleteItem from './ButtonDeleteItem';
import { useRecoilState, useRecoilValue } from 'recoil';
import { autoUpdateState, deleteItemState } from '../atoms';
import FormFloatingSearchSelectInput from './FormFloatingSearchSelectInput';
import FormSpinnerButton from './FormSpinnerButton';

export default function PatientModal(props) {

    const [ autoUpdate, setAutoUpdate ] = useRecoilState(autoUpdateState);

    const { isPatientFormAccessible, isCalendarEditable, userFilter } = usePermissions();

    const { data = {}, handleOnChange, isOpen, toggleModal, list, setUpdateList, setNewPatientID = {}, useAutoUpdate = false } = props;

    const [loading, isLoading] = useState(false);

    const [valid, setValidity] = useState({});

    const [isUpdated, setIsUpdated ] = useState(false);
    const [isDeleted, setIsDeleted ] = useState(false);

    const [isSummaryOpen, toggleSummaryModal ] = useState(false);
    const [isScheduleOpen, toggleScheduleModal ] = useState(false);

    const [selectedDoctor, setSelectedDoctor] = useState('');

    const [isDoctorAlertOpen, toggleDoctorAlertOpen] = useState(false);
    const [deletedDoctor, setDeletedDoctor] = useState({});

    const itemPendingDeletion = useRecoilValue(deleteItemState);

    useEffect(() => {

        setValidity({});

        isLoading(false);

        setIsUpdated(false);
        setIsDeleted(false);

        toggleDoctorAlertOpen(false);
        setDeletedDoctor({});
        setSelectedDoctor(data.medico);

    }, [isOpen]);

    useEffect(() => {

        const doctor = (data.medico?.split(';')) ?? [];
        const selected = (selectedDoctor?.split(';')) ?? [];

        const missing = selected.find(item => !doctor.includes(item));

        const toggle = doctor.length > 0 && selected.length > 0 && missing;

        toggleDoctorAlertOpen(toggle);
        setDeletedDoctor(toggle ? doctors.find(item => item._id === missing) : {});

    }, [selectedDoctor, data.medico])

    const { 

        data : appointments,
        doctors,

    } = useCalendar({ autoUpdate, setAutoUpdate, loadDatastore: true });

    function validateFiscalCode(fiscalCode) {

        const code = fiscalCode.toUpperCase();

        if (/(^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$)?/i.test(code) === false) {

            return false;

        }
        
        const set1 = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const set2 = 'ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const even = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const odd = 'BAKPLCQDREVOSFTGUHMINJWZYX';

        let s = 0;

        for( let i = 1; i <= 13; i += 2 ) {
            s += even.indexOf( set2.charAt( set1.indexOf( code.charAt(i) )));
        }

        for( let i = 0; i <= 14; i += 2 ) {
            s += odd.indexOf( set2.charAt( set1.indexOf( code.charAt(i) )));
        }

        return (s%26 !== code.charCodeAt(15)-'A'.charCodeAt(0)) === false;

    }

    const isFormDataValid = () => {

        const errors = {};

        if (/^[A-Z' àèìòù]{2,}$/i.test(data.cognome ?? '') === false) {
            errors.cognome = 'Cognome non valido';
        }

        if (/^[A-Z' àèìòù]{2,}$/i.test(data.nome ?? '') === false) {
            errors.nome = 'Nome non valido';
        }

        if (data.cognome && data.nome) {

            if (
                list.filter(item => (
                    String(item.cognome ?? '').trim().toLowerCase() === String(data.cognome || '').trim().toLowerCase() && 
                    String(item.nome ?? '').trim().toLowerCase() === String(data.nome || '').trim().toLowerCase() && 
                    String(item.codiceFiscale ?? '').trim().toLowerCase() === String(data.codiceFiscale || '').trim().toLowerCase() && 
                    item._id !== data._id
                )).length > 0
            ) {

                errors.cognome = 'Paziente già presente in archivio'

            }

        }

        if (data.codiceFiscale && validateFiscalCode(data.codiceFiscale) === false) {
            errors.codiceFiscale = 'Codice Fiscale non valido'
        }

        if (data.codiceFiscale && list.filter(item => item.codiceFiscale?.toLowerCase() === data.codiceFiscale?.toLowerCase() && item._id !== data._id).length > 0) {
            errors.codiceFiscale = 'Codice Fiscale già presente in archivio';
        }

        if (data.telefono && (/^(?:\+39)?(?:\s*(?:0|\(?\d+\)?)(?:\s*(?:\d[.\s]*){8,11}))$/).test(data.telefono) === false) {
            errors.telefono = 'Numero di telefono non valido'
        }

        if (data.email && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email ?? '') === false) {
            errors.email = 'Email non valida';
        }

        setValidity(errors);

        return Object.keys(errors).length === 0;

    }

    const handleSubmit = e => {

        if (!isFormDataValid()) {

            return;

        }

        isLoading(true);

        const updatePatient = async() => {

            return await Ajax({
                route : `pazienti/${data._id ?? ''}`, 
                parametri : { data },
                metodo : data._id ? 'post' : 'put',
            })
            .catch(err => {

            });
    
        }

        updatePatient()
        .then((res) => {

            isLoading(false);

            setIsUpdated(true);

            setUpdateList(new Date());
            setAutoUpdate(useAutoUpdate);

            if (!data._id) {

                const { insertedId : _id } = res;

                handleOnChange({ target : { name :'_id', value : _id }});

                setNewPatientID(_id);

            }

        })
        .catch(err => {

        });

    }

    const handleOnDelete = () => {

        const deletePatient = async () => {

            return await Ajax({
                route : `pazienti/${data._id}`, 
                parametri : { data : { data } },
                metodo : 'delete',
            })
            .catch(err => {

            })    
        }

        deletePatient()
        .then((res) => {

            setIsDeleted(true);
            setUpdateList(new Date());

            toggleModal(false);

        });

    }

    return (

        <>
        <InterfaceModal
            show={isOpen}
            onHide={toggleModal}
            isAlertOpen={isUpdated}
            isBlurred={isSummaryOpen || isScheduleOpen || itemPendingDeletion}
            header={
                
                <>{data._id ? 'Dettaglio' : 'Aggiungi'} Paziente</>

            }
            footer={

                <>
                    <RightModalFooterNav>

                        {
                            isCalendarEditable &&

                                <ButtonDeleteItem
                                    disabled={!data._id}
                                    className='me-2'
                                    body={`Eliminare il Paziente e tutti i dati associati?\nL'eliminazione è irreversibile e comporta una perdita di dati.`}
                                    onClick={handleOnDelete}
                                />

                        }

                        <Button 
                            variant='secondary'
                            disabled={!data._id}
                            className='m-0 icon-button'
                            title='Visualizza appuntamenti'
                            onClick={e => toggleSummaryModal(true)}
                        >
                            <Calendar2Week />
                        </Button>

                        {
                            data.daSaldare > 0 &&
                                <AlertBadge show={true} margin='s' className='big' />
                        }

                        {
                            isCalendarEditable &&
                                <ButtonAppointmentSchedule
                                    variant='secondary'
                                    disabled={!data._id}
                                    action={false}
                                    isOpen={isScheduleOpen}
                                    toggleModal={toggleScheduleModal}
                                    appointment={data}
                                    doctors={doctors}
                                    subject={data}
                                    list={appointments}
                                />
                        }

                        {
                            isPatientFormAccessible &&
                                <ButtonPatientForm
                                    variant='dark'
                                    action={false}
                                    isOpen={isScheduleOpen}
                                    toggleModal={toggleScheduleModal}
                                    subject={data}
                                />

                        }

                    </RightModalFooterNav>

                    {
                        isCalendarEditable &&

                            <FormSpinnerButton 
                                caption='Salva' 
                                title='Salva dati' 
                                loading={loading} 
                                onClick={handleSubmit} 
                                error={Object.keys(valid).length > 0} 
                            />

                    }

                </>

            }
        >

            <FormFloatingInput
                label='Cognome'
                name='cognome'
                onChange={handleOnChange}
                value={data}
                valid={valid}
                readOnly={isCalendarEditable === false}
            />

            <FormFloatingInput
                label='Nome'
                name='nome'
                onChange={handleOnChange}
                value={data}
                valid={valid}
                readOnly={isCalendarEditable === false}
            />

            <FormFloatingInput
                label='Codice Fiscale'
                name='codiceFiscale'
                onChange={handleOnChange}
                value={data}
                valid={valid}
                readOnly={isCalendarEditable === false}
            />

            <FormFloatingInput
                label='Telefono'
                name='telefono'
                onChange={handleOnChange}
                value={data}
                valid={valid}
                readOnly={isCalendarEditable === false}
            />

            <FormFloatingInput
                className='mt-3'
                label='Email'
                name='email'
                onChange={handleOnChange}
                value={data}            
                valid={valid}
                readOnly={isCalendarEditable === false}
            />

            <FormFloatingSearchSelectInput
                className='mb-3'
                label='Medico'
                name='medico'
                data={
                    doctors
                    .filter(item => userFilter.length > 0 ? userFilter.includes(item._id) : true)
                    .sort((a, b) => a.cognome.localeCompare(b.cognome))                    
                }
                homonyms={[]}
                onChange={handleOnChange}
                value={data}
                valid={valid}
                style={{
                    textOverflow: 'ellipsis',
                }}
                updateList={list}
                upward
                multiple
            />

            {
                isDoctorAlertOpen && 
                    Object.keys(deletedDoctor).length > 0 &&

                        <Alert className='d-flex' variant='warning'>

                            <div>
                                <InfoCircle size={20} className='me-2' /> 
                            </div>

                            <div className='d-flex flex-column'>
                                <b>Attenzione!</b>

                                <p className='mb-0'>
                                    La rimozione del medico <b>{deletedDoctor.cognome}, {deletedDoctor.nome}</b> dall'elenco comporta la perdita della visualizzazione delle informazioni relative al paziente nella scheda di dettaglio delle viste <b>già registrate in archivio</b> per quel medico.
                                </p>
                                <hr />
                                <p className='mb-0'>
                                    Per ulteriori informazioni contattare il servizio tecnico. Grazie.
                                </p>
                            </div>

                        </Alert>

            }

            <FormFloatingTextarea
                label='Note'
                name='note'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

        </InterfaceModal>

        <AppointmentSummaryModal
            subject={data}
            isOpen={isSummaryOpen}
            toggleModal={toggleSummaryModal}
        />

        </>        

    )

}