import { Container, Spinner } from 'react-bootstrap';

export default function PageSpinner ({ style }) {

    const container = {
        position: 'absolute',
        top : '50%',
        left : '50%',
        transform : 'translate(-50%, -50%)',
        ...style
    }

    const spinner = {
        width : '2rem',
        height : '2rem',
    }

    return (

        <Container className='page-spinner text-center' style={container}>
            <Spinner animation='border' role='status' style={spinner}>
                <span className='visually-hidden'>Loading...</span>
            </Spinner> 
        </Container>

    );

}
