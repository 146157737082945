import DashboardWidget from "./CardWidget";

export default function SummaryWidget(props) {
 
    return (

        <DashboardWidget
            header='Resoconto'
        >

        </DashboardWidget>
    )
    
}