import { FloatingLabel, Form, InputGroup } from "react-bootstrap";

import FormInputError from "./FormInputError";
import { forwardRef } from "react";

const FormFloatingInput = forwardRef((props, ref) => {

    const { autoFocus, label, name, onChange, onKeyUp, value, valid, readOnly, actionButton, type = 'text' } = props;

    return (

        <div className='mb-3'>

            <InputGroup>
            
                <FloatingLabel 
                    label={label}
                >
                    <Form.Control
                        ref={ref}
                        autoFocus={autoFocus}
                        name={name}
                        onChange={onChange}
                        onKeyUp={onKeyUp}
                        value={value[name] ?? ''}
                        placeholder={label}
                        readOnly={readOnly}
                        type={type}
                    />
                </FloatingLabel>

                { actionButton }

            </InputGroup>
            
            <FormInputError show={valid?.[name]?.length > 0} caption={valid?.[name]} />

        </div>

    )

});

export default FormFloatingInput;