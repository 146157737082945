import { useEffect, useState } from "react";
import Ajax from "../components/Ajax";

export default function useNotes({ 

    context,
    updateList = null,

}) {

    const [data, setData] = useState([]);

    const [loading, isLoading] = useState(false);

    useEffect(() => {

        isLoading(false);

    }, []);

    useEffect(() => {

        if (!context || !updateList) return;

        const retrieveNotes = async() => {

            return await Ajax({
                route : context, 
                metodo : 'get'
            });
    
        }

        retrieveNotes()
        .then(res => {

            setData(res);

        });

    
    }, [context, updateList]);

    const handleOnClick = (data) => (e) => {

        isLoading(true);

        Ajax({
            route: context,
            parametri: { data },
            metodo: 'post',
        }).then(res => {

            isLoading(false);

        });

    }

    return {

        notes : data,
        loading,

        handleOnClick

    }
    
}
