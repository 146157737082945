import { useState, useEffect } from 'react';
import Ajax from '../components/Ajax';

export default function useSession(token) {

    const [session, setSession] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {

        const verificaSessione = async () => {

            setIsLoading(true);

            const sessione = await Ajax({
                route: 'sessione',
                metodo: 'get',
            })
            .catch(err => {

            });

            setSession(sessione?.valido === true);

            setIsLoading(false);

        };

        verificaSessione();

    }, [token]);

    return {
        session,
        isLoading
    };
    
}