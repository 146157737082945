import './SelectUpcomingAvailability.css';

import { useEffect, useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { Calendar2Check, ExclamationCircle } from 'react-bootstrap-icons';
import Ajax from './Ajax';
import moment from 'moment';

export default function SelectUpcomingAvailability(props) {

    const { subject, name, onChange, disabled, noRadius } = props;

    const [show, setShow] = useState(false);

    const [loading, isLoading] = useState(false);
    const [availability, setAvailability] = useState([]);

    useEffect(() => {

        setShow(false);

    }, [subject]);

    const getUpcomingAvaliability = async() => {

        const retrieveAvailability = async() => {

            isLoading(true);

            return await Ajax({
                route : `medici/${subject}/disponibilita`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        setAvailability(await retrieveAvailability());

        isLoading(false);

    }

    const handleOnSelect = (value) => {

        onChange({ target : { name , value }});

    }

    const handleOnToggle = (show) => {

        setShow(show);

        if (show) {

            getUpcomingAvaliability();

        }

    }

    const createAvailabilityItems = () => {

        if (availability.length === 0) {

            return <Dropdown.Item className='pointer-events-none'><small><ExclamationCircle className='me-1' />Nessuna disponibilità nei prossimi 30 giorni</small></Dropdown.Item>

        }

        return availability.map(item => (

            <Dropdown.Item 
                key={item}
                eventKey={moment(item).format('YYYY-MM-DD HH:mm:ss')}
            >
                {moment(item).format('dddd DD MMM YYYY HH:mm')}
            </Dropdown.Item>

        ))

    }

    return (

        <DropdownButton 
            variant='light'
            title={<Calendar2Check title='Visualizza prossime disponibilità' />}
            className={`action-dropdown${noRadius ? ' no-radius' : ''}`}
            size='lg'
            disabled={disabled}
            onSelect={handleOnSelect}
            onToggle={handleOnToggle}
            show={show}
            align='end'
        >
            <Dropdown.Header>Prossime disponibilità:</Dropdown.Header>
            {
                loading ?

                (

                    <Dropdown.Item disabled><small>Recupero dati in corso...</small></Dropdown.Item>

                ) : (

                    createAvailabilityItems()

                )

            }
        </DropdownButton>

    )

}