import { Button } from "react-bootstrap";
import { FileEarmarkMedical } from "react-bootstrap-icons";
import InterfaceModal from "./InterfaceModal";

export default function ButtonPatientForm (props) {

    const { variant, action = true, subject, isOpen, toggleModal } = props

    return (

        <>

            <Button 
                variant={variant ?? 'light'}
                className={action ? 'action-button p-2 d-flex align-items-center' : 'ms-2 icon-button'}
                size={action ? 'lg' : ''}
                title={`Visualizza scheda`}
                onClick={e => toggleModal(true)}
            >
                <FileEarmarkMedical />
            </Button>

            <InterfaceModal
                show={isOpen}
                onHide={toggleModal}
                isAlertOpen={false}
                header={

                    <>
                        {subject?.cognome}, {subject?.nome}
                    </>

                }
                footer= {

                    <>
                        
                    </>
    
                }
    
            >

            </InterfaceModal>

        </>
        
    )

}