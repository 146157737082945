import { useEffect, useRef, useState } from "react";
import CardList from "../../components/CardList";
import Ajax from "../../components/Ajax";
import { Table } from "react-bootstrap";
import BackupModal from "../../components/BackupModal";

export default function Backup (props) {

    const didMount = useRef(false);

    const [loading, isLoading] = useState(true);

    const [updateList, setUpdateList] = useState(false);

    const [data, setData] = useState([]);
    const [backupData, setBackupData] = useState({});

    const [isOpen, toggleModal] = useState(false);

    useEffect(() => {

        if (didMount.current) return;

        const retrieveBackup = async() => {

            return await Ajax({
                route : 'backup', 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveBackup()
        .then(data => {

            isLoading(false);

            setData(data);

        });
    
    }, [updateList]);

    const getBackupData = id => e => {

        const retrieveBackup = async(id) => {

            if (!id) {

                return Promise.resolve({});

            }

            return await Ajax({
                route : `backup/${id}`, 
                metodo : 'get'
            })
            .catch(err => {

            });
    
        }

        retrieveBackup(id)
        .then(data => {

            setBackupData(data);
            toggleModal(true);

        })
        .catch(err => {
 
        });

    }

    const handleCreateBackup = e => {

        const createBackup = async() => {

            return await Ajax({
                route : `backup`, 
                metodo : 'put'
            })
            .catch(err => {

            });

        }

        createBackup()
        .then(data => {

            setUpdateList(new Date());

        });
        
    }

    return (

        <CardList
            loading={loading}
            header='Backup'
            onClick={handleCreateBackup}
            setUpdateList={setUpdateList}
             detailModal={

                 <BackupModal
                     data={backupData}
                     isOpen={isOpen}
                     toggleModal={toggleModal}
                 />

            }
        >

            <Table striped borderless hover className='m-0 tabella-backup'>
                <thead>
                    <tr>
                        <th>File</th>
                        <th>Hash</th>
                        <th>Dim.</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        data.length > 0 ?

                        (

                            data.map(record => {
                                
                                return (

                                    <tr key={record.hash} onClick={getBackupData(record.nome)}>
                                        <td>{record.nome}</td>
                                        <td>{record.hash}</td>
                                        <td>{Number(record.dimensione).toFixed(2)} KB</td>
                                    </tr>

                                );

                            })

                        ) : (

                            <tr><td colSpan={3} style={{fontStyle : 'italic'}}>Nessun backup disponibile.</td></tr>

                        )

                    }

                </tbody>
            </Table>

        </CardList>

    )

}