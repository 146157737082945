
const calculateContrast = (color1, color2) => {

    const getLuminance = (color) => {
    
        if (!color) return 0;

        const rgb = parseInt(color.slice(1), 16);
        
        const r = (rgb >> 16) & 0xff;
        const g = (rgb >>  8) & 0xff;
        const b = (rgb >>  0) & 0xff;
        
        return (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    }
        
    const luminance1 = getLuminance(color1);
    const luminance2 = getLuminance(color2);
    
    const contrastRatio = (Math.max(luminance1, luminance2) + 0.05) / (Math.min(luminance1, luminance2) + 0.05);
    
    return contrastRatio;

}

export { calculateContrast }
