import './Login.css';

import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import logo from '../img/logo-d.png';

import 'bootstrap-icons/font/bootstrap-icons.css';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row';

import FormPasswordInput from '../components/FormPasswordInput';
import Ajax from '../components/Ajax';

import { getAppNamespace } from '../hooks/useApp';
import FormFloatingInput from '../components/FormFloatingInput';
import PageFooter from '../components/PageFooter';
import FormSpinnerButton from '../components/FormSpinnerButton';

export default function Login(props) {

    const { setToken } = props;

    const userRef = useRef(null);
    const passwordRef = useRef(null);

    const [loading, isLoading] = useState(false);

    const [data, setData] = useState({

        email : localStorage.getItem(`${getAppNamespace}.email`),
        password : '',
        ricordami : false,

    });

    const [ isAuthenticated, setIsAuthenticated ] = useState(true);

    const [valid, setValidity] = useState({});    

    const handleOnChange = (e) => {

        const { name, checked, value, type } = e.target;

        setData({
            ...data,
            [name] : type === 'checkbox' ? checked : value
        });

    }

    const isFormDataValid = () => {

        setIsAuthenticated(true);

        const errors = {};

        if (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(data.email) === false) {
            errors.email = 'Email non valida';
        }

        if (/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{8,}$/.test(data.password) === false) {
            errors.password ='Password non valida';
        }

        setValidity(errors);

        return Object.keys(errors).length === 0;

    }

    const handleSubmit = async (e) => {

        if (!isFormDataValid()) {

            return;

        }

        const { email, password, ricordami } = data;

        isLoading(true);

        try {

            const { token } = await Ajax({
                route: 'login',
                parametri: { email, password, ricordami },
            });

            setIsAuthenticated(undefined !== token);

            setToken(token);

            localStorage.setItem(`${getAppNamespace}.email`, data.email);

        } catch (err) {

            setIsAuthenticated(false);

        } finally {

            isLoading(false);

        }

    }

    const handleOnKeyUp = (e) => {

        const { key, target : { name } } = e;
        
        if (key !== 'Enter') return;

        if (name === 'email') {

            passwordRef.current.focus();

        }

        if (name === 'password') {

            handleSubmit();

        }

    }

    return (

        <>
        <Card 
            body
            className='login'
        >
            
            <Image className='logo' src={logo} />

            <Form>
                <Form.Group>            

                    <FormFloatingInput
                        ref={userRef}
                        autoFocus
                        label='Email'
                        name='email'
                        onChange={handleOnChange}
                        onKeyUp={handleOnKeyUp}
                        value={data}
                        valid={valid}
                    />

                    <FormPasswordInput
                        ref={passwordRef}
                        label='Password'
                        name='password'
                        onChange={handleOnChange}
                        onKeyUp={handleOnKeyUp}
                        value={data}
                        valid={valid}
                        autoFocus={data.email}
                    />

                    <Alert variant='danger' className='p-1 ps-2' style={{ marginTop: '-.75rem' }} transition={false} show={!isAuthenticated}>
                        Utente o password errati
                    </Alert>

                </Form.Group>

                <Row>
                    <Form.Group as={Col} md='6' className='mb-2 ricordami'>
                        <Form.Check
                            type='switch'
                            id='ricordami'
                            name='ricordami' 
                            label='Ricordami'
                            onChange={handleOnChange}
                        />
                    </Form.Group>
                    <Form.Group as={Col} md='6' className='accedi'>

                        <FormSpinnerButton 
                            caption='Login' 
                            loading={loading} 
                            onClick={handleSubmit} 
                        />

                    </Form.Group>
                </Row>
            </Form>

        </Card>
        <PageFooter hidePrivacyPolicy /> 
        </>
    )

}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
};
