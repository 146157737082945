import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Trash3Fill } from 'react-bootstrap-icons';
import { useSetRecoilState } from 'recoil';
import { deleteItemState } from '../atoms';
import AlertModal from './AlertModal';

export default function ButtonDeleteItem(props) {

    const { body, disabled, className, onClick } = props;

    const setDeleteItemState = useSetRecoilState(deleteItemState);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const handleOnClick = () => {

        setShow(false);

        setTimeout(() => {

            onClick();

        }, 100);

    }

    useEffect(() => {

        setDeleteItemState(show);

    }, [show])

    return (

        <>
            <Button
                disabled={disabled}
                className={`${className} icon-button`}
                variant='danger'
                onClick={() => handleShow()}
                title={'Elimina elemento'}
            >
                <Trash3Fill />
            </Button>

            <AlertModal
                show={show}
                body={body}
                onClose={handleClose}
                onClick={handleOnClick}
            />

        </>

    )

}
