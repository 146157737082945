import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Calendar.css';

import React, { useState, useCallback, useRef, useEffect } from 'react';

import moment from 'moment';
import 'moment/locale/it';

import { Button, ButtonGroup, Dropdown, DropdownButton, Form } from 'react-bootstrap';

import { Calendar, momentLocalizer } from 'react-big-calendar';

import PathBreadcrumb from '../components/PathBreadcrumb';
import AppointmentModal from '../components/AppointmentModal';

import CircularLabel from '../components/CircularLabel';
import CalendarNavbar from '../components/CalendarNavbar';
import useCalendar from '../hooks/useCalendar';
import { Check, DashCircle, List, PlusCircle, ZoomIn } from 'react-bootstrap-icons';
import AlertModal from '../components/AlertModal';
import Ajax from '../components/Ajax';
import { getAppNamespace } from '../hooks/useApp';
import PageSpinner from '../components/PageSpinner';
import CalendarNoteBar from '../components/CalendarNoteSidebar';

import { autoUpdateState } from '../atoms';
import { useRecoilState } from 'recoil';
import useNotes from '../hooks/useNotes';
import useWindowSize from '../hooks/useWindowSize';
import NoteTextarea from '../components/NoteTextarea';

export default function Calendario() {

    const [updateList, setUpdateList] = useState(false);

    const [ isOpen, toggleModal ] = useState(false);

    const initialShowDayNotes = JSON.parse(localStorage.getItem(`${getAppNamespace}.note`) || true);

    const [ showDayNotes, setShowDayNotes ] = useState(initialShowDayNotes);

    const height = window.innerHeight - 170;

    const [ autoUpdate, setAutoUpdate ] = useRecoilState(autoUpdateState);
    const [ lastUpdate, setLastUpdate ] = useState();

    const { width } = useWindowSize();

    const isMobile = width <= 768;

    const [view, setView] = useState(isMobile ? 'day' : 'work_week');
    const [date, setDate] = useState(new Date());

    const { 

        views,

        isCalendarEditable,
        isCalendarFilterable,
        
        minTime,
        maxTime,

        loading,
        data,
        doctors,
        planner,

        filterEvents,
        filters,
        
        setFilters,
        
        selectedSlot,
        setSelectedSlot,

        event,
        eventWrapper,

        formatDateHeader,

        appointmentData,
        getAppointmentData,
        handleOnChange,

    } = useCalendar({ updateList, toggleModal, loadDatastore : true, autoUpdate, setAutoUpdate });

    const context = {'work_week' : 'note', 'day' : 'note', 'agenda' : 'memo'}[view];

    const {

        notes,

    } = useNotes({ context,  updateList : date });

    const [range, setRange] = useState({});
    const [localeView, setLocaleView] = useState(isMobile ? 'Giorno' : 'Settimana');

    const defaultTimeslots = Number(localStorage.getItem(`${getAppNamespace}.visite.timeslots`) || 2);

    const [timeslots, setTimeslots] = useState(defaultTimeslots);

    const [ filteredDoctorAvailability, setFilteredDoctorAvailability ] = useState([]);

    const clickRef = useRef(null);

    const [ slotSelectError, setSlotSelectError ] = useState('');

    const handleClose = () => setSlotSelectError('');

    useEffect(() => {

        setSelectedSlot({});

    }, []);

    useEffect(() => {

        if (Object.keys(selectedSlot).length === 0) {
            return;
        }

        getAppointmentData()();

    }, [selectedSlot])

    useEffect(() => {

        if (!autoUpdate) return;

        const now = new Date();

        setUpdateList(now);
        setLastUpdate(now);

    }, [autoUpdate]);

    useEffect(() => {

        localStorage.setItem(`${getAppNamespace}.note`, showDayNotes);

    }, [showDayNotes]);

    useEffect(() => {

        if (view !== 'agenda') return;

        const findFirstAgendaDate = () => {

            const futureEvents = data.filter(event => new Date(event.start) >= new Date(date));

            futureEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

            return futureEvents.length > 0 ? futureEvents[0].start : null;

        };
        
        const firstDate = findFirstAgendaDate();

        setDate(firstDate);

    }, [view])

    const handleOnSelectFilter = (filter) => {

        if (!filter) {

            setFilters([]);

            return;

        }

        const index = filters.indexOf(filter);

        const newFilters = [...filters]

        if (index > -1) {
            
            newFilters.splice(index, 1);
            
        } else {

            newFilters.push(filter)

        }

        setFilters(newFilters);

    }

    const handleOnViewChange = view => {

        setView(view);
        setLocaleView(views[view]);

    };

    const handleOnNavigate = useCallback(date => {

        setDate(date);

    }, [setDate]);

    const handleOnRangeChange = useCallback(range => {

        setRange(range);

    }, []);

    const handleOnSelectEvent = useCallback(({ _id }) => {

        window.clearTimeout(clickRef?.current);
        
        clickRef.current = window.setTimeout(() => {

            getAppointmentData(_id)();

        }, 250);

    }, [getAppointmentData]);

    const handleOnSelectSlot = (slotInfo) => {

        if (slotInfo.action === 'click') return;

        const start = moment(slotInfo.start);
        const end = moment(slotInfo.end);
        const duration = Math.ceil(moment.duration(end.diff(start)).asMinutes());

        if (duration > 60) {
            setSlotSelectError('La selezione di slot superiori a 60 minuti non è permessa.');
            return;
        }

        const overlappingEvents = filterEvents(data).filter(event => 
            start.isBefore(moment(event.end)) && 
            end.isAfter(moment(event.start))
        ).length;

        if (overlappingEvents) {
            setSlotSelectError('Lo slot selezionato non è disponibile.');
            return;
        }

        const dayOfWeek = start.format('ddd');

        const { businessHours, availability, unavailability } = filteredDoctorAvailability;

        const dailyAvailability = businessHours?.[dayOfWeek] || [];
    
        const startValidity = isTimeInSlots(start, dailyAvailability, availability, unavailability) < 3;
        const endValidity = isTimeInSlots(end, dailyAvailability, availability, unavailability, true) < 3;

        const isAvailable = startValidity && endValidity;

        if (!isAvailable) {

            setSlotSelectError('Lo slot selezionato non è disponibile.');
            return;
            
        }

        setSelectedSlot({
            data: start.format('YYYY-MM-DD HH:mm:ss'),
            durata : duration
        })

    };

    moment.locale('it');

    const localizer = momentLocalizer(moment);

    const toolbar = (loading) => (toolbar) => {

        const header = () => {

            const data = moment(date);
    
            const header = [];
    
            switch(view) {
    
                case 'work_week':

                    const start = moment(range[0]).format('YY-MM'); 
                    const end =  moment(range[4]).format('YY-MM');

                    const headerStart = formatDateHeader('', range[0], 'MMM '); 
                    const headerEnd =  formatDateHeader('', range[4], 'MMM ');
                                        
                    if (start === end) {

                        header.push(headerStart);

                    } else {

                        header.push(<div className='label-range'>{headerStart}-{headerEnd}</div>)

                    }

                    break;
    
                case 'day':

                    header.push(<><span className='label-day-of-week'>{moment(data).format('ddd').toUpperCase()}</span>{formatDateHeader('', data, 'DD MMM ')}</>);

                    break;
    
                default:

                    header.push(formatDateHeader('', data, 'MMM '));
    
            }

            if (filters.length === 1 && 
                    doctors.length > 0) {

                const { cognome, nome } = doctors.find(item => item._id === filters[0]);

                header.push(<div className='label-range-doctor'><b>{cognome.toUpperCase()}, {nome.toUpperCase()}</b></div>)

                document.title = `${cognome.toUpperCase()}, ${nome.toUpperCase()} :: Istituto di Psicopatologia :: Gestionale`;

            } else {

                document.title = 'Istituto di Psicopatologia :: Gestionale';

            }
    
            return typeof header === 'string' ? header.toUpperCase() : header;
    
        }
    
        const gotoNext = () => {

            toolbar.onNavigate('PREV');
    
        }    
    
        const gotoToday = () => {
    
            toolbar.onNavigate('TODAY');
    
        }    
    
        const gotoPrev = () => {

            toolbar.onNavigate('NEXT');
    
        }

        const handleExport = () => {

            Ajax({
                route : 'stampe',
                parametri : { params : { tipo : `calendario_${localeView.toLowerCase()}`, data : moment(date).format('YYYY-MM-DD') }, responseType: 'blob' },
                blob: true,
                metodo : 'get'
            })
            .then(data => {
    
                const link = document.createElement('a');
                const url = URL.createObjectURL(data);

                const file = `${getAppNamespace}-calendario_${localeView.toLowerCase()}-${moment().format('YYYYMMDD.HHmm')}.xlsx`;

                link.href = url;
                link.download = file;
                link.click();
    
                link.remove();
                URL.revokeObjectURL(url);
    
            });
    
        }

        const handleTimeslots = (e) => {

            const { value } = e.target;

            setTimeslots(value);

            localStorage.setItem(`${getAppNamespace}.visite.timeslots`, value);

        }

        return (

            <CalendarNavbar
                header={header()}
                gotoNext={gotoNext}
                gotoPrev={gotoPrev}
                gotoToday={gotoToday}
                timeslots={['work_week', 'day'].includes(view) ? timeslots : undefined}
                handleTimeslots={handleTimeslots}
                dropdownMenu={
                    
                    <>
                        { 
                            loading &&
                                <PageSpinner />
                        }
                        <DropdownButton 
                            size='sm'
                            className='vista-calendario me-1'
                            title={<div>{localeView}</div>}
                            align='end'
                            onSelect={handleOnViewChange}
                        >
                            <Dropdown.Item eventKey='month'>Mese</Dropdown.Item>
                            <Dropdown.Item eventKey='work_week'>Settimana</Dropdown.Item>
                            <Dropdown.Item eventKey='day'>Giorno</Dropdown.Item>
                            <Dropdown.Item eventKey='agenda'>Agenda</Dropdown.Item>
                        </DropdownButton>
                        {
                            ['work_week', 'day'].includes(view) &&
                                <Dropdown
                                    size='sm'
                                    align='end'
                                    as={ButtonGroup}
                                    autoClose='outside'
                                    title='Imposta grandezza slot'
                                    className='calendar-zoom'
                                >
                                    <Dropdown.Toggle variant='light'>
                                        <ZoomIn size={18} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <div
                                            className='calendar-range d-flex align-items-center justify-content-center'
                                        >
                                            <PlusCircle />
                                            <Form.Range 
                                                min={1}
                                                max={4}
                                                value={timeslots}
                                                onChange={handleTimeslots}
                                                style={{ width: 100 }} 
                                                className='mx-1'
                                                title='Zoom'
                                            />
                                            <DashCircle />
                                        </div>
                                    </Dropdown.Menu>                            
                                </Dropdown>
                        }
                        <Dropdown
                            size='sm'
                            align='end'
                            as={ButtonGroup}
                        >
                            <Dropdown.Toggle variant='light'>
                                <List size={22} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item disabled={['agenda'].includes(view)} onClick={handleExport}>Esporta</Dropdown.Item>
                                <Dropdown.Divider />
                                {
                                    (['day', 'agenda'].includes(view) || ('work_week' === view && filters.length === 1)) &&
                                    <>
                                        <Dropdown.Item 
                                            className='d-none d-md-block'
                                            onClick={() => setShowDayNotes(!showDayNotes)}
                                        >
                                            {
                                                view === 'agenda' ? 'Memo' : 'Note'
                                            }
                                            { 
                                                showDayNotes &&
                                                    <Check className='position-absolute' style={{ right: '1rem' }} size={26} />
                                            }
                                        </Dropdown.Item>
                                        <Dropdown.Divider 
                                            className='d-none d-md-block'
                                        />
                                    </>
                                }
                                <Dropdown.Item onClick={e => setUpdateList(new Date())}>Aggiorna</Dropdown.Item>
                            </Dropdown.Menu>                            
                        </Dropdown>
                    </>

                }
            />

        )

    }

    const monthHeader = ({ label, date }) => {

        return <>{label}</>;

    }

    const weekHeader = ({ date }) => {

        const momentDate = moment(date);

        const label = momentDate.format('DD ') + momentDate.format('ddd');
        const formattedDate = momentDate.format('YYYY-MM-DD');
        
        const [ doctor ] = filters;
        const data = notes.find(item => item.medico === doctor && item.data === formattedDate)?.note ?? ''

        return (
            <div onClick={(e) => e.stopPropagation()}>
                <b>{label}</b>
                {
                    showDayNotes && filters.length === 1 &&
                        <NoteTextarea 
                            context='note'
                            label='Note'
                            key={formattedDate}
                            doctor={doctor}
                            date={formattedDate}
                            name={formattedDate}
                            initialValue={data}
                        />

                }
            </div>
        );
        
    };

    const formatTime = range => {

        let {start} = range

        start = moment(start).format('HH:mm');

        return start;

    }

    useEffect(() => {

        if (filters.length !== 1) {
            
            setFilteredDoctorAvailability([]);

            return;

        }

        const doctor = doctors.find(item => item._id === filters[0])

        const { orariRicevimento, disponibilità, indisponibilità } = doctor ?? {};

        setFilteredDoctorAvailability({ 
            businessHours : orariRicevimento,
            availability : disponibilità,
            unavailability : indisponibilità 
        });

    }, [filters, doctors]);

    const isTimeInSlots = (slot, slots, availability = [], unavailability = [], inclusiveEnd = false) => {

        const inclusivity = inclusiveEnd ? '[]' : '[)';

        if (availability.some(item => {

            const start = moment(item.start).toDate();
            const end = moment(item.end).toDate();

            return slot.isBetween(start, end, undefined, inclusivity);            

        })) return 2;

        if (unavailability.some(item => {

            const start = moment(item.start);
            const end = moment(item.end);

            return slot.isBetween(start, end, undefined, inclusivity);            

        })) return 4;

        const timeToMinutes = (t) => {

            const [hours, minutes] = t.split(':').map(Number);
            return hours * 60 + minutes;

        }
    
        const timeInMinutes = timeToMinutes(slot.format('HH:mm'));
    
        for (const slot of slots) {

            const slotStart = timeToMinutes(slot.inizio);
            const slotEnd = timeToMinutes(slot.fine);
        
            if (timeInMinutes >= slotStart && (inclusiveEnd ? timeInMinutes <= slotEnd : timeInMinutes < slotEnd)) {
                return 1;
            }

        }

        return 3;

    }

    const slotPropGetter = useCallback((date) => {

        const dayOfWeek = moment(date).format('ddd');

        const { businessHours, availability, unavailability } = filteredDoctorAvailability;

        const dailyAvailability = businessHours?.[dayOfWeek] || [];

        const isAvailable = isTimeInSlots(moment(date), dailyAvailability, availability, unavailability);

        const classes = {
            1 : '',
            2 : 'slot-abilitato-da-planner',
            3 : 'slot-disabilitato',
            4 : 'slot-disabilitato-da-planner',
        }

        return ({
            className : classes[isAvailable]
        })

    }, [filteredDoctorAvailability]);

    const handleOnNewAppointmentClick = (e) => {

        setSelectedSlot({});
        
        getAppointmentData()(e);

    }

    return (

        <>

            <PathBreadcrumb />

            <div className='d-flex flex-column flex-md-row gap-1'>

                <Calendar
                    events={filterEvents(data)}
                    className={`${view} flex-grow-1`}
                    culture='it-IT'
                    localizer={localizer}
                    startAccessor='start'
                    endAccessor='end'
                    step={15}
                    timeslots={timeslots}                
                    style={{ height : height }}
                    components={{
                        toolbar : toolbar(loading),
                        event : event,
                        eventWrapper : eventWrapper,
                        agenda: {
                            event: event,
                        },
                        month: {
                            dateHeader: monthHeader,
                        },
                        work_week: {
                            header: weekHeader,
                        },
                    }}
                    formats={{ 
                        eventTimeRangeFormat: (() => {}),
                        agendaTimeRangeFormat: formatTime,
                    }}
                    messages={{
                        noEventsInRange: 'Non ci sono appuntamenti in questo intervallo.',
                        showMore: (total) => {
                            return `+${total} altr${total > 1 ? 'i' : 'o'}`;
                        },
                        date: 'Data',
                        time: 'Ora',
                        event: 'Paziente',
                    }}
                    views={['month', 'work_week', 'day', 'agenda']}
                    defaultView={'work_week'}
                    view={view}
                    date={date}
                    slotPropGetter={filters.length === 1 ? slotPropGetter : ''}
                    min={minTime}
                    max={maxTime}
                    onView={handleOnViewChange}
                    onNavigate={handleOnNavigate}
                    onRangeChange={handleOnRangeChange}
                    onSelectEvent={handleOnSelectEvent}
                    onSelectSlot={handleOnSelectSlot}
                    selectable={filters.length > 0 && view !== 'month'}
                    scrollToTime={moment().subtract(15, 'minutes').toDate()}
                    showAllEvents
                />

                {
                    ['day', 'agenda'].includes(view)  && 
                        showDayNotes &&
                            <CalendarNoteBar 
                                context={view === 'day' ? 'note' : 'memo'}
                                date={date}
                                filters={filters} 
                                doctors={doctors} 
                                notes={notes}
                                onClose={() => setShowDayNotes(false)}
                            />
                }

            </div>

            <div className='p-2' style={{height : '55px'}}>

            { 
                isCalendarEditable && 

                    <Button
                        className='d-inline float-start'
                        align='start'
                        onClick={handleOnNewAppointmentClick}
                    >
                        Nuova visita
                    </Button>
            }

            { 
                isCalendarFilterable && 

                    <DropdownButton
                        className='filtro-medici d-inline float-end'
                        align='start'
                        title='Filtra'
                        onSelect={handleOnSelectFilter}
                        variant={filters.length === 0 ? 'primary' : 'warning'}
                        autoClose='outside'
                    >
                        {

                            doctors
                            .sort((a, b) => a.cognome.localeCompare(b.cognome))
                            .map(item => { 
                                
                                const { _id, nome, cognome, coloreSfondo } = item;

                                const active = filters.includes(_id);

                                return (

                                    <Dropdown.Item 
                                        key={_id} 
                                        eventKey={_id} 
                                        active={active}
                                        className='d-flex align-items-center'
                                    >
                                        <CircularLabel
                                            className='me-1'
                                            bg={coloreSfondo} 
                                        />
                                        {`${cognome}, ${nome}`}
                                    </Dropdown.Item>

                                )

                            })

                        }
                        <Dropdown.Divider />
                        <Dropdown.Item eventKey=''>Rimuovi filtro</Dropdown.Item>
                    </DropdownButton>
            }

            </div>

            <AppointmentModal
                data={appointmentData}
                handleOnChange={handleOnChange}                   
                isOpen={isOpen}
                toggleModal={toggleModal}
                list={data}
                doctors={doctors}
                planner={planner}
                updateList={updateList}
                setUpdateList={setUpdateList}
                filters={filters}
                selectedSlot={selectedSlot}
                setSelectedSlot={setSelectedSlot}
            />

            <AlertModal
                show={slotSelectError.length > 0}
                body={slotSelectError}
                onClose={handleClose}
                showButton={false}
            />

        </>

    )
 
}