import { useState } from "react";
import InterfaceModal from "./InterfaceModal";
import FormPasswordInput from "./FormPasswordInput";
import ButtonPasswordPolicy from "./ButtonPasswordPolicy";
import RightModalFooterNav from "./RightModalFooterNav";
import { Alert, Button } from "react-bootstrap";
import usePassword from "../hooks/usePassword";
import FormSpinnerButton from "./FormSpinnerButton";

export default function PasswordExpiredModal(props) {

    const { doLogout } = props;

    const [ isOpen, toggleModal ] = useState(true);

    const {

        data,

        valid,
        isAlertOpen,
        loading,

        handleOnChange,
        handleSubmit

    } = usePassword(isOpen, toggleModal);

    return (

        <InterfaceModal
            show={isOpen}
            isAlertOpen={isAlertOpen}
            footer= {

                <>
                    <RightModalFooterNav>

                        <Button 
                            variant='danger'
                            title='Logout'
                            onClick={doLogout}
                        >
                            Logout
                        </Button>

                    </RightModalFooterNav>

                    <FormSpinnerButton 
                        caption='Salva'
                        title='Salva dati'
                        loading={loading}
                        onClick={handleSubmit}
                        error={Object.keys(valid).length > 0} 
                    />

                </>

            }            
        >
            <Alert
                variant='danger'
            >
                La tua password è scaduta!                
            </Alert>

            <FormPasswordInput
                label='Password'
                name='vecchiaPassword'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

            <FormPasswordInput
                label='Nuova password'
                name='password'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />
            
            <ButtonPasswordPolicy />

            <FormPasswordInput
                label='Conferma nuova password'
                name='conferma'
                onChange={handleOnChange}
                value={data}
                valid={valid}
            />

        </InterfaceModal>
    )

}